<template>
  <div :class="[
      $style.thumb,
      isLoaded ? $style.isLodaded : '',
      isIntersecting ? $style.isIntersecting : ''
    ]"
  >
    <img
      :class="$style.thumbImg"
      :src="loadedSrc"
      :alt="alt"
    >
  </div>
</template>

<script>
export default {
  name: 'ArticleThumbnail',
  props: ['src', 'alt'],
  computed: {
    imgSrc() {
      return this.src || '/img/thumb.jpg';
    },
    loadedSrc() {
      return this.isLoaded ? this.imgSrc : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    },
  },
  data() {
    return {
      isLoaded: false,
      observer: null,
      isIntersecting: false,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.callback, {
      rootMargin: '500px',
    });
    this.observer.observe(this.$el);
  },
  methods: {
    getImage() {
      const image = new Image();
      image.addEventListener('load', () => {
        this.isLoaded = true;
      });
      image.src = this.imgSrc;
    },
    callback(changes) {
      changes.forEach((change) => {
        if (change.isIntersecting) {
          this.isIntersecting = true;
          this.getImage();
          this.observer.unobserve(this.$el);
        }
      });
    },
  },
};
</script>

<style lang="scss" module>
.thumb {
  width: 100%;
  flex-shrink: 1;
  background-color: #eee;
}

.thumbImg {
  width: 100%;
  aspect-ratio: 200/143;
  object-fit: cover;
  opacity: 0;
  transition: opacity 300ms 200ms ease-in-out;
  pointer-events: none;

  .isLodaded.isIntersecting & {
    opacity: 1;
  }
}
</style>
