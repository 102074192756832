<template>
  <InFeedAdsense
    v-if="helper.env.name === 'production' && (index !== 0 && index % 4 === 0)"
    :class="[
      $style.col,
      $style.adsense,
      isWide && $style.isWide,
      isNarrow && $style.isNarrow,
      showImg && $style.hasThumb,
      !article?.id && $style.isPlaceholder
    ]"
    data-ad-layout-key="-do+6n-2k-ak+12g"
    data-ad-client="ca-pub-4866689451938023"
    data-ad-slot="4372876115" />

  <li :class="[
      $style.col,
      isWide && $style.isWide,
      isNarrow && $style.isNarrow,
      showImg && $style.hasThumb,
      !article?.id && $style.isPlaceholder
    ]"
  >
    <router-link v-if="article?.slug" :class="$style.link" :to="`/article/${article.slug}/`">
      <h3 :class="$style.title">{{ article.title }}</h3>
      <Spacer :y="1.5" />
      <div :class="$style.inner">
        <div :class="$style.content">
          <p :class="$style.description" v-html="article.description"></p>
          <div :class="$style.meta">
            <!-- <span :class="$style.isNew" v-if="isNew">NEW</span> -->
            <span 
            v-for="category in article.categories"
            :class="$style.categoryTag"
            :key="category.id">{{ category.label }}</span>
            <time :class="$style.createdAt">
              {{ formatTimestamp(article.releaseDate, 'YYYY.MM.DD') }}
            </time>
          </div>
        </div>
        <ArticleThumbnail
          :class="$style.thumbnail"
          v-if="showImg && article.urls?.thumbnail?.[0]?.image?.url"
          :src="article.urls?.thumbnail?.[0]?.image?.url"
          :alt="article.title"
        />
      </div>
    </router-link>
  </li>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import cf from '@/mixins/commonFunctions.js';
import Spacer from '@/components/Spacer';
import ArticleThumbnail from '@/components/ArticleList/ArticleThumbnail';

export default {
  name: 'ArticleListItem',
  components: { ArticleThumbnail, Spacer },
  mixins: [cf],
  props: {
    index: {
      type: Number,
      default: 0,
    },
    article: {
      type: Object,
      required: false,
      default: null,
    },
    isWide: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNarrow: {
      type: Boolean,
      required: false,
      default: false,
    },
    showImg: {
      type: Boolean,
      required: false,
      default: true,
    },
    isPlaceholder: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState(['helper']),
    isNew() {
      const releaseDate = this.formatTimestamp(this.article.releaseDate);
      const date = this.formatTimestamp(moment().add(-24, 'h'));
      return releaseDate > date;
    },
  },
};
</script>

<style lang="scss" module>
.col {
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  display: block;
  width: calc((100% - var(--a-g-x)) / 2);

  @include sm-view {
    &.hasThumb {
      width: 100%;
    }
  }

  &.isWide {
    width: 100%;
  }

  &.isNarrow {
    width: calc((100% - var(--a-g-x) * 3) / 4);

    @include sm-view {
      width: calc((100% - var(--a-g-x)) / 2);

      &.hasThumb {
        width: 100%;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: calc((-1 * var(--a-g-y)) / 2);
    left: calc((-1 * var(--a-g-x)) / 2);
    height: 1px;
    width: calc(100% + var(--a-g-x));
    background-color: var(--border-gray);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc((-1 * var(--a-g-y)) / 2);
    width: 1px;
    height: 100%;
    background-color: var(--border-gray);
  }
}

.categoryTag {
  display: inline-block;
  width: fit-content;
  padding: 0 8px;
  border-radius: 7px;
  text-align: center;
  overflow-wrap: break-word;
  margin-right: var(--s) / 2;
  background: var(--secondary-surface);
  color: var(--primary-label);
  margin-right: 8px;
  font-size: 11px;
}

.isPlaceholder + .isPlaceholder::after {
  content: none;
}

.link {
  display: block;
  width: 100%;
  height: 100%;
}

.title {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 700;
  color: var(--font-black);

  @include sm-view {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

.inner {
  display: flex;
  align-items: flex-start;
  gap: calc(var(--s) * 2.5);
}

.content {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  width: 50%;
  flex-direction: column;
  gap: calc(var(--s) * 1.5);
}

.description {
  font-size: 14px;
  font-weight: 400;
  color: var(--font-gray);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;

  @include sm-view {
    -webkit-line-clamp: 3;
  }
}

.meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}


.isNew {
  display: block;
  width: fit-content;
  padding: 4px 11px;
  border-radius: 2px;
  background-color: var(--accent);
  color: #fff;
  font-weight: 900;
  font-size: 10px;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 0.04em;
}

.createdAt {
  font-size: 12px;
  line-height: 1;
  color: var(--font-gray);
}

.thumbnail {
  width: 50%;
  flex-shrink: 1;
  flex-grow: 1;
}

.adsense {
  display: none;
  @include sm-view {
    display: block;
  }
}


</style>
