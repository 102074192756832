<template>
  <div :class="$style.wrap">
    <form
    id="form"
      :class="$style.search"
      autocomplete="off"
      v-on:submit="submit">
      <span :class="$style.icon" />
      <input
        :class="$style.input"
        type="text"
        v-model="keyword"
        placeholder="キーワードで探す"
        ref="search"
        @focus="getList()"
      />
      <ul :class="$style.history" v-if="limitItem.length && !keyword">
        <li v-for="item of limitItem"
        :key="item"
        @click="beforeClickSearch(item, $event)">{{ item }}
        </li>
      </ul>
    </form>
    <i
      class="fa-solid fa-circle-xmark"
      :class="$style.cross"
      @click="clearKeyword"
      v-if="keyword"/>
  </div>
</template>

<script>
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'SearchParts',
  data() {
    return {
      keyword: null,
      // 検索履歴リスト格納用
      list: [],
      cloneList: [],
    };
  },
  computed: {
    limitItem() {
      return this.list.slice(0, 5) || [];
    },
  },
  created() {
    //this.keyword = this.$route.query.keyword;
    const ls = cf.getLocalStorage('times');
    if (ls && ls.searchKey) {
      this.cloneList = JSON.parse(ls.searchKey);
    }
  },
  mounted() {
    window.addEventListener('localStorage', this.cloneList);
    window.addEventListener('click', this.closeDropDown);
  },
  unmounted() {
    document.removeEventListener('localStorage', this.cloneList);
    window.removeEventListener('click', this.closeDropDown);
  },
  watch: {
    $route(to) {
      this.keyword = to.query.keyword;
    },
  },
  methods: {
    getList() {
      const ls = cf.getLocalStorage('times');
      if (ls && ls.searchKey) {
        this.list = JSON.parse(ls.searchKey);
      }
    },
    submit(e) {
      e.preventDefault();
      const args = { path: '/search/' };
      if (this.keyword) args.query = { keyword: this.keyword };
      this.$router.push(args);
      // フォーカスを外す
      this.$refs.search.blur();
      const index = this.cloneList.indexOf(this.keyword);
      if (index !== -1) {
        this.cloneList.splice(index, 1);
        this.cloneList.unshift(this.keyword);
      } else {
        this.cloneList.unshift(this.keyword);
      }
      // 10番目以降の要素を削除
      this.cloneList.splice(10);
      // キーワードを保存
      cf.saveLocalStorage({ searchKey: JSON.stringify(this.cloneList) }, 'times');
      this.list = [];
    },

    clearKeyword() {
      this.keyword = null;
      // this.$refs.search.focus();
    },

    beforeClickSearch(item, e) {
      this.keyword = item;
      this.list = [];
      this.submit(e);
    },
    closeDropDown(event) {
      if (!this.$el.querySelector('#form').contains(event.target)) {
        this.list = [];
      }
    },
  },
};
</script>

<style module lang="scss">
.wrap {
  position: relative;
}

.cross {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  color: var(--lightgray);
  font-size: 20px;
  cursor: pointer;
  margin-right: 8px;
}

.search {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  margin-right: 20px;
}

.icon {
  flex-shrink: 0;
  flex-grow: 0;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 36px;
  width: 36px;
  background: transparent url("~@/assets/images/icon/search.svg") 5px center no-repeat;
  background-size: 24px;
  appearance: none;
  border: none;
  box-shadow: none;
  pointer-events: none;
}

.input {
  display: block;
  width: 100%;
  height: 36px;
  padding: 0 5px 0 36px;
  appearance: none;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #ddd;

  &:focus {
    outline: none;
    border-bottom: 1px solid #000;
  }
}
.history {
  display: block;
  width: 100%;
  position: absolute;
  top: calc(100% - 1px);
  margin-bottom: 10px;
  z-index: 999;
  border: 1px solid var(--border-gray);
  li {
    display: block;
    cursor: pointer;
    background: transparent url("~@/assets/images/icon/search.svg") 5px center no-repeat;
    height: 36px;
    padding-left: 36px;
    background-color: white;
    padding-top: 4px;
    &:not(:first-child) {
      border-top: 1px solid var(--border-gray);
    }
    &:hover {
      color: var(--font-gray);
    }
  }
}
</style>
