<template>
  <div>
    <div :class="$style.wrap" v-if="user.email">
      <!-- すでにサプスクリプション登録している場合 -->
      <div v-if="((user?.customer?.subscriptions?.length && !flag.registedSubscription) && (!isCancelLatest && isPaid)) || flag.canceled || flag.courseChanged">
        <h2>定期購読プラン変更</h2>
        <Spacer :y="6"/>
        <div v-if="!flag.canceled && !flag.courseChanged">
          <ul
            :class="$style.plan_list"
            v-if="flag.planLoaded">
            <li :class="$style.plan"
              v-for="(plan, planName) in existPlans"
              :key="planName">
              <input
                type="radio"
                style="display: none;"
                :id="planName"
                :name="planName"
                v-model="coursename"
                :value="planName"
                :checked="planName === coursename">
              <label
                :for="planName"
                :class="[
                  planName === coursename ? $style.selected : '',
                  $style.label
                ]">
                <!-- <p
                  :class="$style.label_current"
                  v-if="userCourse === planName">現在のプラン</p>
                <div :class="$style.label_data">
                  <span v-if="helper.master?.products">{{ helper.master.products.plans[planName].label }}</span>
                  <span
                    v-if="helper.master?.products"
                    :class="$style.price">&yen;{{ addComma(plan.unit_amount) }}/{{ helper.master.products.plans[planName].term }}</span>
                  v-if="userCourse === planName">現在のプラン</p> -->
                <p
                  v-if="helper.master?.products"
                  :class="$style.label_title">{{ helper.master.products.plans[planName].label }}</p>
                <Spacer :y="4"/>
                <div :class="$style.assistant">
                  <p
                    v-for="des in helper.master.products.plans[planName].description"
                    :key="des"
                    :class="$style.label_des"><i class="fa-regular fa-badge-check"/>{{ des }}</p>
                </div>
                <Spacer :y="4"/>
                <div :class="$style.price_wrap">
                  <div :class="$style.price">
                    <div
                      v-if="helper.master?.products"
                      :class="$style.default">&yen;{{ addComma(plan.unit_amount) }}/{{ helper.master.products.plans[planName].term }}</div>
                  </div>
                </div>
                <Spacer :y="4"/>
              </label>
            </li>
          </ul>
          <Loading v-else/>
          <Spacer :y="3"/>
          <ul :class="$style.warn">
            <li>電子版+紙面配達プランに変更する場合は、次回請求より¥2,400が請求され、差額は請求されません。ただし、紙面は翌日以降配達されます。</li>
            <li>電子版+紙面配達プランから電子版プランへ変更しても差額は返金されません。</li>
          </ul>
          <Spacer :y="4"/>
          <div v-if="['webPaper', 'paper'].includes(coursename)">
            <div v-if="!adjustAddress(2)">
              <p>配達先を設定してください。</p>
              <Address
                :type="2"
                :showDeleteBtn="false"
                :showRegistBtn="true"/>
            </div>
            <div v-else>
              <p><i class="fa-solid fa-house me-2"/>配送先</p>
              <p>{{ adjustAddress(2).label }}</p>
            </div>
          </div>
          <Spacer :y="4"/>
          <div :class="$style.btns">
            <button
              :class="[
                $style.btn,
                $style.primary,
                userCourse === coursename || (['webPaper', 'paper'].includes(coursename) && !adjustAddress(2)) ? $style.disabled : '',
              ]"
              :disabled="userCourse === coursename || (['webPaper', 'paper'].includes(coursename) && !adjustAddress(2))"
              @click="changeCourses">プランを変更する</button>
          </div>
          <Spacer :y="2"/>
          <div :class="$style.btns">
            <div
              :class="$style.cancel"
              @click="cancelSubscription">解約する</div>
          </div>
        </div>
        <div v-else>
          <p v-if="flag.canceled">定期購読解約のお申し込みが完了しました。</p>
          <p v-if="flag.courseChanged">プランの変更が完了しました。</p>
          <Spacer :y="4"/>
          <div :class="$style.btns">
            <router-link
              :class="[$style.btn, $style.primary]"
              to="/">トップページに戻る</router-link>
          </div>
        </div>
      </div>

      <div v-else-if="isPaid && user.role && user.role.role === 7">
        <h2>定期購読変更</h2>
        <Spacer :y="6"/>
        <p>口座振替にて桐生タイムスを御購読の場合、プランの変更や解約をWEB上からお手続きいただけません。<br>お手数ですが、お問合せフォームまたはお電話にてお問合せください。</p>
        <Spacer :y="4"/>
          <div :class="$style.btns">
            <router-link
              :class="[$style.btn, $style.primary]"
              to="/">トップページに戻る</router-link>
          </div>
      </div>

      <!-- サブスクリプション未登録の場合 -->
      <div v-else>
        <h2>定期購読お申し込み</h2>
        <Spacer :y="6"/>
        <!-- 上部step表示 -->
        <div>
          <ul :class="$style.steps">
            <li
              :class="[$style.step,  i + 1 <= step ? $style.done : '']"
              v-for="(s, i) in adjustSteps"
              :key="s">
              <div :class="$style.step_num">
                <span v-if="i + 1 > step">{{ i + 1 }}</span>
                <span v-else><i class="fa-regular fa-circle-check"></i></span>
              </div>
              <p :class="$style.step_label">{{ s.label_short }}</p>
            </li>
          </ul>
          <Spacer :y="6"/>
          <h5>{{ adjustSteps[step - 1].label }}</h5>
          <Spacer :y="2"/>
        </div>

        <div v-if="(!user.customer || user.customer && (!user.customer.subscriptions.length || isExpired)) || (isCancelLatest && !flag.canceled && !flag.courseChanged)">
          <!-- プラン選択 -->
          <div
            v-if="step === 1">
            <ul
              :class="$style.plan_list"
              v-if="flag.planLoaded">
              <li :class="$style.plan"
                v-for="(plan, planName) in existPlans"
                :key="planName">
                <input
                  type="radio"
                  style="display: none;"
                  :id="planName"
                  :name="planName"
                  v-model="coursename"
                  :value="planName"
                  :checked="planName === coursename">
                <label
                  :for="planName"
                  :class="[
                    planName === coursename ? $style.selected : '',
                    $style.label
                  ]">
                  <div
                    v-if="helper.master?.products?.plans && helper.master.products.plans[planName]"
                    :class="$style.label_title">{{ helper.master.products.plans[planName].label }}</div>
                  <Spacer :y="4"/>
                  <div :class="$style.assistant" v-if="helper.master?.products?.plans[planName]">
                    <p
                      v-for="des in helper.master.products.plans[planName].description"
                      :key="des"><i class="fa-regular fa-badge-check"/>{{ des }}</p>
                  </div>
                  <Spacer :y="4"/>
                  <div :class="$style.price_wrap">
                    <div
                      :class="$style.price">
                      <div
                        v-if="helper.master?.products?.plans && helper.master?.products?.plans[planName]"
                        :class="[
                          $style.default,
                          isTrial ? $style.notDefault : ''
                        ]">&yen;{{ addComma(plan.unit_amount) }}/{{ helper.master.products.plans[planName].term }}</div>
                      <p
                        v-if="isTrial"
                        :class="[$style.arrow, $style.right]"><i class="fa-regular fa-arrow-right"/></p>
                      <p
                        v-if="isTrial"
                        :class="[$style.arrow, $style.down]"><i class="fa-regular fa-arrow-down"/></p>
                      <div
                        v-if="isTrial"
                        :class="$style.trial">
                        <p :class="$style.trial_assistant">今だけ!!なんと</p>
                        <p :class="$style.trial_price">&yen;<span :class="$style.zero">０</span>/月</p>
                        <p :class="$style.trial_des">会員登録から２週間無料</p>
                      </div>
                    </div>
                  </div>
                  <Spacer :y="4"/>
                </label>
              </li>
            </ul>
            <Loading v-else/>
            <div>※クレジットカード決済は、「前払い（前納）」で１カ月単位の購読となります。原則として途中解約はできませんのでご了承ください。</div>
            <Spacer :y="3"/>
            <div :class="$style.btns">
              <button
                :class="[
                  $style.btn,
                  $style.primary,
                ]"
                @click="changeStep('next')">次に進む</button>
            </div>
            <Spacer :y="2"/>
            <div :class="$style.btns">
              <div
                :class="$style.notNow"
                @click="notNow">今はしない</div>
            </div>
          </div>

          <!-- 支払い情報入力 -->
          <div v-if="step === 2">
            <div v-if="user && !user.customer || !user?.subscribe?.cards?.cards?.[0]">
              <CardRegist
                v-on:registed="changeStep('next')"
                v-on:sendFlag="receiveCardRegistFlag"
                :hideBtn="true"
                ref="cardRegist"/>
            </div>

            <Spacer :y="3"/>

            <div v-if="!flag.editCard && user?.subscribe?.cards?.cards?.[0]">
              <div :class="$style.credit_card_wrap">
                <div :class="$style.credit_card">
                  <div
                    :class="$style.card_img"
                    v-bind:style="{ 'background-image':`url('/img/cards/${getCardIconName(user.subscribe.cards.cards[0].brand)}.png')`}"></div>
                  <p>**** **** **** {{ user.subscribe.cards.cards[0].last4 }}</p>
                </div>
                <Spacer :x="6"/>
                <div
                  :class="$style.edit_btn"
                  @click="updateEditFlag">変更する</div>
              </div>
            </div>

            <div v-if="user && user.customer && flag.editCard">
              <CardSelect
                :hideBtn="true"
                v-on:updatedDefaultCard="changeStep('next')"
                ref="cardSelect" />
              <div :class="$style.btns">
                <button
                  :class="[$style.btn, $style.secondary]"
                  @click="updateEditFlag">キャンセル</button>
                <Spacer :x="2"/>
                <button
                  :class="[$style.btn, $style.primary]"
                  @click="updateCard">このカードに設定</button>
              </div>
            </div>

            <Spacer :y="6"/>

            <div :class="$style.btns" v-if="!(user && user.customer && flag.editCard)">
              <button
                :class="[$style.btn, $style.secondary]"
                @click="changeStep('back')">戻る</button>
              <Spacer :x="2"/>
              <button
                v-if="!user?.subscribe?.cards.cards[0]"
                :class="[
                  $style.btn,
                  $style.primary,
                  !flag.cardRegist ? $style.disabled : '',
                ]"
                :disabled="!flag.cardRegist"
                @click="registCard">次に進む</button>
              <button
                :class="[ $style.btn, $style.primary ]"
                v-if="user?.subscribe?.cards?.cards[0]"
                @click="changeStep('next')">次に進む</button>
            </div>
          </div>

          <!-- 配送先 -->
          <div v-if="['webPaper', 'paper'].includes(coursename) && step === 3">
            <Address
              v-if="flag.editAddress.delivery || !adjustAddress(2)"
              :type="2"
              :showRegistBtn="false"
              :showDeleteBtn="false"
              v-on:updated="updatedAddress('delivery')"
              v-on:isCancel="updateEditFlag('address', 'delivery')"
              v-on:sendInvalidsFlag="updateReadyAddressFlag"
              ref="address" />
            <div
              v-if="adjustAddress(2) && adjustAddress(2).flag === 1 && !flag.editAddress.delivery"
              :class="$style.address_str">
              <p>{{ adjustAddress(2).flag === 1 ? adjustAddress(2).label : '' }}</p>
              <div
                :class="[$style.edit_btn, $style.address]"
                @click="updateEditFlag('address', 'delivery')">
                <span :class="$style.onlyPC">変更する</span>
                <span :class="$style.onlySP"><i class="fa-solid fa-pen-to-square" /></span>
              </div>
            </div>
            <Spacer :y="3"/>
            <div :class="$style.btns">
              <button
                :class="[$style.btn, $style.secondary]"
                @click="changeStep('back')">戻る</button>
              <Spacer :x="2"/>
              <button
                :class="[$style.btn, $style.primary, !flag.readyAddress ? $style.disabled : '']"
                :disabled="!flag.readyAddress"
                @click="submitAddress(1)">次に進む</button>
            </div>
          </div>


          <!-- 既存契約の解約 -->
          <div
            v-if="(['webPaper', 'paper'].includes(coursename) && step === 4) || (coursename === 'web' && step === 3)">
            <p>
              この度は「{{ helper.master.products.plans[coursename].label }}」を申し込みしていただきありがとうございます。<br>
              申し込みいただいたお客様で以前より桐生タイムス紙面をご契約のお客様はこちらで既存の契約の解約をしていだけます。<br>
              <strong>解約ご希望のお客様</strong>は以下の情報を入力してください。<br>
              <strong>解約を希望されない・該当しないお客様</strong>は何も入力せずに「次に進む」ボタンをクリックしてください。
            </p>
            <div>
              <Spacer :y="6"/>
              <div
                :class="$style.stop_form">
                <div :class="$style.stop_input">
                  <p :class="$style.stop_label">契約者氏名</p>
                  <input
                    id="plusPlanName"
                    type="text"
                    name="plusPlanName"
                    v-model="plusPlanCancel.name">
                  <span class="slide-in"></span>
                  <Spacer :y="6"/>
                  <p :class="$style.stop_label">契約者住所</p>
                  <input
                    id="plusPlanAddress"
                    type="text"
                    name="plusPlanAddress"
                    v-model="plusPlanCancel.address">
                  <span class="slide-in"></span>
                  <Spacer :y="6"/>
                  <p :class="$style.stop_label">契約者電話番号</p>
                  <input
                    id="plusPlanTel"
                    type="text"
                    name="plusPlanTel"
                    v-model="plusPlanCancel.tel">
                  <Spacer :y="6"/>
                  <p :class="$style.stop_label">支払い方法</p>
                  <ul
                    class="select-list mt-2 category"
                    :class="$style.radio_wrap">
                    <li
                      v-for="(method) in paymentMethods"
                      :key="method.name"
                      :class="$style.radio">
                      <input
                        type="radio"
                        :name="method.label"
                        :id="method.label"
                        :value="method.label"
                        v-model="plusPlanCancel.method"
                        style="display: none">
                      <label
                        :for="method.label"
                        :class="[$style.radio_label, plusPlanCancel.method === method.label ? $style.checked : '']">{{ method.label }}</label>
                    </li>
                    <li :class="[$style.radio, $style.radio_right]" @click="clearSelectedMethod">
                      <input
                        type="radio"
                        name="clear"
                        id="clear"
                        value="clear"
                        style="display: none">
                      <label
                        for="clear"
                        :class="[$style.radio_label, $style.checked]">選択解除</label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <Spacer :y="6"/>
            <div :class="$style.btns">
              <button
                :class="[$style.btn, $style.secondary]"
                @click="changeStep('back')">戻る</button>
              <Spacer :x="2"/>
              <button
                :class="[
                  $style.btn,
                  $style.primary,
                  isInputCancelInfo && (!plusPlanCancel.name || !plusPlanCancel.address || !plusPlanCancel.tel || !plusPlanCancel.method) ? $style.disabled : ''
                ]"
                @click="changeStep('next')"
                :disabled="isInputCancelInfo && (!plusPlanCancel.name || !plusPlanCancel.address || !plusPlanCancel.tel || !plusPlanCancel.method)">次に進む</button>
            </div>
          </div>

          <!-- 内容確認 -->
          <div
            v-if="(['webPaper', 'paper'].includes(coursename) && step === 5) || (coursename === 'web' && step === 4)">
            <ul :class="$style.confirmList">
              <li>
                <p :class="$style.itemname">プラン</p>
                <div :class="$style.detail">
                  <p v-if="helper.master?.products?.plans">
                    <span>{{ helper.master.products.plans[coursename].label }}</span>
                    <span :class="$style.price">&yen;{{ addComma(plans[coursename].unit_amount) }}/{{ helper.master.products.plans[coursename].term }}</span>
                  </p>
                </div>
              </li>
              <li>
                <p :class="$style.itemname">お支払い方法</p>
                <div :class="$style.detail">
                  <div
                    :class="$style.credit_card"
                    v-if="user.subscribe.cards?.cards?.length">
                    <div
                      :class="$style.card_img"
                      v-bind:style="{ 'background-image':`url('/img/cards/${getCardIconName(user.subscribe.cards.cards[0].brand)}.png')`}"></div>
                    <p>**** **** **** {{ user.subscribe.cards.cards[0].last4 }}</p>
                  </div>
                </div>
              </li>
              <li v-if="['webPaper', 'paper'].includes(coursename)">
                <p :class="$style.itemname">配送先</p>
                <div :class="$style.detail" v-if="adjustAddress(2) || adjustAddress(1)">
                  <p>{{ adjustAddress(2) ? adjustAddress(2).label : adjustAddress(1).label }}</p>
                </div>
              </li>
            </ul>
            <Spacer :y="6"/>
            <div :class="$style.btns">
              <button
                :class="[$style.btn, $style.secondary]"
                @click="changeStep('back')">戻る</button>
              <Spacer :x="2"/>
              <button
                :class="[ $style.btn, $style.primary ]"
                @click="submit">申し込みを確定する</button>
            </div>
          </div>
        </div>

        <div v-if="flag.registedSubscription && ((['webPaper', 'paper'].includes(coursename) && step === 6) || (coursename === 'web' && step === 5))">
          <p>桐生タイムスの定期購読にお申し込みいただきありがとうございます！</p>
          <Spacer :y="6"/>
          <div :class="$style.btns">
            <button
              :class="[$style.btn, $style.primary]">
              <router-link to="/">トップページへ</router-link>
            </button>
          </div>
        </div>
      </div>
    </div>
    <Loading v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import CardRegist from '@/components/parts/CardRegist.vue';
import CardSelect from '@/components/parts/CardSelect.vue';
import Address from '@/components/parts/Address.vue';
import cf from '@/mixins/commonFunctions.js';
import Spacer from '@/components/Spacer';
import Loading from '@/components/Loading.vue';

export default {
  name: 'subscription',
  mixins: [cf],
  components: {
    CardRegist,
    CardSelect,
    Spacer,
    Loading,
    Address,
  },
  data() {
    return {
      root2: Math.sqrt(2), //  styleで使用
      flag: {
        planLoaded: false,
        submit: true,
        cardError: false,
        cardRegist: false,
        editCard: false,
        editAddress: {
          normal: false,
          delivery: false,
        },
        registedSubscription: false,
        canceled: false,
        courseChanged: false,
        readyAddress: false,
      },
      message: {
        cardError: '',
      },
      values: {
        label: null,
        type: '0',
      },
      plusPlanCancel: {
        name: '',
        address: '',
        tel: '',
        method: '',
      },
      paymentMethods: [
        {
          name: 'bank',
          label: '銀行引き落とし',
        },
        {
          name: 'collection',
          label: '集金',
        },
        {
          name: 'other',
          label: 'その他',
        },
        {
          name: 'unknown',
          label: '不明',
        },
      ],
      step: 1,
      steps: [
        {
          label: 'プランの選択',
          label_short: 'プラン',
        },
        {
          label: 'お支払い方法の入力・確認',
          label_short: 'お支払い',
        },
        {
          label: '配送先の登録',
          label_short: '配送先',
        },
        {
          label: '既存契約の解約',
          label_short: '解約確認',
        },
        {
          label: '登録内容の確認',
          label_short: '確認',
        },
        {
          label: 'お申込み完了',
          label_short: '完了',
        },
      ],
      coursename: 'web',
      product: null,
      plans: {
        web: {},
        webPaper: {},
        paper: {},
      },
      existPlanNames: [],
      tax: null,
    };
  },

  created() {
    const query = this.$route.query;
    if (query.plan) this.coursename = query.plan;
    if (this.user.email) {
      this.getSubscriptionData();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getSubscriptionData();
        }
      });
    }
  },

  watch: {
    coursename(to) {
      if (to === 'web') {
        this.flag.plusPlanCancel = false;
      }
    },
  },

  computed: {
    ...mapState(['user', 'helper']),
    isTrial() {
      return Boolean(this.user.email
        && moment(new Date()).isBefore(moment(this.user.limit_trial)));
    },
    isPaid() {
      // 有料会員(資格)を有するかどうか
      // ここのテストはStripeのWebhookをAPIで受け取る必要有り
      return Boolean((this.user.role && this.user.role.role === 7)
        || (this.user.email
        && this.user.customer
        && this.user.customer.subscriptions.length
        && this.user.customer.subscriptions[0].expire
        && moment(new Date()).isBefore(moment(this.user.customer.subscriptions[0].expire))));
    },
    isCancelLatest() {
      // 最新の購読がキャンセルされているかどうか
      return Boolean(this.user.email
        && this.user.customer
        && this.user.customer.subscriptions.length
        && this.user.customer.subscriptions[0].isCancel);
    },
    isExpired() {
      // 最新の購読が有効期限切れかnullかどうか
      return Boolean(this.user.email
        && this.user.customer
        && this.user.customer.subscriptions.length
        && (moment(new Date()).isAfter(moment(this.user.customer.subscriptions[0].expire))
          || !this.user.customer.subscriptions[0].expire));
    },
    userCourse() { // stripe_subscriptionのprice_idから登録中のcoursenameを特定
      let name = '';
      let pID = '';
      if (this.user?.customer?.subscriptions?.[0]?.price_id) pID = this.user.customer.subscriptions[0].price_id;
      Object.keys(this.plans).forEach((planname) => {
        if (this.plans[planname].id === pID) name = this.plans[planname].nickname;
      });
      return name;
    },
    adjustSteps() {
      const array = cloneDeep(this.steps);
      let flag = false;
      if (this.helper.master?.products?.plans[this.coursename]?.requireAddress) flag = true;
      if (!flag) array.splice(2, 1);
      return array;
    },
    isType2Address() {
      if (this.user.email && this.user.address.length) {
        return this.user.address.some((row) => row.type === 2);
      }
      return false;
    },
    isInputCancelInfo() {
      return Object.keys(this.plusPlanCancel).some((column) => {
        if (this.plusPlanCancel[column] || !this.plusPlanCancel[column].match(/^[ 　\r\n\t]*$/)) {
          return true;
        }
        return false;
      });
    },
    existPlans() {
      const obj = {};
      this.existPlanNames.forEach((name) => { obj[name] = this.plans[name]; });
      return obj;
    },
  },

  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    getSubscriptionData() {
      const env = this.helper.env.name;
      let pid;
      if (this.user.customer?.subscriptions?.length) pid = this.user.customer.subscriptions[0].price_id;

      const params = {
        subscription_id: this.helper.master.products.product_id[env],
      };

      this.axios({
        method: 'GET',
        url: 'v1/stripe/subscription/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.product = res.product;
          this.tax = res.tax;
          res.prices.data = res.prices.data.filter((price) => price.active);
          this.existPlanNames = res.prices.data.map((price) => price.nickname);
          res.prices.data.forEach((price) => {
            this.plans[price.nickname] = price;
            if (price.id === pid && !this.$route?.query?.plan) this.coursename = price.nickname;
          });
          this.flag.planLoaded = true;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
        });

      if (this.adjustAddress(2)) {
        this.flag.readyAddress = true;
      }
    },

    receiveCardRegistFlag(flag) {
      this.flag.cardRegist = flag;
    },

    registCard() {
      this.$refs.cardRegist.registCard();
    },

    updateEditFlag(type, target) {
      if (type === 'address') {
        this.flag.editAddress[target] = !this.flag.editAddress[target];
        if (this.adjustAddress(2)) this.flag.readyAddress = true;
      } else {
        this.flag.editCard = !this.flag.editCard;
      }
    },

    updateCard() {
      this.$refs.cardSelect.updateDefaultCard();
    },

    updatedAddress(target) {
      this.$store.dispatch('user/update');
      this.flag.editAddress[target] = false;
      this.step += 1;
    },

    updateReadyAddressFlag(flag) {
      this.flag.readyAddress = flag;
    },

    adjustAddress(type) {
      if (this.user && this.user.address) {
        const addressArray = this.user.address.filter((addr) => addr.type === type);
        if (!addressArray.length) return null;
        const address = addressArray[0];
        const label = type === 2
          ? `${address.city}${address.address} ${address.building || ''}`
          : `〒${address.zip} ${address.pref}${address.city}${address.address} ${address.building || ''}`;
        return {
          label,
          flag: address.flag,
        };
      }
      return null;
    },

    submit() {
      if (!confirm('お支払いを確定します。よろしいですか？')) return;
      this.showLoading();
      if (this.user.customer) {
        this.registSubscription(this.user.customer.customer_id);
      } else {
        alert('カスタマー情報の取得に失敗しました。');
      }
    },

    registSubscription(customerId) {
      const name = this.coursename;
      const data = {
        // isExist: false, // 今回使うカード情報がすでに登録済の場合true
        // ignoreSoldOut: this.data.ignoreSoldOut || false,
        user_id: this.user.id,
        productObj: this.product,
        priceObj: this.plans[name],
        taxObj: this.tax,
        customer_id: customerId,
        customer: {
          email: this.user.email,
          name: `${this.user.last_name} ${this.user.first_name}`,
        },
        connected_account_id: this.product.metadata?.connected_account_id ? this.product.metadata.connected_account_id : null,
      };

      if (this.user.limit_trial && moment(this.user.limit_trial).isSameOrAfter(new Date(), 'day')) {
        data.limit_trial = this.user.limit_trial;
      }

      // 支払いを実行
      try {
        this.axios({
          method: 'POST',
          url: '/v1/stripe/subscription/set/register',
          data,
        })
          .then((response) => {
            const res = response.data;
            if (res && res.registedSubscription) {
              alert('お申し込みが完了しました。');
              this.$store.dispatch('user/update', null, { root: true });
              const customerData = { customer: { customer_id: customerId } };
              this.$store.dispatch('user/subscribe/getCustomer', customerData, { root: true });
              // これでリロードしないとその後のカード追加登録がうまくいかない
              // this.$router.push({ path: '/account/' });
              this.flag.registedSubscription = true;
              this.changeStep('next');
              /** 既存契約の解約情報登録 */
              if (this.isInputCancelInfo) {
                this.regisPlusPlanCancel();
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              alert(`定期購入の登録に失敗しました。\n失敗の理由については以下サイトをご参照くださいませ。\nhttps://stripe.com/docs/declines/codes\nエラー内容: ${error.response.data.detail}`);
            } else {
              console.log(error);
            }
          })
          .finally(() => {
            this.hideLoading();
          });
      } catch (err) {
        if (err.response) console.log(err.response.data);
        else console.log(err);
        alert('お支払い情報の登録に失敗しました。\nお手数ですが管理者までお問い合わせください');
        this.hideLoading();
      }
    },

    /** 既存契約の解約情報登録 */
    async regisPlusPlanCancel() {
      const tempValues = [];
      const checkColumns = [
        {
          column: 'name',
          type: 4,
        },
        {
          column: 'address',
          type: 5,
        },
        {
          column: 'tel',
          type: 6,
        },
        {
          column: 'method',
          type: 7,
        },
      ];
      // 必要な項目を確認
      checkColumns.forEach((row) => {
        tempValues.push({
          type: row.type,
          user_id: this.user.id,
          value: this.plusPlanCancel[row.column],
        });
      });
      await Promise.all(
        tempValues.map(async (row) => {
          await this.axios({
            method: 'POST',
            url: '/v1/temporary/set/register',
            data: row,
          })
            .then(() => {
            })
            .catch((error) => {
              alert('紙面プラスプランの解約情報登録に失敗しました。お手数ですが、管理者へお問い合わせください。');
              if (error.message) console.log(error.message);
              else console.log(error);
            })
            .finally(() => {
              this.hideLoading();
            });
        }),
      );
    },

    submitAddress(flag) {
      if (this.flag.editAddress.delivery || !this.adjustAddress(2)) {
        this.$refs.address.submit(flag);
      } else {
        this.changeStep('next');
      }
    },

    changeStep(type) {
      if (type === 'next' && ['webPaper', 'paper'].includes(this.coursename) && this.step === 3 && !this.isType2Address) {
        return alert('配送先住所を設定してください。');
      }
      if (type === 'next') this.step += 1;
      else if (type === 'back') this.step -= 1;
    },

    changeCourses() {
      if (!confirm('プランを変更します。よろしいですか？\n※この操作は取り消せません。')) return;
      this.showLoading();
      this.flag.planLoaded = true;

      const data = {
        id: this.user.customer.subscriptions[0].id, // StripeSubscriptionsのID
        user_id: this.user.id,
        email: this.user.email,
        customer_id: this.user.customer.customer_id,
        subscription_id: this.user.customer.subscriptions[0].subscription_id,
        price_id: this.plans[this.coursename].id,
      };

      this.axios({
        method: 'POST',
        url: '/v1/stripe/subscription/set/update',
        data,
      })
        .then((response) => {
          const res = response.data;
          if (res.updateStripe && res.updateDB) {
            alert('定期購読のプラン変更が完了しました。');
            this.$store.dispatch('user/update');
          } else {
            alert('プラン更新中に何らかのエラーが発生しました。\nもう一度お試しいただくか、サイト管理者へお問合せください。');
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
          this.flag.planLoaded = false;
          this.flag.courseChanged = true;
        });
    },

    cancelSubscription() {
      if (!confirm('定期購読をキャンセルします。よろしいですか？\n※この操作は取り消せません。')) return;
      this.showLoading();
      this.flag.planLoaded = true;

      const data = {
        id: this.user.customer.subscriptions[0].id, // StripeSubscriptionsのID
        user_id: this.user.id,
        email: this.user.email,
        customer_id: this.user.customer.customer_id,
        subscription_id: this.user.customer.subscriptions[0].subscription_id,
      };

      const expire = moment(this.user.customer.subscriptions[0].expire).format('YYYY年MM月DD日');
      const priceDetail = Object.keys(this.plans).find((row) => this.plans[row].id === this.user.customer.subscriptions[0].price_id);
      const expirePlanLabel = {
        webPaper: '有料会員としてウェブ電子版をご利用が可能であり、紙面の配達もございます。',
        web: '有料会員としてウェブ電子版をご利用が可能です。',
        paper: '紙面の配達もございます。',
      };

      this.axios({
        method: 'POST',
        url: '/v1/stripe/subscription/set/cancel',
        data,
      })
        .then(() => {
          alert(`定期購読の解約処理が完了しました。\nなお、${expire}までは${expirePlanLabel[priceDetail]}`);
          this.$store.dispatch('user/update');
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
          this.flag.planLoaded = false;
          this.flag.canceled = true;
        });
    },

    notNow() {
      if (!confirm('定期購読のお申込みが完了しておりません。\n無料期間終了後に有料記事が読めなくなります。\nこのページを離れてよろしいですか？')) return;
      this.$router.push('/');
    },

    clearSelectedMethod() {
      this.plusPlanCancel.method = '';
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  max-width: 500px;
  margin: 0 auto;
}

.steps {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .step {
    flex: 1;
    border-width: 1px;
    border-style: solid;
    border-color: var(--lightgray) var(--lightgray) var(--lightgray) transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding: 12px;
    color: var(--lightgray);

    &:not(:last-child) {
      position: relative;
      &::after {
        $l: 15px;
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        right: 0;
        width: $l;
        height: $l;
        transform: translate(calc($l / v-bind(root2) + ($l - $l * v-bind(root2)) / 2), -50%) rotate(45deg);
        background-color: white;
        border-width: 1px;
        border-style: solid;
        border-color: var(--lightgray) var(--lightgray) transparent transparent;
      }
    }

    &:first-child {
      border-left: 1px solid var(--lightgray);
    }

    &.done {
      color: var(--gray);
      border-color: var(--gray) var(--gray) var(--gray) transparent;

      &::after {
        border-color: var(--gray) var(--gray) transparent transparent;
      }

      &:first-child {
        border-color: var(--gray) var(--gray) var(--gray) var(--gray);
      }
    }

    &_num {
      margin-bottom: 8px;
    }

    &_label {
      font-size: 12px;
      line-height: 1;
      margin-left: 4px;
    }
  }
}

.credit_card {
  display: flex;
  align-items: center;

  &_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.card_img {
  display: inline-block;
  height: 20px;
  width: calc(20px * 220 / 142);
  background-size: cover;
  border-radius: 2px;
  margin: auto 5px auto 0;
}

.card_No {
  font-size: 18px;
}

.edit_btn {
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  &.address {
    display: inline;
  }
}

.address_str {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delivery {
  margin-bottom: 24px;
  &_label {
    font-size: 20px;
  }
}

.btns {
  display: flex;
  justify-content: center;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 128px;
  height: 40px;
  padding: 13px 26px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  border-radius: 20px;
  cursor: pointer;
  appearance: none;
  box-shadow: none;
  transition: all .3s;

  &.primary {
    background-color: rgba(26, 34, 61, 1);
    border: none;
    color: #fff;
    a {
      color: #fff;
    }
    &:hover, &.disabled {
      opacity: .6;
      cursor: default;
    }
  }

  &.secondary {
    border: 1px solid rgba(26, 34, 61, 1);
    color: var(--font-black);
    background-color: #fff;
    &:hover {
      opacity: .4;
    }
  }
}

.notNow {
  font-size: 12px;
  color: var(--gray);
  text-decoration: underline;
  cursor: pointer;
}

.plan_list {
  li {
    margin-bottom: 32px;
  }
}

.warn {
  color: var(--red);
  li {
    padding-left: 16px;
    position: relative;
    &::before {
      content: '※';
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.label {
  border: 1px solid #858585;
  border-radius: 4px;
  width: 100%;
  color: var(--gray);

  &_title {
    padding: 15px 32px;
    background-color: #858585;
    color: #fff;
    text-align: center;
    font-size: 22px;
  }

  .assistant {
    padding: 0 32px;
    font-size: 12px;
    display: flex;
    flex-flow: column;
    align-items: center;

    i {
      font-size: 16px;
      color: green;
      margin-right: 4px;
    }
  }

  &_current {
    font-size: 12px;
    color: var(--gray);
  }

  &.selected {
    border-color: var(--black);
    border-width: 1.5px;
    color: var(--black);

    .label_title {
      background-color: var(--black);
    }
  }
  .price {
    display: flex;
    align-items: center;
    &_wrap {
      display: flex;
      justify-content: center;
    }
    @include xs-view {
      flex-flow: column;
    }
  }

  .default {
    font-size: 24px;
    &.notDefault {
      position: relative;
      &::after {
        content: '';
        width: 100%;
        height: 2px;
        background-color: var(--font-black);
        position: absolute;
        top: 50%;
        left: 0;
      }
    }
  }

  .arrow {
    margin-left: 26px;
    font-size: 24px;
    &.right {
      @include xs-view {
        display: none;
      }
    }
    &.down {
      margin-left: 0;
      display: none;
      @include xs-view {
        display: inline-block;
      }
    }
  }

  .trial {
    margin-left: 16px;
    display: flex;
    flex-flow: column;
    align-items: center;
    &_assistant {
      font-size: 14px;
    }
    &_price {
      font-size: 26px;
      .zero {
        font-size: 37px;
        line-height: 1;
      }
    }
    &_des {
      font-size: 12px;
    }
    @include xs-view {
      margin-left: 0;
      margin-top: 12px;
    }
  }
}

.confirmList {
  border: 1px solid var(--gray);
}

.itemname {
  background-color: var(--lightgray);
  padding: 4px 10px;
  font-size: 12px;
  font-weight: bold;
}

.detail {
  padding: 12px 16px;
}

.cancel {
  margin: 0 auto;
  display: inline-block;
  text-decoration: underline;
  color: var(--black);
  cursor: pointer;
}

.onlyPC {
  @include xs-view {
    display: none;
  }
}

.onlySP {
  display: none;
  @include xs-view {
    display: inline-block;
  }
}
.stop_checkbox {
  text-align: center;
}
.stop_form {
  text-align: center;
}
.stop_label {
  font-size: 12px;
  padding-left: 4px;
  text-align: left;
  line-height: 1;
  font-weight: bold;
}
.stop_input {
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  position: relative;
  input {
    width: 100%;
    padding: 13px;
    border: none;
    background-color: var(--gray-sub);
    color: var(--black);
    outline: none;
    border-bottom: 1px solid var(--gray);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    &:focus + .slideIn {
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(#e2e2e2, .4);
      mix-blend-mode: multiply;
      position: absolute;
      animation: split .3s;
    }

    @keyframes split {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }
}

.radio {
  &_wrap {
    display: flex;
    flex-wrap: wrap;
  }

  margin-bottom: 4px;

  &:not(:last-child) {
    margin-right: 4px;
  }

  &_label {
    padding: 4px 12px;
    border-radius: 4px;
    border: 1px solid var(--lightgray);
    color: var(--gray);
    &.checked {
      border-color: var(--gray);
      color: var(--black);
    }
  }
  &_right {
    margin: 0 0 0 auto;
  }
}
@include sm-view {
  .stop_input {
    width: 100%;
  }
}
</style>
