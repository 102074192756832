<template>
  <div class="terms pages">
    <div
      class="container"
      v-if="terms">
      <h2 v-html="terms.title" :class="$style.title"></h2>

      <section v-html="terms.description"></section>
      <spacer :y="5"/>

      <ul>
        <li
          v-for="(row,i) in terms.sections"
          v-bind:key="i">
          <section>
            <h3 v-html="row.titles" :class="$style.title"></h3>
            <ul :class="$style.description">
              <li
                v-for="(description, i) in row.description"
                v-bind:key="i">
                <p v-html="description.title"></p>
                <ul :class="$style.only">
                  <li
                    v-for="(only, i) in description.onlyBracketslist"
                    v-bind:key="i"
                  >
                  <p v-html="only.title"></p>
                  </li>
                </ul>
              </li>
            </ul>
            <ul :class="$style.numlist">
              <li
                v-for="(numlist, i) in row.numlist"
                v-bind:key="i"
                >
                <p v-html="numlist.title"></p>
                <ul :class="$style.bracketslist">
                  <li
                    v-for="(bracketslist,i) in numlist.bracketslist"
                    v-bind:key="i">
                    <p v-html="bracketslist.title" v-if="bracketslist.title"></p>
                  </li>
                </ul>
              </li>
            </ul>
          </section>
          <spacer :y="5"/>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
import terms from '@/data/pages/terms.yaml';
import Spacer from '@/components/Spacer.vue';

export default {
  components: { 
    Spacer, 
  },
  name: 'terms',
  data() {
    return {
      terms,
    };
  },
};


</script>

<style lang="scss" module>
  .numlist {
    margin: 10px 0;
    padding-left: 10px;
    li {
      padding-left: 20px;
      position: relative;
      counter-increment: numcnt;
      &::before {
          position: absolute;
          left: 0;
          content: counter(numcnt) ". ";
      }
      .bracketslist {
        li {
          counter-increment: brackets;
          padding-left: 30px;
          &::before {
            content: counter(brackets) " ) ";
          }
        }
      }
    }
  }
  .description {
    padding-left: 10px;
    ::first-letter{
      padding-left: 15px;
    }
  }
  .only {
    li {
      counter-increment: only;
      padding-left: 30px;
      position: relative;
      &::before {
        position: absolute;
        left: 20px;
        content: counter(only) " ) ";
      }
    }
  }

.title {
  margin-bottom: 30px;
}
</style>
