<template>
  <div>
    <spacer :y="4"/>
    <h2
      v-if="flag.updated"
      :class="$style.title">メールアドレス更新完了</h2>
    <Spacer
      v-if="flag.updated"
      :y="5"/>

    <div
      :class="$style.wrap"
      v-if="flag.updated">
      <div :class="$style.center">
        <p :class="$style.comment">メールアドレスを更新しました。次回ログイン時から更新したメールアドレスをご利用ください。</p>
        <Spacer :y="4"/>
        <router-link
          v-if="user.login.isLogin"
          :class="$style.btn"
          to="/account/">マイページ</router-link>
        <router-link
          v-if="!user.login.isLogin"
          :class="$style.btn"
          to="/login/">ログイン</router-link>
      </div>
    </div>

    <div
      :class="$style.wrap"
      v-if="flag.invalid">
      <p :class="$style.comment">アクティベートキーが無効です。<br>メールアドレス更新リクエスト時のメールを確認し、メール記載のURLからアクセスしてください。</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Spacer from '@/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'edit-updated-email',
  mixins: [cf],
  components: {
    Spacer,
  },
  data() {
    return {
      activatekey: null,
      flag: {
        invalid: true,
        updated: false,
      },
    };
  },
  created() {
    // パラメータが含まれているか確認
    const query = this.$route.query;
    if (query.activate) {
      // アクティベートキーがあればメールアドレスを変更
      this.flag.invalid = false;
      this.activatekey = query.activate;

      if (this.user.email) {
        this.updateLoginEmailAddress();
      } else {
        this.$store.subscribe((mutation) => {
          if (mutation.type === 'user/setUserData') {
            this.updateLoginEmailAddress();
          }
        });
      }
    }
  },
  computed: {
    ...mapState(['user', 'page']),
  },
  methods: {
    ...mapActions('user/login', [
      'logout',
    ]),

    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** ログイン用メールアドレスを更新 */
    updateLoginEmailAddress() {
      this.showLoading();

      const data = {
        id: this.user.id,
        email: this.user.updateEmail,
        updateEmail: null,
        activatekey: this.activatekey,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/updateLoginEmail',
        data,
      })
        .then(() => {
          this.flag.updated = true;
          this.$store.dispatch('user/update', null, { root: true });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            this.flag.invalid = true;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  display: flex;
  justify-content: center;
}
.logo {
  margin: 0 auto;
  width: 50%;
  max-width: 300px;
}
.title {
  text-align: center;
  color: var(--orange-main);
}
.center {
  text-align: center;
}
.form {
  text-align: center;
}
.input {
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
  position: relative;
  input {
    width: 100%;
    padding: 13px;
    border: none;
    background-color: var(--gray-sub);
    outline: none;
    border-bottom: 1px solid var(--gray);

    &:focus + .slideIn {
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(#e2e2e2, .4);
      mix-blend-mode: multiply;
      position: absolute;
      animation: split .3s;
    }

    @keyframes split {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }
}
.comment {
  font-size: 12px;
  color: var(--pink);
}
.message {
  font-size: 12px;
  text-align: center;
}
.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
}
</style>
