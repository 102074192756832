<template>
  <ol :class="$style.container">
    <slot />
  </ol>
</template>

<script>
export default {
  name: 'TagList',
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-wrap: wrap;
}
</style>
