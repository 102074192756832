<template>
  <div class="form-contents" :class="$style.wrap">
    <div v-if="type === 2">
      <p>現在配達は群馬県桐生市・みどり市のいずれかの市内のみになります。</p>
      <p>
        該当市内一部区域は翌朝の朝刊、または配達不可となります。
        <span
          @click="openAreaInfo"
          :class="$style.areaDetail">詳細はこちら</span>
      </p>
    </div>
    <dl
      :class="$style.label_wrap"
      class="form-group"
      v-for="(item, i) in items"
      v-bind:key="i"
      >
      <div v-if="((item.isShowType2 && type === 2) || (!item.isNoShowType1 && type === 1))">
        <dt class="label" :class="[$style.title_wrap, { required: item.required }]">
          <label
            :class="$style.label"
            class="form-label"
            v-bind:for="item.name">{{ item.label }}</label>
          <Spacer :x="1"/>
          <p v-if="item.required" :class="$style.required">※必須項目</p>
        </dt>
        <dd
          class="detail"
          :class="$style.content">
          <div class="form-parts">
            <input
              :class="$style.input"
              :id="item.name"
              :type="item.type"
              :name="item.name"
              :placeholder="item.placeholder"
              @input="invalidsCheck(item.name, $v[item.name].$invalid)"
              v-model.trim="$v[item.name].$model"
              @blur="addPref(item.name)"
              v-if="(item.type === 'text' || item.type === 'email')
                && $v[item.name]">

            <input
              :class="$style.input"
              :id="item.name"
              :type="item.type"
              :name="item.name"
              :disabled="isConfirm"
              :placeholder="item.placeholder"
              @input="sendValue"
              v-model="this[item.name]"
              v-if="item.type === 'text' && !$v[item.name]">

            <select
              :class="$style.select"
              :id="item.name"
              :name="item.name"
              @change="invalidsCheck(item.name, $v[item.name].$invalid)"
              v-model.trim="$v[item.name].$model"
              v-if="item.type === 'select'">
              <option :value="''">選択してください</option>
              <option
                v-for="option in item.options"
                :key="option.value"
                :value="option.value"
                :selected="option.value === user.address[item.name]">{{ option.label }}</option>
            </select>

            <ul
              class="select-list mt-2 category"
              v-if="item.type === 'radio'"
              :class="$style.radio_wrap">
              <li
                v-for="(option) in item.options"
                :key="option.value"
                :class="$style.radio">
                <input
                  type="radio"
                  :name="option.value"
                  :id="option.value"
                  :value="option.value"
                  v-model.trim="$v[item.name].$model"
                  style="display: none"
                  @change="invalidsCheck(item.name, $v[item.name].$invalid)">
                <label
                  :for="option.value"
                  :class="[$style.radio_label , option.value === this[item.name] ? $style.checked : '']">{{ option.label }}</label>
              </li>
            </ul>
          </div>
          <p
            :class="$style.assistant"
            class="form-assistance"
            v-if="item.comment"
            v-html="item.comment"></p>
          <p
            class="form-text text-danger"
            v-if="$v[item.name]
              && $v[item.name].$dirty
              && $v[item.name].required
              && $v[item.name].required.$invalid">「{{ item.label }}」は必須項目です</p>
          <p
            class="form-text text-danger"
            v-if="$v[item.name]
              && $v[item.name].$dirty
              && $v[item.name].integer
              && $v[item.name].integer.$invalid">「{{ item.label }}」はハイフンなしで半角数値を入力してください</p>
        </dd>
      </div>
    </dl>
    <Spacer :y="3"/>
    <ul v-if="user"
      class="btn-list horizontal">
      <li v-if="showRegistBtn">
        <button
          :class="[$style.btn, $style.primary]"
          :disabled="!isReadySubmit"
          v-on:click="submit(1)">住所を登録</button>
      </li>
      <Spacer :y="1"/>
      <li v-if="user && targetAddress && user.address && user.address.length">
        <button
          :class="[$style.btn, $style.secondary]"
          v-on:click="cancelEdit">キャンセル</button>
      </li>
      <Spacer :y="1"/>
      <li v-if="user && targetAddress && targetAddress.flag !== 999 && showDeleteBtn">
        <button
          :class="[$style.btn, $style.danger]"
          v-on:click="submit(999)">削除</button>
      </li>
    </ul>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, integer } from '@vuelidate/validators';
import { jsonp } from 'vue-jsonp';
import cf from '@/mixins/commonFunctions';
import Spacer from '@/components/Spacer';
// import { cloneDeep } from 'lodash';

export default {
  name: 'form-address',
  mixins: [cf],
  props: ['type', 'showDeleteBtn', 'showRegistBtn'],
  components: {
    Spacer,
  },
  data() {
    return {
      invalids: {
        zip: true,
        pref: true,
        city: true,
        town: true,
        address: true,
        username: true,
        tel: true,
      },
      // vuelidateを使用しない項目
      building: null,
      items: [
        {
          name: 'zip',
          type: 'text',
          label: '郵便番号',
          value: null,
          required: true,
          placeholder: '1020074',
          comment: '半角・ハイフンなしで入力してください',
          isShowType2: false,
        },
        {
          name: 'pref',
          type: 'select',
          label: '都道府県',
          value: null,
          options: [],
          required: true,
          comment: '',
          isShowType2: false,
        },
        {
          name: 'city',
          type: 'text',
          label: '市区町村',
          value: null,
          required: true,
          placeholder: '桐生市東',
          isShowType2: false,
        },
        {
          name: 'city',
          type: 'radio',
          label: '市',
          value: null,
          options: [
            {
              value: '桐生市',
              label: '桐生市',
            },
            {
              value: 'みどり市',
              label: 'みどり市',
            },
          ],
          required: true,
          isNoShowType1: true,
          isShowType2: true,
        },
        {
          name: 'town',
          type: 'text',
          label: '町村',
          value: null,
          required: true,
          placeholder: '東',
          isShowType2: true,
        },
        {
          name: 'address',
          type: 'text',
          label: '番地',
          value: null,
          required: true,
          placeholder: '4-5-21',
          isShowType2: true,
        },
        {
          name: 'building',
          type: 'text',
          label: '建物名・部屋番号',
          placeholder: 'ビル1階',
          value: null,
          isShowType2: true,
        },
        {
          name: 'username',
          type: 'text',
          label: 'お届け先名',
          required: true,
          placeholder: '桐生　太郎',
          value: null,
          isShowType2: true,
        },
        {
          name: 'tel',
          type: 'text',
          label: '電話番号',
          required: true,
          placeholder: '0277-46-2511',
          value: null,
          isShowType2: true,
        },
      ],
    };
  },
  created() {
    if (this.user.email) {
      this.setAddressData();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') this.setAddressData();
      });
    }
    if (this.helper.master.prefs) {
      this.items[1].options = this.helper.master.prefs;
    } else {
      this.$store.subscribe((mutations) => {
        if (mutations.type === 'helper/putMaster') {
          this.items[1].options = mutations.payload.prefs;
        }
      });
    }
  },
  setup() {
    const zip = ref('');
    const pref = ref('');
    const city = ref('');
    const town = ref('');
    const address = ref('');
    const username = ref('');
    const tel = ref('');

    const rules = {
      zip: { required, integer },
      pref: { required },
      city: { required },
      town: { required },
      address: { required },
      username: { required },
      tel: { required },
    };

    const $v = useVuelidate(rules, {
      zip,
      pref,
      city,
      town,
      address,
      username,
      tel,
    });
    return {
      zip,
      pref,
      city,
      town,
      address,
      username,
      tel,
      $v,
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
    isReadySubmit() {
      const keys = Object.keys(this.invalids);
      const bools = [];
      keys.some((key) => { bools.push(this.invalids[key]); return false; });
      return !bools.includes(true);
    },
    targetAddress() {
      if (this.user && this.user.address && this.user.address.length) {
        // typeから対象のaddress特定
        const addressArray = this.user.address.filter((addr) => addr.type === Number(this.type));
        if (addressArray.length) {
          return addressArray[0];
        }
        return null;
      }
      return null;
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    openAreaInfo() {
      const args = {
        modalName: 'delivery-area-info',
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },

    async addPref(fieldName) {
      if (fieldName !== 'zip') return;
      await jsonp(`https://api.zipaddress.net/?zipcode=${this.zip}`)
        .then((response) => {
          if (response.pref && response.address) {
            this.pref = response.pref;
            this.city = response.address;
          } else {
            this.pref = null;
            this.city = null;
          }
          // 郵便番号は合併などで正しいが反映がされていない場合を考慮
          this.checkInvalids();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    /** this.user.addressに値があればセット */
    setAddressData() {
      if (this.user.address && this.type) {
        const updateKeys = ['zip', 'pref', 'city', 'address', 'building', 'username', 'tel'];
        if (this.targetAddress && this.targetAddress.flag === 1) {
          const address = this.targetAddress;
          const keys = Object.keys(address);
          keys.forEach((key) => {
            if (updateKeys.includes(key)) {
              if (key === 'city' && this.type === 2) {
                if (address[key].includes('桐生市') || address[key].includes('みどり市')) {
                  const isKiryu = address[key].includes('桐生市');
                  this.city = isKiryu ? '桐生市' : 'みどり市';
                  this.town = isKiryu ? address[key].replace('桐生市', '') : address[key].replace('みどり市', '');
                }
              } else {
                this[key] = address[key];
                if (address[key]) this.invalids[key] = false;
              }
            }
          });
        }
      }
      if (this.type === 2) {
        this.pref = '群馬県';
        if (!this.city) this.city = '桐生市';
      } else {
        this.town = 'valid'; // type = 1のとき、townのvalidを外しておく
      }
      this.checkInvalids();
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
      if (this.type === 2) {
        if (this.city === '桐生市') {
          this.zip = '3760000';
        } else if (this.city === 'みどり市') {
          this.zip = '3760100';
        }
        this.checkInvalids();
      }
    },

    /** 現状の入力状態でinvalidsをチェック */
    checkInvalids() {
      const addressColumn = Object.keys(this.invalids);
      addressColumn.forEach((key) => {
        if (this[key]) this.invalids[key] = false;
        else this.invalids[key] = true;
      });
      this.sendInvalidsFlag();
    },

    sendInvalidsFlag() {
      this.$emit('sendInvalidsFlag', this.isReadySubmit);
    },

    cancelEdit() {
      this.$emit('isCancel');
    },

    submit(flag) {
      if (!this.isReadySubmit) return;
      if (flag === 999 && !confirm('住所を削除してよろしいですか？')) return;
      this.showLoading();

      const data = {
        type: this.type,
        flag,
        user_id: this.user.id,
        zip: this.zip,
        pref: this.pref,
        city: this.type === 2 ? `${this.city}${this.town}` : this.city,
        address: this.address,
        building: this.building,
        username: this.username,
        tel: this.tel,
      };

      if (this.targetAddress && this.targetAddress.id) {
        data.id = this.targetAddress.id;
      }

      // deliveryUsersにレコードがない場合にはupdate対象外(次回増減判定で最新の住所が自動追加)
      const isExistsType2 = this.user.address.some((row) => row.type === 2 && row.flag === 1);
      const oldAddresses = this.user.address;
      let newAddress = data;
      let oldAddress = oldAddresses.find((row) => row.type === 2 && row.flag === 1);

      const postType = this.targetAddress ? 'updater' : 'register';
      const label = flag === 1 ? '登録' : '削除';
      this.axios({
        method: 'POST',
        url: `/v1/address/set/${postType}`,
        data,
      })
        .then(async () => {
          if (!isExistsType2 || this.type !== 1) {
            await this.updateDeliveryUser(oldAddress, newAddress);
          }
          this.$store.dispatch('user/update');
          this.$emit('updated');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          alert(`住所を${label}しました。`);
          newAddress = null;
          oldAddress = null;
          this.hideLoading();
        });
    },

    async updateDeliveryUser(oldAddress, newAddress) {
      if (!oldAddress) return;
      newAddress.address = `〒${newAddress.zip} ${newAddress.pref}${newAddress.city}${newAddress.address} ${newAddress.building || ''}`;
      oldAddress.address = `〒${oldAddress.zip} ${oldAddress.pref}${oldAddress.city}${oldAddress.address} ${oldAddress.building || ''}`;

      // deliveryUsersは更新があれば保存しておく
      const data = {
        user_id: this.user.id,
        sendFlag: 3,
      };

      // oldがあれば別途確認
      const tempValues = [];
      // 確認対象のカラム
      const checkColumns = [
        {
          column: 'username',
          type: 1,
        },
        {
          column: 'address',
          type: 2,
        },
        {
          column: 'tel',
          type: 3,
        },
      ];
      // 変更が必要な項目を確認
      checkColumns.forEach((row) => {
        if (oldAddress[row.column] !== newAddress[row.column]) {
          tempValues.push({
            type: row.type,
            user_id: this.user.id,
            value: oldAddress[row.column] || '未登録', // addressにtel, username実装前に登録したaddressを更新した場合のエラー回避
          });
        }
      });

      // 住所変更があったことをdeliveryUsersに保存
      this.axios({
        method: 'POST',
        url: '/v1/deliveryuser/set/updater',
        data,
      })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
        });

      // 変更前情報をtemporariesに保存
      await Promise.all(
        tempValues.map(async (row) => {
          await this.axios({
            method: 'POST',
            url: '/v1/temporary/set/register',
            data: row,
          })
            .then(() => {
            })
            .catch((error) => {
              if (error.message) console.log(error.message);
              else console.log(error);
            })
            .finally(() => {
            });
        }),
      );
    },
  },
};
</script>

<style lang="scss" module>
.areaDetail {
  color: var(--font-gray);
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  white-space: nowrap;
}

.wrap {
  dl {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
.label {
  font-size: 14px;
  margin-bottom: 0;
  &_wrap {
    margin: 0 0;
  }
}
.content {
  margin-left: 0;
}
.input {
  width: 100%;
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  $background: var(--gray-sub);
  border-bottom: 1px solid var(--gray);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }

  &:disabled {
    background-color: transparent;
    color: black;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
  &::placeholder {
    opacity: .6;
  }
}
.select {
  padding: 13px;
  border: none;
  border-radius: 8px;
  background-color: var(--gray-sub);
  appearance: none;
  border-bottom: 1px solid var(--gray);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  &:disabled {
    background-color: transparent;
    color: black;
  }
}

.radio {
  &_wrap {
    display: flex;
    flex-wrap: wrap;
  }

  margin-bottom: 4px;

  &:not(:last-child) {
    margin-right: 4px;
  }

  &_label {
    padding: 4px 12px;
    border-radius: 4px;
    border: 1px solid var(--lightgray);
    color: var(--gray);
    &.checked {
      border-color: var(--gray);
      color: var(--black);
    }
  }
}

.assistant {
  font-size: 12px;
}
.auto_input_btn {
  margin-top: 5px;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 128px;
  height: 40px;
  padding: 13px 26px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  border-radius: 20px;
  cursor: pointer;
  appearance: none;
  box-shadow: none;
  transition: all .3s;

  &.primary {
    background-color: rgba(26, 34, 61, 1);
    border: none;
    color: #fff;
    &:hover, &.disabled {
      opacity: .6;
      cursor: default;
    }
  }

  &.secondary {
    border: 1px solid rgba(26, 34, 61, 1);
    color: var(--font-black);
    background-color: #fff;
    &:hover {
      opacity: .4;
    }
  }
  &.danger {
    border: 0;
    color: #fff;
    background-color: var(--red);
    &:hover {
      opacity: .4;
    }
  }
  &:disabled {
    background: #ccc;
  }
}
.title_wrap {
  display: flex;
  align-items: baseline;

}
.required {
  font-size: 10px;
  color: var(--red);
}
</style>
