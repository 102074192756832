<template>
  <div :class="$style.col">
    <router-link :class="$style.link" :to="`/article/${article.slug}/`">
      <div :class="$style.inner">
        <div :class="$style.title">{{ article.title }}</div>
        <p :class="$style.description" v-html="omitDescription(article.description)"></p>
        <div :class="$style.meta">
          <!-- <span :class="$style.isNew">NEW</span> -->
          <span 
          v-for="category in article.categories"
          :class="$style.categoryTag"
          :key="category.id">{{ category.label }}</span>
          <time :class="$style.createdAt">
            {{ formatTimestamp(article.releaseDate, 'YYYY.MM.DD') }}
          </time>
        </div>
      </div>
      <ArticleThumbnail
        :class="$style.thumbnail"
        v-if="article.urls?.thumbnail?.[0]?.image?.url"
        :src="article.urls?.thumbnail?.[0]?.image?.url"
        :alt="article.title"
      />
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import cf from '@/mixins/commonFunctions.js';
import ArticleThumbnail from '@/components/ArticleList/ArticleThumbnail';

export default {
  name: 'TopArticleItem',
  components: { ArticleThumbnail },
  mixins: [cf],
  props: ['article'],
  computed: {
    ...mapState(['view']),
    isSmView() {
      return this.view.display_size === 'sm';
    },
    isNew() {
      const releaseDate = this.formatTimestamp(this.article.releaseDate);
      const date = this.formatTimestamp(moment().add(-24, 'h'));
      return releaseDate > date;
    },
  },
  methods: {
    // descriptionの文末を…にする
    omitDescription(des) {
      if (des) {
        let omittedStr = des;
        if (des.length > 80) {
          omittedStr = des.substring(0, 80);
          omittedStr += '...';
        } else {
          omittedStr = omittedStr.slice(0, -3);
          omittedStr += '...';
        }
        return omittedStr;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" module>
.col {
  position: relative;
  display: block;
  width: 100%;
}

.categoryTag {
  padding: 0 8px;
  border-radius: 7px;
  overflow-wrap: break-word;
  margin-right: var(--s) / 2;
  background: var(--secondary-surface);
  color: var(--primary-label);
  font-size: 11px;
  margin-right: 8px;
}

.link {
  position: relative;
  display: flex;
  gap: calc(var(--s) * 2.5);

  @include sm-view {
    padding-bottom: 95px;
  }
}

.inner {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: calc(var(--s) * 1.5);

  @include sm-view {
    position: absolute;
    bottom: 0;
    left: calc(var(--g) * -1);
    padding: calc(var(--s) * 3) calc(var(--g)) 0;
    z-index: 2;
    width: 94.855305%;
    max-width: 500px;
    background-color: #fff;
  }
}

.title {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 700;
  color: var(--font-black);

  @include sm-view {
    font-size: 20px;
  }
}

.description {
  font-size: 14px;
  font-weight: 400;
  color: var(--font-gray);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;

  @include sm-view {
    -webkit-line-clamp: 2;
    height: 51px;
  }
}

.meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}


.isNew {
  display: block;
  width: fit-content;
  padding: 4px 11px;
  border-radius: 2px;
  background-color: var(--accent);
  color: #fff;
  font-weight: 900;
  font-size: 10px;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 0.04em;
}

.createdAt {
  font-size: 12px;
  line-height: 1;
  color: var(--font-gray);
}

.thumbnail {
  @include sm-view {
    position: relative;
    z-index: 1;
    margin: 0 calc(50% - 50vw);
    max-width: 100vw;
    width: 100vw;
  }
}
</style>
