<template>
  <li :class="$style.tag">
    <router-link :class="$style.link" :to="`/tag/${tag.name}/`">
      #{{ tag.label }}
    </router-link>
  </li>
</template>

<script>
import cf from '@/mixins/commonFunctions.js';
// import Spacer from '@/components/Spacer';

export default {
  name: 'TagListItem',
  mixins: [cf],
  // components: { Spacer },
  props: ['tag'],
};
</script>

<style lang="scss" module>
.tag {
  margin: 0 8px 7px 0;
}
.link {
  color: var(--font-black);
  cursor: pointer;
  padding: 12px 12px;
  border-radius: 24px;
  background-color: var(--lightgray);
  display: flex;
}
</style>
