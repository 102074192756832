<template>
  <InviewTarget
    :use-inview="useInview"
    tag="li"
    :class="liClassName"
    @inview="handleInview"
  >
    <router-link :class="$style.link" :to="`/article/${article.slug}/`">
      <div :class="$style.inner">
        <div :class="$style.title">{{ article.title }}</div>
        <p :class="$style.description" v-html="omitDescription(article.description)"></p>
        <div :class="$style.meta">
          <!-- <span :class="$style.isNew">NEW</span> -->
          <span 
          v-for="category in article.categories"
          :class="$style.categoryTag"
          :key="category.id">{{ category.label }}</span>
          <time :class="$style.createdAt">{{ formatTimestamp(article.releaseDate, 'YYYY.MM.DD') }}</time>
        </div>
      </div>
      <ArticleThumbnail
        :class="$style.thumb"
        :src="article.urls?.thumbnail?.[0]?.image?.url"
        :alt="article.title"
      />
    </router-link>
  </InviewTarget>
</template>

<script>
import cf from '@/mixins/commonFunctions.js';
import InviewTarget from '@/components/InviewTarget';
import ArticleThumbnail from './ArticleThumbnail';

export default {
  name: 'ArticleSimpleListItem',
  components: { InviewTarget, ArticleThumbnail },
  mixins: [cf],
  props: {
    article: {
      type: Object,
      required: true,
    },
    useInview: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isInview: false,
    };
  },
  methods: {
    // descriptionの文末を…にする
    omitDescription(des) {
      if (des) {
        let omittedStr = des;
        if (des.length > 80) {
          omittedStr = des.substring(0, 80);
          omittedStr += '...';
        } else {
          omittedStr = omittedStr.slice(0, -3);
          omittedStr += '...';
        }
        return omittedStr;
      }
      return '';
    },
    handleInview() {
      this.isInview = true;
    },
  },
  computed: {
    liClassName() {
      return `${this.$style.col} ${this.useInview ? this.$style.useInview : ''} ${this.isInview ? this.$style.isInview : ''}`;
    },
  },
};
</script>

<style lang="scss" module>
.col {
  position: relative;
  display: block;
  width: 100%;
  &:not(:first-child) {
    border-top: 1px solid var(--border-gray);
  }

  &.useInview {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    pointer-events: none;
    transition: all 300ms 200ms ease-in-out;

    &.isInview {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      pointer-events: auto;
    }
  }
}

.categoryTag {
  padding: 0 8px;
  border-radius: 7px;
  overflow-wrap: break-word;
  margin-right: var(--s) / 2;
  background: var(--secondary-surface);
  color: var(--primary-label);
  font-size: 11px;
  margin-right: 8px;
}

.link {
  display: flex;
  align-items: flex-start;
  padding: 20px 0;
  gap: calc(var(--s) * 2.5);
}

.inner {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: calc(var(--s) * 1.5);
}

.title {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 700;
  color: var(--font-black);

  @include sm-view {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

.description {
  font-size: 14px;
  font-weight: 400;
  color: var(--font-gray);

  @include sm-view {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
}

.meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}


.isNew {
  display: block;
  width: fit-content;
  padding: 4px 11px;
  border-radius: 2px;
  background-color: var(--accent);
  color: #fff;
  font-weight: 900;
  font-size: 10px;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 0.04em;
}

.createdAt {
  font-size: 12px;
  line-height: 1;
  color: var(--font-gray);
}

.thumb {
  width: 120px;
}
</style>
