<template>
  <div class="userLimited">
    <p :class="$style.modal_title">{{ data.message || '続きを閲覧するには新規会員登録またはログインが必要です。' }}</p>
    <div :class="$style.wrap">
      <router-link
        :to="'/login/'"
        :class="[$style.login, $style.btn]"
        @click="saveLoginRef">
        ログイン
      </router-link>
      <Spacer :x="3"/>
      <router-link
        :to="'/signup/'"
        :class="[$style.signin, $style.btn]"
        @click="saveLoginRef">
        新規会員登録
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Spacer from '@/components/Spacer';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'user-limited',
  props: ['data'],
  components: {
    Spacer,
  },
  data() {
    return {
      flag: {
        invalid: false,
      },
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    hideModal() {
      this.$store.dispatch('modal/contents/hideModal', null, { root: true });
    },
    saveLoginRef() {
      const current = this.$router.options.history;
      const ref = { path: this.data.path || current.location };
      // ログイン後に元いたページへ戻すためpathとqueryを保持しておく
      cf.saveLocalStorage({ loginRef: JSON.stringify(ref), query: this.data.query || null }, 'times');
      this.hideModal();
    },
  },
};
</script>

<style lang="scss" module>
.modal_title {
  text-align: center;
  margin-bottom: 32px;
  font-size: 16px;
  @include sm-view {
    margin-bottom: 20px;
    font-size: 12px;
  }
}
.wrap {
  display: flex;
  justify-content: center;
  @include sm-view {
    flex-flow: column;
  }
}

.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
  @include sm-view {
    text-align: center;
  }
}

.signin {
  background-color: rgba(26, 34, 61, 1);
  border: none;
  color: #fff;
  &:hover {
    color: #fff;
  }
}

.login {
  border: 1px solid rgba(26, 34, 61, 1);
  color: var(--font-black);
  background-color: #fff;
  &:hover {
    color: var(--font-black);
  }
}
</style>
