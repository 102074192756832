import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home/index.vue';
import ArticleSinglePage from '@/views/Article/_id.vue';
import CategoryPage from '@/views/Category/_slug.vue';
import PaperPage from '@/views/Paper/_slug.vue';
import Search from '@/views/Search/Search.vue';

/** @Pages */
import Policy from '@/views/Pages/Policy.vue';
import Terms from '@/views/Pages/Terms.vue';
import Subscriptions from '@/views/Pages/Subscriptions.vue';
import TransactionLaw from '@/views/Pages/TransactionLaw.vue';
import Contact from '@/views/Pages/Contact.vue';

/** @Account */
import Account from '@/views/Account/Account.vue';
import Login from '@/views/Account/Login.vue';
import Signup from '@/views/Account/Signup.vue';
import SignupPassword from '@/views/Account/SignupPassword.vue';
import ForgotPassword from '@/views/Account/ForgotPassword.vue';
import ResetPassword from '@/views/Account/ResetPassword.vue';

/** @Edits */
import EditEmail from '@/views/Edit/Email.vue';
import EditUpdatedEmail from '@/views/Edit/UpdatedEmail.vue';
import EditPassword from '@/views/Edit/Password.vue';
import EditProfile from '@/views/Edit/Profile.vue';
import EditSubscription from '@/views/Edit/Subscription.vue';

/** @Error */
import NotFound from '@/views/Error/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/article/:slug',
    name: 'ArticleSinglePage',
    component: ArticleSinglePage,
  },
  {
    path: '/category/:slug',
    name: 'CategoryPage',
    component: CategoryPage,
  },
  {
    path: '/paper/:slug',
    name: 'PaperPage',
    component: PaperPage,
  },
  {
    path: '/tag/:slug',
    name: 'TagPage',
    component: CategoryPage,
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: Subscriptions,
  },

  /** @Pages */
  {
    path: '/policy',
    name: 'policy',
    component: Policy,
    meta: {
      title: '個人情報保護方針',
      description: '',
      og_image: '',
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
    meta: {
      title: '利用規約',
      description: '',
      og_image: '',
    },
  },
  {
    path: '/transactionLaw',
    name: 'transactionLaw',
    component: TransactionLaw,
    meta: {
      title: '特定商取引法に基づく表示',
      description: '',
      og_image: '',
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      title: 'お問い合わせ',
      description: '',
      og_image: '',
    },
  },

  /** @Account */
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'ログイン',
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      title: 'ユーザー仮登録',
    },
  },
  {
    path: '/signup/password',
    name: 'SignupPassword',
    component: SignupPassword,
    meta: {
      title: 'ユーザー登録',
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'パスワード再発行',
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: 'パスワード再設定',
      description: '',
      og_image: '',
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      title: 'アカウント',
    },
  },

  /** @Edits */
  {
    path: '/edit/email',
    name: 'edit-email',
    component: EditEmail,
    meta: {
      title: 'メールアドレス更新',
    },
  },
  {
    path: '/edit/email/updated',
    name: 'updated-email',
    component: EditUpdatedEmail,
    meta: {
      title: 'メールアドレス更新完了',
    },
  },
  {
    path: '/edit/password',
    name: 'edit-password',
    component: EditPassword,
    meta: {
      title: 'パスワード変更',
    },
  },
  {
    path: '/edit/profile',
    name: 'edit-profile',
    component: EditProfile,
    meta: {
      title: 'プロフィール変更',
    },
  },
  {
    path: '/edit/subscription',
    name: 'edit-subscription',
    component: EditSubscription,
    meta: {
      title: '会員ステータス変更',
    },
  },

  /** @Error */
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'お探しのページは見つかりませんでした',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // ページ遷移時
  scrollBehavior(to, from, savedPosition) {
    let position = {};
    // fromとtoの違いがない場合はスルー
    if (from.path && to.path === from.path) return;
    if (!to.hash) {
      // ハッシュがなく、
      if (savedPosition) { // ブラウザバック
        position = { top: savedPosition.top };
      } else { // ページ遷移
        position = { top: 0 };
      }
    } else {
      // ハッシュが存在する場合スクロール
      position = { selector: to.hash };
    }
    return position;
  },
});

export default router;
