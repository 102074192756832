<template>
  <component :is="tag">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'InviewTarget',
  props: {
    tag: {
      type: String,
      required: false,
      default: 'div',
    },
    useInview: {
      type: Boolean,
      required: false,
      default: true,
    },
    rootMargin: {
      type: String,
      required: false,
      default: '0px',
    },
    once: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      observer: null,
    };
  },
  mounted() {
    if (!this.useInview) return;
    this.observer = new IntersectionObserver(
      this.callback,
      { rootMargin: this.rootMargin },
    );
    this.observer.observe(this.$el);
  },
  methods: {
    callback(changes) {
      changes.forEach((change) => {
        if (change.isIntersecting) {
          if (this.once) this.observer.unobserve(this.$el);
          this.$emit('inview');
        }
      });
    },
  },
};
</script>
