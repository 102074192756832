<template>
  <div>
    <h2>プロフィール編集</h2>
    <spacer :y="5"/>
    <form v-on:submit.prevent="updateFlagConfirm">
      <div>
        <!-- プロフィールアイコン -->
        <div>
          <p :class="$style.label">プロフィールアイコン</p>
          <Spacer :y="1"/>
          <div>
            <div :class="$style.preview_wrap">
              <input
                id="file"
                type="file"
                name="file"
                accept=".jpg, .jpeg, .png, .gif"
                v-if="validate.view"
                v-bind:disabled="flag.confirm"
                v-on:change="updateFile"
                v-show="false">
              <label
                v-if="flag.showFileInput"
                for="file" :class="[$style.preview, $style.fileInput]"/>
              <div
                v-if="!flag.isSaved && !flag.showFileInput"
                :class="$style.preview"
                :style="`background-image: url(${previewIconUrl})`"></div>
              <div
                v-if="flag.isSaved && !flag.showFileInput"
                :class="$style.preview"
                :style="`background-image: url(${getMyIconUrl(user)})`"></div>
            </div>
            <div v-if="!flag.showFileInput">
              <Spacer :y="1"/>
              <button
                type="button"
                :class="$style.deleteBtn"
                v-on:click="flag.isSaved ? deleteURL() : deletePreviewImg()">画像削除・変更</button>
            </div>
            <spacer :y="1"/>
            <p :class="$style.assistant"
              >推奨サイズ：110 x 110<br>ファイルタイプ：.jpg, .jpeg, .png, .gif</p>
          </div>
        </div>

        <!-- プロフィールアイコン以外 -->
        <div
          v-for="(item, i) in formItems"
          v-bind:key="i">
          <Spacer :y="3"/>
          <p :class="$style.label">{{ item.label }}</p>
          <div>
            <div :class="$style.input">
              <input
                :class="$style.text"
                :id="item.name"
                :type="item.type"
                :name="item.name"
                :disabled="flag.confirm"
                v-model="item.value"
                v-if="item.type === 'text'"
                @:change="updateValue($event, i)">

              <textarea
                :class="$style.textarea"
                :id="item.name"
                :name="item.name"
                :disabled="flag.confirm"
                v-model="item.value"
                rows="5"
                v-if="item.type === 'textarea'"
                @:change="updateValue($event, i)"></textarea>

              <select
                :id="item.name"
                :name="item.name"
                :disabled="flag.confirm"
                :class="$style.select"
                v-model="item.value"
                v-if="item.type === 'select'">
                <option value="0">選択してください</option>
                <option
                  v-for="option in item.options"
                  :key="option.value"
                  :value="option.value"
                  :selected="option.value === user[item.name]">{{ option.label }}</option>
              </select>
            </div>
            <p
              :class="$style.assistant"
              v-if="item.comment"
              v-html="item.comment"></p>
          </div>
        </div>
      </div>

      <Spacer :y="4"/>

      <div :class="$style.flex">
        <button
          v-if="!flag.confirm"
          type="submit"
          :class="$style.btn"
          id="btn_check"
          >確認</button>
        <spacer v-if="!flag.confirm" :x="2"/>
        <router-link
          v-if="!flag.confirm"
          to="/account/">
          <div
            :class="[$style.btn, $style.bdr]">戻る</div>
        </router-link>
        <div
          v-if="flag.confirm"
          :class="$style.btn"
          id="btn_submit"
          v-on:click="submit">登録</div>
        <spacer v-if="flag.confirm" :x="2"/>
        <div
          v-if="flag.confirm"
          :class="`${$style.btn} ${$style.bdr}`"
          id="btn_back"
          v-on:click="updateFlagConfirm">編集画面に戻る</div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import cf from '@/mixins/commonFunctions';
import Spacer from '@/components/Spacer.vue';

export default {
  name: 'edit-profile',
  mixins: [cf],
  components: {
    Spacer,
  },
  data() {
    return {
      file: null,
      previewIconUrl: null,
      validate: {
        size: false,
        view: true,
      },
      flag: {
        confirm: false,
        showFileInput: true,
        isSaved: true,
      },
      formItems: [
        {
          name: 'last_name',
          type: 'text',
          label: 'お名前(姓)',
          value: null,
          comment: '',
        },
        {
          name: 'first_name',
          type: 'text',
          label: 'お名前(名)',
          value: null,
          comment: '',
        },

      ],
    };
  },
  created() {
    if (this.user.email) {
      this.setUserData();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.setUserData();
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** state.userの値をフォームにセット */
    setUserData() {
      const clone = cloneDeep(this.user);
      const keys = Object.keys(clone);
      // フォーム項目に該当する値のみを抽出
      this.formItems.forEach((item, i) => {
        if (keys.includes(item.name)) {
          this.formItems[i].value = clone[item.name];
        }
      });

      // アイコンが存在する
      if (clone.urls.icon.length
        && clone.urls.icon[0].url) {
        this.flag.showFileInput = false;
      }
    },

    /** 確認と編集の切り替え */
    updateFlagConfirm(e) {
      const id = e.currentTarget.id;
      const next = id && id === 'btn_back';
      this.flag.confirm = !next;
      if (next) {
        this.$scrollTo('#edit-top');
      }
    },

    /** プロフィールテキストの更新 */
    updateValue(e, index) {
      const value = e.currentTarget.value;
      this.formItems[index].value = value;
    },

    /** 画像の更新 */
    updateFile(e) {
      const files = e.target.files || e.dataTransfer.files;

      // files.lengthが0の場合はキャンセル時
      if (!files.length) {
        this.file = null;
        return;
      }

      // ファイルのサイズチェック（上限2mb）
      const limit = 1024 * 1024 * 2;
      if (files[0].size > limit) {
        this.validate.size = true;
        // ファイルのリセットは非表示→表示で生成しなおす
        this.validate.view = false;
        this.$nextTick(() => {
          this.validate.view = true;
        });
        alert('ファイルサイズの上限は2MBまでです。\n再度インプットしてください。');
        return;
      }

      // ファイルを格納
      this.file = files[0];
      this.validate.size = false;

      // プレビュー表示
      this.previewIcon();
    },

    previewIcon() {
      const path = window.URL.createObjectURL(this.file);

      this.previewIconUrl = path;
      this.flag.isSaved = false;
      this.flag.showFileInput = false;
    },

    /**
     * 確認後のサブミット
     * ファイルが存在する場合は更新・登録
     * ファイル削除時にはnullを登録
     * それ以外はユーザ情報のみ更新
     */
    submit() {
      this.showLoading();

      if (this.file) {
        // 画像更新・登録
        this.uploadFile();
      } else if (this.user.urls.icon.length
        && !this.user.urls.icon[0].url) {
        // 画像URLをnullに更新
        this.updateUrl({ uploaded: { s3Path: null } });
      } else {
        // ユーザ情報だけ更新
        this.updateBaseData();
      }
    },

    /** ファイルのアップロード */
    uploadFile() {
      // 保存時formDataに落とし込む
      const form = new FormData();
      form.append('file', this.file);

      this.axios({
        method: 'POST',
        url: '/v1/user/set/uploadfile',
        data: form,
        params: {
          id: this.user.id,
          environment: this.helper.env.name,
        },
      })
        .then((response) => {
          // URLの紐付けを保存
          this.updateUrl(response.data.data);
        })
        .catch((error) => {
          alert('プロフィール画像のアップロードに失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.hideLoading();
        });
    },

    /** URLの紐付け更新 */
    updateUrl(uploadData) {
      // 既存の紐付けがあるか新規登録か
      const id = this.user.urls.icon.length
        ? this.user.urls.icon[0].id
        : null;

      const data = {
        id,
        user_id: this.user.id,
        url: uploadData.uploaded.s3Path,
        type: 2, // アイコンは2
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/updateUrl',
        data,
      })
        .then(() => {
          // 初期化
          this.file = null;
          // アイコンの表示非表示
          const next = uploadData.uploaded.s3Path === null;
          this.flag.showFileInput = next;
        })
        .catch((error) => {
          alert('プロフィール画像の更新に失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.updateBaseData();
        });
    },

    /** 基本データの更新 */
    updateBaseData() {
      const data = { id: this.user.id };
      this.formItems.forEach((item) => {
        data[item.name] = item.value;
      });

      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          alert('プロフィールを更新しました。');
          this.flag.confirm = false;
          this.$store.dispatch('user/update', null, { root: true });
        })
        .catch((error) => {
          alert('プロフィールの更新に失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    /** 画像URL削除 */
    deleteURL() {
      this.user.urls.icon[0].url = null;
      // input[type=file]の表示
      this.flag.showFileInput = true;
    },

    /** プレビュー画像URL削除 */
    deletePreviewImg() {
      this.file = null;
      this.previewIconUrl = null;
      // input[type=file]の表示
      this.flag.showFileInput = true;
    },
  },
};
</script>

<style lang="scss" module>
.label {
  font-weight: bold;
}
.assistant {
  font-size: 12px;
}
.preview {
  &_wrap {
    width: 100px;
    position: relative;
  }
  display: block;
  width: 100%;
  padding-top: 100%;
  background-color: var(--lightgray);
  background-size: cover;
  background-position: center;
  &.fileInput {
    &::after, &::before {
      content: '';
      width: 20px;
      height: 2px;
      background-color: var(--gray);
      position: absolute;
      top: 50%;
      left: 50%;
    }
    &::before {
      transform: translate(-50%, -50%);
    }
    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}
.input {
  input {
    width: 100%;
    border: 1px solid var(--lightgray);
    outline: none;
    padding: 12px;
  }
}
.btn {
  line-height: normal;
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
  &.bdr {
    background-color: transparent;
    color: var(--font-black);
    border: 1px solid var(--font-black);
  }
}
.flex {
  display: flex;
}
.deleteBtn {
  outline: none;
  border: 1px solid var(--font-black);
  background-color: transparent;
  padding: 2px 12px;
  transition: all .3s;
  &:hover {
    opacity: .4;
  }
}
</style>
