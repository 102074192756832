<template>
  <div :class="$style.container">
    <div :class="$style.today">
      <div :class="$style.year">{{ queryDate.year }}年</div>
      <div :class="$style.date">
        <span>{{ queryDate.month }}<span :class="$style.dateUnit">月</span></span>
        <span>{{ queryDate.day }}<span :class="$style.dateUnit">日</span></span>
      </div>
    </div>
    <Spacer :y="5"/>
    <ul :class="$style.infoList">
      <!-- <CoronaInfo /> -->
      <TemperatureInfo :temperature="tempAfter6" />
      <TrashInfo />
      <WeatherInfo :weather="weatherAfter6" />
      <Spacer :x="0" :sm-x="2.5" />
    </ul>
    <Spacer :y="4" :sm-y="0" />

    <calender
      :class="$style.calender"
      :isLoading="flag.loadCalender"
      :hiddenFuture="true"
      :disableDates="notExistsArticleDate"
      @sendDate="receiveDate"
      @sendYearMonth="receiveYearMonth"
    />

    <Spacer :y="5"
      v-if="helper.env.name === 'production'"
      :class="$style.adsense" />

    <Adsense
      v-if="helper.env.name === 'production'"
      :class="$style.adsense"
      data-ad-client="ca-pub-4866689451938023"
      data-ad-slot="5625128473"
      data-full-width-responsive="true" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import cf from '@/mixins/commonFunctions.js';
import Spacer from '@/components/Spacer';
import Calender from '@/components/Calender';
// import CoronaInfo from './InfoItem/CoronaInfo';
import WeatherInfo from './InfoItem/WeatherInfo';
import TemperatureInfo from './InfoItem/TemperatureInfo';
import TrashInfo from './InfoItem/TrashInfo';

export default {
  name: 'InfoArea',
  mixins: [cf],
  components: {
    Spacer,
    Calender,
    // CoronaInfo,
    WeatherInfo,
    TemperatureInfo,
    TrashInfo,
  },
  data() {
    return {
      weatherAfter6: null,
      tempAfter6: null,
      notExistsArticleDate: [],
      yearMonth: null,
      flag: {
        loadCalender: true,
      },
    };
  },
  created() {
    this.getWeather();
  },
  computed: {
    ...mapState(['user', 'helper']),
    today() {
      const today = new Date();
      const object = {
        year: this.formatTimestamp(today, 'YYYY'),
        month: this.formatTimestamp(today, 'MM'),
        day: this.formatTimestamp(today, 'DD'),
      };
      return object;
    },
    queryDate() {
      const query = this.$route.query;
      if (!query.date) {
        return this.today;
      }
      const object = {
        year: this.formatTimestamp(query.date, 'YYYY'),
        month: this.formatTimestamp(query.date, 'MM'),
        day: this.formatTimestamp(query.date, 'DD'),
      };
      return object;
    },
    yearMonthObj() {
      if (!this.yearMonth) return null;
      const yearMonthData = moment(`${this.yearMonth}-01`).format('YYYY-MM-DD');
      const object = {
        year: this.formatTimestamp(yearMonthData, 'YYYY'),
        month: this.formatTimestamp(yearMonthData, 'MM'),
      };
      return object;
    },
  },
  watch: {
    yearMonth(to, old) {
      if (to !== old) {
        this.getExistsArticle();
      }
    },
  },
  methods: {
    getWeather() {
      // 閲覧時間に合わせた天気・気温コードをdataに格納
      const now = new Date();
      const time = this.formatTimestamp(now, 'HH');
      let targetTime = Number(time) + 6;
      let targetDate = `${this.today.year}-${this.today.month}-${this.today.day}`;
      // 18時以降の閲覧は対象日を翌日にする
      if (time > 17) {
        targetDate = moment(targetDate).add(1, 'd').format('YYYY-MM-DD');
        targetTime -= 24;
      }

      const params = { date: targetDate };
      this.axios({
        method: 'GET',
        url: '/v1/weather/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          if (!res.detail) return;

          const wcode = JSON.parse(res.detail.weatherCode);
          const temp = JSON.parse(res.detail.temperature);
          this.weatherAfter6 = wcode[targetTime];
          this.tempAfter6 = temp[targetTime];
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    getExistsArticle() {
      if (!this.yearMonthObj) return;
      const params = {
        year: this.yearMonthObj.year,
        month: this.yearMonthObj.month,
      };
      this.axios({
        method: 'GET',
        url: '/v1/article/get/existsInMonth',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.notExistsArticleDate = res.notExists;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loadCalender = false;
        });
    },

    receiveDate(args) {
      if (args.date !== 'Invalid date') {
        this.$router.replace({ query: { date: args.date } });
        this.$scrollTo('#header');
      }
    },

    receiveYearMonth(args) {
      if (args.date !== 'Invalid date') {
        this.yearMonth = args.yearMonth;
        this.flag.loadCalender = true;
      }
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: block;
}

.today {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}

.year {
  font-size: 24px;
  font-weight: 700;
}

.date {
  display: block;
  font-size: 56px;
  font-weight: 700;
}

.dateUnit {
  font-size: 32px;
}

.infoList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--s);
}

@include sm-view {
  .container {
    display: flex;
    flex-direction: column-reverse;
  }

  .today {
    margin: 0;
  }

  .year {
    font-size: 20px;
    line-height: 1;
  }

  .infoList {
    flex-wrap: nowrap;
    overflow: scroll;
    margin: 0 calc(50% - 50vw);
    max-width: 100vw;
    width: 100vw;
    padding-left: calc(var(--s)*3);
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display:none;
    }
  }

  .calender {
    display: none;
  }

  .adsense {
    display: none;
  }
}
</style>
