<template>
  <h2 :class="$style.title"><slot /></h2>
</template>

<script>
export default {
  name: 'ListTitle',
};
</script>

<style module lang="scss">
.title {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 900;
}
</style>
