import { createStore } from 'vuex';
import helper from './modules/helper';
import page from './modules/page';
import user from './modules/user';
import modal from './modules/modal';
import view from './modules/view';
import category from './modules/category';
import article from './modules/article';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    helper,
    page,
    user,
    modal,
    view,
    category,
    article,
  },
});
