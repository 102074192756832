
<template>
  <div>
    <spacer :y="4"/>
    <h2 :class="$style.title">ログイン</h2>
    <spacer :y="5"/>
    <form
      v-on:submit="submit"
      v-bind:class="user.login.vibration ? $style.vibration : ''"
      autocomplete="off">
      <div :class="$style.form">
        <div :class="$style.input">
          <input
            id="mail"
            type="email"
            name="mail"
            placeholder="メールアドレス"
            v-model.trim="$v.mail.$model"
            v-bind:class="{ input_error: validationFlags.mail }"
            v-on:input="invalidsCheck('mail', $v.mail.$invalid)"
            >
          <span :class="$style.slideIn"/>
        </div>
        <p
          :class="$style.comment"
          v-if="$v.mail.$dirty
            && $v.mail.required.$invalid">メールアドレスを入力してください</p>
        <p
          :class="$style.comment"
          v-if="$v.mail.$dirty
            && $v.mail.email.$invalid">正しいメールアドレスの形式で入力してください</p>
        <spacer :y="1.5"/>
        <div :class="$style.input">
          <input
            id="password"
            type="password"
            name="password"
            placeholder="パスワード"
            v-model.trim="$v.password.$model"
            v-bind:class="{ input_error: validationFlags.password }"
            v-on:input="invalidsCheck('password', $v.password.$invalid)"
            >
          <span :class="$style.slideIn" />
        </div>
        <p
          :class="$style.comment"
          v-if="$v.password.$dirty
            && $v.password.required.$invalid">パスワードを入力してください</p>
        <spacer
          v-if="user.login.isBanned || user.login.isFaild >= 1"
          :y="3"/>
        <p
          :class="$style.message"
          v-if="user.login.isBanned">パスワードを5回間違えたため、{{ user.login.unbannedTime }}までログインが制限されています。<br>制限を解除したい場合は運営まで連絡してください。</p>
        <p
          :class="$style.comment"
          v-if="user.login.isFaild === 1 && !user.login.isBanned">入力されたメールアドレスは登録されていません</p>
        <p
          :class="$style.comment"
          v-if="user.login.isFaild === 2 && !user.login.isBanned">入力されたパスワードが間違っています</p>
        <spacer :y="6"/>
        <div :class="$style.wrap">
          <button
            type="submit"
            :class="$style.btn"
            v-bind:disabled="!submitFlag">ログイン</button>
        </div>
        <spacer :y="3"/>
        <div :class="$style.wrap" style="flex-direction: column">
          <div :class="$style.forgot">
            <router-link
              to="/forgot-password/">パスワードをお忘れですか？</router-link>
          </div>
          <spacer :y="6"/>
          <div :class="$style.wrap">
            <div :class="$style.form">
              <router-link :class="$style.lgnlink" to="/signup/">新規会員登録</router-link>
            </div>
            <spacer :x="3"/>
            <div :class="$style.form">
              <router-link :class="$style.lgnlink" to="/subscriptions/">有料プランについて</router-link>
            </div>
          </div>
        </div>
      </div>
    </form>
    <spacer :y="15"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import cf from '@/mixins/commonFunctions';
import Spacer from '@/components/Spacer.vue';


export default {
  name: 'Login',
  mixins: [cf],
  components: {
    Spacer,
  },
  data() {
    return {
      validationFlags: {
        mail: false,
        password: false,
      },
      invalids: {
        mail: true,
        password: true,
      },
      // 有効期限切れでリダイレクトされた
      isExpired: false,
    };
  },
  created() {
    this.axios({
      method: 'GET',
      url: '/v1/connection/test',
    })
      .then((response) => {
        console.log(response.data.status);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });

    if (this.$route.query
      && this.$route.query.expired) this.isExpired = true;
  },
  setup() {
    const mail = ref('');
    const password = ref('');

    const rules = {
      // メールは必須・email形式
      mail: { required, email },
      // パスワードは必須
      password: { required },
    };

    const $v = useVuelidate(rules, { mail, password });

    return { mail, password, $v };
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.mail && !this.invalids.password;
    },
  },
  methods: {

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      // validateチェックは入力時に行われてる
      const data = {
        email: this.mail,
        password: this.password,
      };

      this.$store.dispatch('user/login/submit', data);
      this.$store.dispatch('user/update');
    },
  },
};
</script>


<style lang="scss" module>
.wrap {
  display: flex;
  justify-content: center;
}
.logo {
  margin: 0 auto;
  width: 50%;
  max-width: 300px;
}
.title {
  text-align: center;
  color: var(--orange-main);
}
.form {
  text-align: center;
}
.input {
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
  position: relative;
  input {
    width: 100%;
    padding: 13px;
    border: none;
    background-color: var(--gray-sub);
    outline: none;
    border-bottom: 1px solid var(--gray);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    &:focus + .slideIn {
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(#e2e2e2, .4);
      mix-blend-mode: multiply;
      position: absolute;
      animation: split .3s;
    }

    @keyframes split {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }
}
.comment {
  font-size: 12px;
  color: var(--pink);
}
.message {
  font-size: 12px;
  text-align: center;
}
.btn {
  border: none;
  background-color: #666;
  color: #fff;
}
.lgnlink {
  border: 1px solid rgb(26, 34, 61);
  color: rgb(26, 34, 61);
  background-color: #fff;
  text-decoration: none;
  &:hover {
    color: inherit
  }
}

.btn,.lgnlink {
  display: inline-block;
  border-radius: 22px;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
  font-weight: bold;
}

.forgot {
  font-size: 14px;
  text-decoration: underline;
  a {
    color: var(--orange-main);
  }
}
// ログイン失敗時
.vibration {
  animation: vibration .1s  infinite;
}
@keyframes vibration {
  0% {transform: translate(0px, 0px) rotateZ(0deg)}
  25% {transform: translate(2px, 2px) rotateZ(1deg)}
  50% {transform: translate(0px, 2px) rotateZ(0deg)}
  75% {transform: translate(2px, 0px) rotateZ(-1deg)}
  100% {transform: translate(0px, 0px) rotateZ(0deg)}
}
</style>
