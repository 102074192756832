<template>
  <div :class="$style.wrap">
    <div :class="$style.bg" v-on:wheel="wheelZoom" @click.self="closeViewer">
      <ul>
        <li
          v-for="(img, i) in images"
          :key="img" @click.self="closeViewer">
          <div
            v-if="showIdx === i"
            :class="[$style.image_wrap, isPaper ? $style.isPaper : '']"
            :style="`transform: scale(${zoomRatio}) translate(${position.x}px, ${position.y}px);`">
            <img :src="img.url" alt="" id="paper">
            <div
              :class="[
                $style.image,
                flag.drag ? $style.active : ''
              ]"
              v-on:mousedown="changeDragFlag($event, true)"
              v-on:mouseup="changeDragFlag($event, false)"
              v-on:mouseleave="changeDragFlag($event, false)"
              v-on:mousemove="flag.drag ? mouseMove($event) : ''"
              v-on:touchstart="touchStart"
              v-on:touchend="touchMoved"
              v-on:touchleave="changeDragFlag($event, false)"
              v-on:touchmove="touchMoved"></div>
          </div>
        </li>
      </ul>

      <!-- 閉じる -->
      <div
        :class="[$style.angle, $style.top_right]"
        v-on:click="closeViewer"><i class="fa-regular fa-xmark"></i></div>
      <!-- ページ移動 -->
      <div
        :class="[$style.angle, $style.left]"
        v-if="showArrow.left"
        v-on:click="changePage($event, changePageType.left)"><i class="fa-regular fa-angle-left"></i></div>
      <div
        :class="[$style.angle, $style.right]"
        v-if="showArrow.right"
        v-on:click="changePage($event, changePageType.right)"><i class="fa-regular fa-angle-right"></i></div>

      <!-- キャプション -->
      <div :class="$style.caption" v-if="images[showIdx].caption">
        {{ images[showIdx].caption }}
      </div>

      <!-- ツールバー -->
      <ul :class="$style.toolbar">
        <li v-on:click="zoom($event, 'zoomin')">
          <i class="fa-regular fa-magnifying-glass-plus"></i>
        </li>
        <li v-on:click="zoom($event, 'zoomout')">
          <i class="fa-regular fa-magnifying-glass-minus"></i>
        </li>
        <li v-on:click="reset">
          <i class="fa-regular fa-rotate-right"></i>
        </li>
      </ul>

      <!-- 下部リスト -->
      <div :class="$style.list_wrap" v-on:wheel="listWheel" id="wrapUp">
        <ul :class="listsClass" id="lists">
          <li
            v-for="(img, i) in images"
            :key="img"
            :class="$style.listItem"
            v-on:click="changePage($event, 'jump', i)">
            <div
              :class="$style.page"
              v-if="img.page && showPage">{{ img.page }}面</div>
            <div :class="$style.imgBlocker">
              <img
                :src="img.url"
                alt=""
                v-on:load="imagesLoaded"
                :class="[showIdx !== i ? $style.list_bg : '']"
                oncontextmenu="return false;">
            </div>
          </li>
        </ul>
        <div
          :class="$style.left"
          v-on:click="scroll('left')"
          v-if="flag.showArrow"><i class="fa-solid fa-angle-left"/></div>
        <div
          :class="$style.right"
          v-on:click="scroll('right')"
          v-if="flag.showArrow"><i class="fa-solid fa-angle-right"/></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // images
    //  * url：画像パス（必須）
    //  * link：該当画像が使われているページに飛ぶボタンのリンク先
    //  * page：紙面のpage
    images: {
      type: Array,
      required: true,
    },
    // showIdx
    //  * 表示する画像のidx
    showIdx: {
      type: Number,
      required: true,
    },
    showLink: {
      type: Boolean,
      default: false,
    },
    showPage: {
      type: Boolean,
      default: true,
    },
    listReverse: {
      type: Boolean,
      default: true,
    },
    isPaper: {
      type: Boolean,
      default: false,
    },
    sitePageType: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      flag: {
        drag: false,
        showArrow: false,
      },
      imgStat: 0,
      zoomRatio: 1,
      zoomFlag: false,
      position: {
        x: 0,
        y: 0,
      },
      fingers: {},
      innerHeight: 0,
      innerWidth: 0,
      imagesTest: [],
    };
  },
  created() {
    this.innerHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
  },
  mounted() {
    if (this.sitePageType === 'page') this.setURLQuery(this.showIdx);
    document.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('resize', this.handleResize);
    document.body.style.overflow = 'hidden';
    if (this.imgStat === this.images.length) this.checkTabWidth();
  },
  unmounted() {
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('resize', this.handleResize);
    if (this.sitePageType === 'page') this.setURLQuery(this.showIdx, false);
    this.imgStat = 0;
  },
  computed: {
    changePageType() {
      const direction = {
        left: this.isPaper ? 'next' : 'back',
        right: this.isPaper ? 'back' : 'next',
      };
      return direction;
    },
    showArrow() {
      const defaultL = this.showIdx !== 0;
      const defaultR = this.showIdx !== this.images.length - 1;
      const arrowMode = {
        left: this.listReverse ? defaultR : defaultL,
        right: this.listReverse ? defaultL : defaultR,
      };
      return arrowMode;
    },
    listsClass() {
      let className = this.$style.list;
      if (this.flag.showArrow) className += ` ${this.$style.arrow}`;
      if (this.listReverse) className += ` ${this.$style.reverse}`;
      return className;
    },
  },
  methods: {
    setURLQuery(imageIdx, isViewerOpening = true) {
      if (isViewerOpening) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            page: imageIdx + 1,
          },
        });
      } else {
        this.$router.replace({
          path: this.$route.path,
          query: null,
        });
      }
    },
    handleResize() {
      this.innerHeight = window.innerHeight;
      this.innerWidth = window.innerWidth;
      if (this.imgStat === this.images.length) this.checkTabWidth();
    },
    reset() {
      this.zoomRatio = 1;
      this.position = { x: 0, y: 0 };
    },

    closeViewer() {
      this.$emit('changePage', null);
      document.body.style.overflow = 'scroll';
    },

    changeDragFlag(e, flag) {
      e.preventDefault();
      this.flag.drag = flag;
    },

    changePage(e, type, index) {
      e.preventDefault();
      let idx = this.showIdx;
      if (type === 'next') idx = this.showIdx + 1;
      else if (type === 'back') idx = this.showIdx - 1;
      else if (type === 'jump') idx = index;
      this.$emit('changePage', idx);
      if (this.sitePageType === 'page') this.setURLQuery(idx);
      this.reset();
    },

    // キーボード操作
    onKeyDown(e) {
      e.preventDefault();
      if ((e.key === 'j' || e.key === 'ArrowRight') && this.showIdx < this.images.length - 1) this.changePage(e, 'next');
      if ((e.key === 'k' || e.key === 'ArrowLeft') && this.showIdx > 0) this.changePage(e, 'back');
      if (e.key === 'i' || e.key === '+') this.zoom('zoomin');
      if (e.key === 'o' || e.key === '-') this.zoom('zoomout');
    },

    /** マウス操作（PC） */
    mouseMove(e) {
      e.preventDefault();
      // それ以上moveできないようにするマージン
      const paperMargin = 100;
      const elm = document.getElementById('paper');
      const nowX = elm.getBoundingClientRect().x;
      const nowY = elm.getBoundingClientRect().y;
      const eh = elm.height * this.zoomRatio;
      const ew = elm.width * this.zoomRatio;
      const topLimit = -(eh - paperMargin);
      const bottomLimit = (this.innerHeight - 50 - 160 - paperMargin);
      const rightLimit = this.innerWidth - 100;
      const leftLimit = -(ew - 100);

      // TODO: スクロールを高速にすると演算が間に合わず、想定より下に下がることがある
      if ((nowY < topLimit && e.movementY < 0) || (nowY > bottomLimit && e.movementY > 0)) {
        return;
      }
      if ((nowX < leftLimit && e.movementX < 0) || (nowX > rightLimit && e.movementX > 0)) {
        return;
      }

      this.position.x += e.movementX / this.zoomRatio;
      this.position.y += e.movementY / this.zoomRatio;
    },

    zoom(e, type) {
      e.preventDefault();
      // ズームイン
      if (type === 'zoomin') {
        if (this.zoomRatio < 10 && this.zoomRatio > 9) this.zoomRatio = 10;
        else if (this.zoomRatio < 9) this.zoomRatio += 0.1;
      // ズームアウト
      } else if (type === 'zoomout') {
        if (this.zoomRatio > 0.1 && this.zoomRatio < 0.2) this.zoomRatio = 0.1;
        else if (this.zoomRatio > 0.2) this.zoomRatio -= 0.1;
        console.log(this.zoomRatio);
      }
    },

    wheelZoom(e) {
      e.preventDefault();
      let scale = this.zoomRatio;
      const deltaY = Math.min(Math.max(-10, e.deltaY), 10);
      if (!Number.isInteger(e.deltaX * 100) || !Number.isInteger(e.deltaY * 100)) {
        scale += deltaY * -0.01;
        scale = Math.min(Math.max(0.125, scale), 4);
        this.zoomRatio = scale;
      }
    },

    listWheel(e) {
      e.stopPropagation();
    },

    /** タッチ操作（SP）
     * ・スワイプ：移動
     * ・ピンチイン：ズームイン
     * ・ピンチアウト：ズームアウト
     */
    touchStart(e) {
      e.preventDefault();
      this.fingers = e.targetTouches;
    },
    touchMoved(e) {
      e.preventDefault();
      const type = e.type === 'touchend' ? 'end' : 'move';

      // 一本指操作：移動
      if (!e.targetTouches[1]) {
        if (!this.zoomFlag) {
          const targetPosition = {
            x: e.changedTouches[0].clientX,
            y: e.changedTouches[0].clientY,
          };

          // それ以上moveできないようにするマージン
          const paperMargin = 100;
          const elm = document.getElementById('paper');
          const nowX = elm.getBoundingClientRect().x;
          const nowY = elm.getBoundingClientRect().y;
          const eh = elm.height * this.zoomRatio;
          const ew = elm.width * this.zoomRatio;
          const topLimit = -(eh - paperMargin);
          const bottomLimit = (this.innerHeight - 50 - 160 - paperMargin);
          const rightLimit = this.innerWidth - 100;
          const leftLimit = -(ew - 100);

          const movementX = targetPosition.x - this.fingers[0].clientX;
          const movementY = targetPosition.y - this.fingers[0].clientY;

          // TODO: スクロールを高速にすると演算が間に合わず、想定より下に下がることがある
          if ((nowY < topLimit && movementY < 0) || (nowY > bottomLimit && movementY > 0)) {
            return;
          }
          if ((nowX < leftLimit && movementX < 0) || (nowX > rightLimit && movementX > 0)) {
            return;
          }

          this.position.x += movementX / this.zoomRatio;
          this.position.y += movementY / this.zoomRatio;
          if (type === 'move') {
            this.fingers = e.changedTouches;
          }
        } else {
          // ズーム後初めての移動はかくかくする可能性が高いので無視する
          // 1イベントだけ無視できればよい
          this.zoomFlag = false;
        }

      // 二本指操作：ピンチイン・アウト（ズーム）
      } else if (e.targetTouches[1] && !e.targetTouches[2]) {
        const identifiers = [];
        Object.keys(this.fingers).forEach((f) => {
          identifiers.push(f);
        });
        // ピンチ前の距離算出
        const distX = this.fingers[0].clientX - this.fingers[1].clientX;
        const distY = this.fingers[0].clientY - this.fingers[1].clientY;
        const startDist = Math.sqrt(distX ** 2 + distY ** 2);

        const movedDistX = e.targetTouches[0].clientX - e.targetTouches[1].clientX;
        const movedDistY = e.targetTouches[0].clientY - e.targetTouches[1].clientY;
        const endDist = Math.sqrt(movedDistX ** 2 + movedDistY ** 2);
        const percentage = Math.round((endDist * 100) / startDist);
        const ratio = percentage / 100;
        this.zoomRatio *= ratio;

        this.fingers = e.targetTouches;
        this.zoomFlag = true;
      }

      if (type === 'end') {
        this.fingers = e.targetTouches;
      }
    },
    imagesLoaded() {
      this.imgStat += 1;
      if (this.imgStat === this.images.length) this.checkTabWidth();
    },
    checkTabWidth() {
      // タブが全て表示される場合には矢印非表示
      this.flag.showArrow = false;
      const wrapUp = document.getElementById('wrapUp');
      const lists = document.getElementById('lists');
      if (wrapUp.clientWidth < lists.scrollWidth) this.flag.showArrow = true;
    },
    scroll(direction) {
      const lists = document.getElementById('lists');
      const viewW = lists.clientWidth;
      const X = direction === 'right' ? viewW : viewW * -1;
      lists.scrollBy({ left: X });
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  position: relative;
  z-index: 500;
}

.bg {
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(black, 0.3);
  width: 100%;
  height: 100%;
}

.image_wrap {
  width: 800px;
  max-width: 100%;
  margin: 50px auto;
  cursor: grab;
  -webkit-touch-callout:none;
  
  &.isPaper {
    width: 1220px;
  }
  img {
    width: 100%;
    -moz-user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
  }
  .image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    &.active {
      cursor: grabbing;
    }
  }
}

.angle {
  position: absolute;
  top: 50%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  background-color: rgba(black, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  &.left {
    left: 0;
    margin-left: 20px;
  }
  &.right {
    right: 0;
    margin-right: 20px;
  }
  &.top_right {
    top: 0;
    right: 0;
    margin-top: 20px;
    margin-right: 20px;
  }
}

$listHeight: 100px;
$listMgn: 10px;

.caption {
  position: absolute;
  text-align: center;
  white-space: pre-wrap;
  bottom: $listHeight + 65px;
  left: 50%;
  transform: translate(-50%);
  background-color: rgba(white, 0.5);
  padding: 2px 8px;
  display: block;
}

.toolbar {
  position: absolute;
  bottom: $listHeight - 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translate(-50%, -50%);

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 20px;
    border-radius: 50%;
    background-color: rgba(black, 0.5);
    color: white;
    cursor: pointer;
    &:not(:first-child) {
      margin-left: 5px;
    }
  }
}

.list {
  height: $listHeight;
  display: flex;
  direction: ltr;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  justify-content: flex-start;
  scroll-behavior: smooth;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-corner,
  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    display: none;
  } 
   

  li {
    margin: 0 5px;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    list-style: none;
  }
  img {
    max-height: $listHeight - $listMgn;
    max-width: $listHeight - $listMgn;
    -moz-user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
  }
  .page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    background-color: rgba(white, 0.5);
  }
  &.arrow {
    margin: 0 30px;
  }
  &.reverse {
    flex-direction: row-reverse;
  }
}
.list_bg {
  filter: brightness(75%);
}
.list_wrap {
  background-color: rgba(black, 0.5);
  height: $listHeight;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 510;
}
.right, .left {
  position: absolute;
  top: 50%;
  transform: translate(0, calc(-50% + 4px));
  font-size: 28px;
  color: white;
  cursor: pointer;
  &:hover {
    opacity: .4;
  }
  transition: all .3s;
  padding: 0 6px;
}
.right {
  right: 0;
}
.left {
  left: 0;
}
.imgBlocker {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
}
</style>
