<template>
  <ol :class="$style.container">
    <slot />
  </ol>
</template>

<script>
export default {
  name: 'ArticleSimpleList',
};
</script>

<style lang="scss" module>
.container {
  gap: var(--a-g-x) var(--a-g-y);
  overflow: hidden;
}
</style>
