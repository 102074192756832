
<template>
  <div :class="$style.container">
    <div :class="$style.header">
      <div :class="[$style.title, $style.pointer]" @click="goPrevMonth"><i class="fa-solid fa-chevron-left"></i></div>
      <Spacer :y="1.5" />
      <p :class="$style.title">{{ formatTimestamp(yearMonth, 'YYYY年 MM月') }}</p>
      <Spacer :y="1.5" />
      <div :class="[$style.title, $style.pointer, notGoFutureMonth ? $style.disable : '' ]" @click="goNextMonth"><i class="fa-solid fa-chevron-right"></i></div>
    </div>
    <Spacer :y="1" />
    <Loading v-if="isLoading" />
    <div :class="$style.calender_container" v-else>
      <ul :class="$style.calender">
        <li v-for="day in dayLabel" :key="day">{{ day }}</li>
        <li
          v-for="date in daysOfMonth"
          :key="date"
          :class="[
            $style.pointer,
            $style.box,
            $style.date,
            date === 1 ? $style.firstDate : '',
            date === selectedCalenderDate && selectedCalenderYearMonth === yearMonth ? $style.selected : '',
            disableDates.includes(date) && !(date === selectedCalenderDate && selectedCalenderYearMonth === yearMonth) ? $style.disable : '',
          ]"
          @click="selectDate(date)">
          {{ date }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import cf from '@/mixins/commonFunctions.js';
import Spacer from '@/components/Spacer';
import Loading from '@/components/Loading.vue';

export default {
  name: 'calender',
  mixins: [cf],
  components: {
    Spacer,
    Loading,
  },
  props: {
    initDate: {
      type: String,
      required: false,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hiddenFuture: {
      type: Boolean,
      default: false,
    },
    disableDates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      addMonth: 0,
      selectedCalenderDate: null,
      selectedCalenderYearMonth: null,
      dayLabel: ['日', '月', '火', '水', '木', '金', '土'],
    };
  },
  created() {
    const initData = this.$route.query.date || this.initDate;
    const baseObject = initData || new Date();
    const todayObject = moment(baseObject);
    this.selectedCalenderDate = todayObject.date();
    this.selectedCalenderYearMonth = todayObject.format('YYYY-MM');
    this.sendYearMonth();
  },
  computed: {
    currentMoment() {
      return moment(new Date()).add(this.addMonth, 'months');
    },
    yearMonth() {
      return this.currentMoment.format('YYYY-MM');
    },
    firstDayDate() {
      return this.currentMoment.startOf('month').day() + 1;
    },
    daysOfMonth() {
      return this.currentMoment.endOf('month').date();
    },
    notGoFutureMonth() {
      const next = this.currentMoment.clone().add('months', 1);
      const isFutureMonth = moment(next).isAfter(new Date());
      return isFutureMonth && this.hiddenFuture;
    },
  },
  methods: {
    goNextMonth() {
      if (!this.notGoFutureMonth) {
        this.addMonth += 1;
        this.sendYearMonth();
      }
    },
    goPrevMonth() {
      this.addMonth -= 1;
      this.sendYearMonth();
    },
    selectDate(date) {
      const sendDateData = moment(this.yearMonth).add(date - 1, 'd').format('YYYY-MM-DD');
      const onlyDate = moment(sendDateData).format('D');
      if (this.disableDates.includes(Number(onlyDate))) return;

      this.$emit('sendDate', {
        date: sendDateData,
      });
      this.selectedCalenderYearMonth = this.yearMonth;
      this.selectedCalenderDate = date;
    },
    sendYearMonth() {
      this.$emit('sendYearMonth', {
        yearMonth: this.yearMonth,
      });
    },
  },
};
</script>
<style lang="scss" module>
.container {
  display: block;
}

.pointer {
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: center;
  .title {
    font-size: 18px;
    font-weight: bold;
  }
}

.calender_container {
  border: 1px solid #e3e4e6;
  .day {
    width: 100%;
    table-layout: fixed;
  }
  padding-bottom: 1px;
  padding-left: 1px;
}

.calender {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-collapse: collapse;
  li {
    text-align: center;
  }
  .firstDate {
    grid-column-start: v-bind(firstDayDate);
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.box {
  box-sizing: border-box;
  margin: 0 0px -1px -1px;
  padding: 2px 0px;
  z-index: 1;

  &:hover {
    opacity: .4;
  }
}

.date {
  font-size: 16px;
}

.selected {
  background-color: #e3e4e6;
}

.disable {
  opacity: .4;
}


@include sm-view {
  .container {
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>
