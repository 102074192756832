<template>
  <div :class="$style.container">
    <header :class="$style.header" id="header">
      <div :class="$style.inner">
        <component :is="logoTag" :class="$style.logo" @click="setTopGetFlag">
          <router-link to="/" :class="$style.logo__link">
            <picture :class="$style.logo__img">
              <source
                srcset="@/assets/images/logo-mark.png"
                alt="桐生タイムス"
                media="(max-width: 767px)"
              />
              <img src="@/assets/images/logo-full.png" alt="桐生タイムス" />
            </picture>
          </router-link>
        </component>
        <div :class="$style.menu" v-if="isMdView">
          <div :class="$style.searchForm">
            <SearchParts/>
          </div>
          <Spacer :x="2"/>
          <AccountLinks v-if="isLgView" />
          <button
            v-if="!isLgView"
            @click="openMenu"
            :class="[$style.trigger, $style.menu]"
          />
        </div>
        <div
          v-if="!isMdView"
          :class="$style.triggers"
        >
          <button
            @click="toggleSearch"
            :class="[$style.trigger, $style.search]"
          />
          <Spacer :x="2"/>
          <button
            @click="openMenu"
            :class="[$style.trigger, $style.menu]"
          />
        </div>
      </div>
      <GlobalNav v-if="showNav" />
    </header>
    <div v-if="!isMdView" :class="searchAccordionClass">
      <SearchParts />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Spacer from '@/components/Spacer';
import GlobalNav from './GlobalNav';
import SearchParts from './SearchParts';
import AccountLinks from '../AccountLinks';

export default {
  name: 'GlobalHeader',
  components: {
    GlobalNav,
    SearchParts,
    AccountLinks,
    Spacer,
  },
  computed: {
    ...mapState(['user', 'view']),
    isLgView() {
      return ['lg'].includes(this.view.display_size);
    },
    isMdView() {
      return ['lg', 'md'].includes(this.view.display_size);
    },
    searchAccordionClass() {
      return `${this.$style.searchAccordion} ${this.isOpenSearch ? this.$style.isOpen : ''}`;
    },
    showNav() {
      return this.isLgView && !(this.hideNav.some((name) => this.$route.path.includes(name)));
    },
  },
  data() {
    return {
      // 記事ページとかでは記事タイトルをh1にする
      logoTag: 'h1',
      isOpenSearch: false,
      hideNav: [ // nav非表示ページ（配下含む）
        'account',
        'login',
        'edit',
        'signup',
        'forgot-password',
        'search',
      ],
    };
  },
  created() {
    if (this.user.email) {
      this.initialize();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.initialize();
        }
      });
    }
  },
  methods: {
    ...mapActions('view', ['openMenu']),
    initialize() {
      this.$store.dispatch('user/login/check');
    },
    toggleSearch() {
      this.isOpenSearch = !this.isOpenSearch;
    },
    setTopGetFlag() {
      this.$store.dispatch('article/setTopGetFlag', true);
    },
  },
  watch: {
    $route() {
      this.isOpenSearch = false;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  width: 100%;
  padding: 0 var(--g);
}

.header {
  position: relative;
  padding: calc(var(--s) * 4) 0;
  border-bottom: 1px solid rgba(24, 24, 24, 0.12);
}

.inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  position: relative;
  display: block;
  flex-grow: 0;
}

.logo__link {
  display: block;
}

.logo__img {
  width: 324px;
}

.menu {
  display: flex;
}

.searchForm {
  width: 240px;
}
.triggers {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.trigger {
  flex-shrink: 0;
  flex-grow: 0;
  display: block;
  height: 36px;
  width: 36px;
  background-size: 24px;
  appearance: none;
  border: none;
  box-shadow: none;

  &.search {
    background: transparent url("~@/assets/images/icon/search.svg") center no-repeat;
  }

  &.menu {
    background: transparent url("~@/assets/images/icon/menu.svg") center no-repeat;
  }
}

.searchAccordion {
  overflow: hidden;
  height: 0;
  opacity: 0;
  padding: 0 var(--g);
  background-color: #eee;
  margin: 0 calc(50% - 50vw);
  max-width: 100vw;
  width: 100vw;
}

@include md-view {
  .logo__img {
    width: 224px;
  }
}

@include sm-view {
  .container {
    border-bottom: 1px solid rgba(24, 24, 24, 0.12);
  }

  .header {
    padding: calc(var(--s) * 2) 0;
    border-bottom: none;
  }

  .logo__img {
    width: 64px;
  }

  .searchAccordion {
    transition: all 400ms ease-in-out;
    transform: translate3d(0, -5px, 0);

    &.isOpen {
      opacity: 1;
      padding: var(--s) var(--g);
      height: 52px;
      transform: translate3d(0, 0, 0);
    }
  }
}
</style>
