<template>
  <nav :class="$style.container">
    <ul :class="$style.list">
      <li
        v-for="category in categories"
        :key="category.id"
        :class="$style.category"
        @mouseover="category.onHover = true"
        @mouseleave="category.onHover = false"
      >
        <router-link
          :to="`/category/${category.name}/`"
          :class="[
            $style.link,
            isActive === category.name ? $style.isActive : '',
          ]"
        >
          <div :class="[category.children?.length ? $style.hasChild : '']">
            {{category.label}}
            <i
              v-if="category.children?.length"
              :class="$style.arrow"
              class="fa-solid fa-chevron-down" />
          </div>
        </router-link>
        <div
          :class="$style.children_wrap"
          v-if="category.children?.length && category.onHover"
        >
          <div :class="$style.children">
            <ul
              v-for="n of Math.ceil(category.children.length / childPerCol)"
              :key="n"
              :class="[
                $style.children_list,
                n === 1 ? $style.first : '',
                Math.ceil(category.children.length / childPerCol) === n ? $style.last : '',
              ]">
              <li
                v-for="m of n === Math.ceil(category.children.length / childPerCol) ? category.children.length % childPerCol : childPerCol"
                :key="m">
                <router-link
                  :class="$style.child"
                  :to="`/category/${category.children[(n - 1) * childPerCol + m - 1].name}/`">{{ category.children[(n - 1) * childPerCol + m - 1].label }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li :class="$style.category">
        <router-link
          :to="'/paper/latest/'"
          :class="$style.paperlink"
        >紙面版で見る</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'GlobalNav',
  mixins: [cf],
  data() {
    return {
      childPerCol: 7, // １列に子カテゴリをいくつ並べるか（数が多い場合に折り返す）
    };
  },
  computed: {
    ...mapState('category', ['categories']),
    yesterday() {
      return this.formatTimestamp(moment(new Date()).add(-1, 'd'), 'YYYYMMDD');
    },
    isActive() {
      let name = '';
      if (this.categories.length) {
        this.categories.forEach((row) => {
          if (this.$route.path.includes(row.name)) name = row.name;
          else if (row.children?.length) {
            row.children.forEach((r) => {
              if (this.$route.path.includes((r.name))) name = row.name;
            });
          }
        });
      }
      return name;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  width: 100%;
}

.list {
  display: flex;
  margin-top: 40px;
}

.category {
  & + & {
    margin-left: 32px;
  }
  position: relative;
}

.children {
  &_wrap {
    padding-top: 24px;
    position: absolute;
    display: flex;
    z-index: 1;
    left: 0;
    top: 100%;
    transform: translate(-24px);
  }
  &_list {
    display: block;
    background-color: white;
    white-space: nowrap;
    color: #1A223D;
    margin: 0;
    padding: 16px 40px 16px 0px;
    position: relative;
    border-radius: 4px;
    &.first {
      padding-left: 24px;
    }
    &.last {
      padding-right: 48px;
    }
  }
  box-shadow: 4px 3px 20px rgba(11, 30, 96, 0.16);
  display: flex;

  .child {
    display: block;
    padding: 8px 0;
    cursor: pointer;
    color: #1A223D;

    &:hover {
      color: rgba(26, 34, 61, 0.72);
    }
  }
}

.hasChild {
  display: flex;
  align-items: center;
  .arrow {
    font-size: 10px;
    margin-left: 4px;
    margin-top: 2px;
  }
}

.link {
  display: block;
  color: rgba(26, 34, 61, 0.72);
  &:hover {
    color: #1A223D;
  }

  &.isActive {
    font-weight: bold;
    color: #1A223D;
    position: relative;
  }

}

@include sm-view {
  .container {
    position: absolute;
    left: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, 0, 0);
  }
}

.paperlink {
  font-size: 14px;
  border-radius: 4px;
  font-weight: bold;
  padding: 6px 16px;
  color: var(--black);
  border: 1px solid var(--black);
  transition: all .3s;
  &:hover {
    color: var(--black);
    opacity: .5;
  }
}
</style>
