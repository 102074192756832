<template>
  <div class="contact container-xl">
    <div class="contact_content">
      <spacer :y="3"/>
      <form
        class="form_contact form"
        autocomplete="off">
        <h2 :class="$style.title">お問い合わせ</h2>
        <spacer :y="8"/>
        <div
          :class="$style.form">
          <div :class="$style.input">
            <p :class="$style.label">お名前</p>
            <input
              id="contactName"
              type="text"
              name="contactName"
              v-model="contactData.name">
            <span class="slide-in"></span>
            <spacer :y="6"/>
            <p :class="$style.label">メールアドレス</p>
            <input
              id="mail"
              class=""
              type="email"
              name="mail"
              v-model.trim="$v.mail.$model"
              v-bind:class="{ input_error: validationFlags.mail }"
              v-on:input="invalidsCheck('mail', $v.mail.$invalid)">
            <span class="slide-in"></span>
            <p
              :class="$style.message"
              v-if="$v.mail.$dirty
                && $v.mail.email.$invalid">
                正しいメールアドレスの形式で入力してください
            </p>
            <spacer :y="6"/>
            <p :class="$style.label">電話番号</p>
            <input
              id="tel"
              name="tel"
              type="tel"
              v-model="$v.tel.$model"
              required
              v-bind:class="{ input_error: validationFlags.tel }"
              v-on:input="invalidsCheck('tel', $v.tel.$invalid)"/>
            <spacer :y="6"/>
            <p
              :class="$style.message"
              v-if="$v.tel.$dirty
            && $v.tel.$invalid">
              10~11桁の半角英数字で入力してください
            </p>
            <p :class="$style.label">お問合せ項目</p>
            <div :class="$style.select_wrap">
              <div :class="$style.select">
                <select
                  id="contactType"
                  name="contactType"
                  v-model="contactData.type"
                  :class="contactData.type === 0 ? $style.placeholder : ''">
                  <option :value="0">選択してください</option>
                  <option
                    v-for="type in contactItems"
                    :key="type.id"
                    :value="type.id">{{ type.itemName }}</option>
                </select>
              </div>
            </div>
            <spacer :y="6"/>
            <p :class="$style.label">お問い合わせ内容</p>
            <spacer :y="1.5"/>
            <textarea
              id="contactContent"
              :class="$style.textarea"
              type="textarea"
              name="contactContent"
              placeholder="お問い合わせ内容を入力してください"
              v-model="contactData.content"
              rows="10" />
            <span class="slide-in"></span>
          </div>
          <spacer :y="4"/>
          <div :class="$style.wrap">
            <button
              :class="$style.btn"
              @click="submit"
              :disabled="!submitFlag">
            送信</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required, numeric, helpers } from '@vuelidate/validators';
import cf from '@/mixins/commonFunctions';
import Spacer from '@/components/Spacer.vue';

export default {
  name: 'contact',
  mixins: [cf],
  components: {
    Spacer,
  },
  data() {
    return {
      contactData: {
        name: null,
        type: 0,
        content: null,
      },
      contactItems: null,
      validationFlags: {
        mail: false,
        tel: false,
      },
      invalids: {
        mail: true,
        tel: true,
      },
    };
  },
  created() {
    this.getContactItems();
  },
  setup() {
    const mail = ref('');
    const tel = ref('');

    const contains = helpers.regex(/^[0-9]{10,11}$/);

    const rules = {
      // メールは必須・email形式
      mail: { required, email },
      tel: { required, numeric, contains },
    };

    const $v = useVuelidate(rules, { mail, tel });

    return { mail, tel, $v };
  },
  computed: {
    ...mapState(['page', 'helper']),
    submitFlag() {
      // 無効フラグが全てfalse、全て入力済みならばtrueを返却
      const isValid = !Object.keys(this.invalids).some((inv) => this.invalids[inv]);
      const isFilled = !Object.keys(this.contactData).some((key) => !this.contactData[key]);
      return isValid && isFilled;
    },
  },
  methods: {
    initialize() {
      this.contactData = {
        name: null,
        type: 0,
        content: null,
      };
      this.mail = '';
      this.tel = '';
    },
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    getContactItems() {
      this.showLoading();

      this.axios({
        method: 'GET',
        url: '/v1/contactItem/get/list',
      })
        .then((response) => {
          const res = response.data;
          this.contactItems = res.data.data;
          this.setItem();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    setItem() {
      this.contactItems.forEach((row) => {
        if (this.$route.query && this.$route.query.item === 'policy') {
          if (row.itemName.includes('個人情報')) this.contactData.type = row.id;
        }
      });
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      this.showLoading();
      // validateチェックは入力時に行われてる
      const itemLabel = this.contactItems.find((item) => item.id === this.contactData.type).itemName;
      const data = {
        email: this.mail,
        type: itemLabel,
        name: this.contactData.name,
        tel: this.tel,
        content: this.contactData.content.replace(/\r?\n/g, '<br>'),
      };
      this.axios({
        method: 'POST',
        url: '/v1/contact/send/contact',
        data,
      })
        .then(() => {
          alert('お問い合わせを送信しました。');
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.initialize();
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  display: flex;
  justify-content: center;
}
.title { // いる
  text-align: center;
  color: var(--orange-main);
}
.form { // いる
  text-align: center;
}
.label {
  font-size: 12px;
  padding-left: 4px;
  text-align: left;
  line-height: 1;
  font-weight: bold;
}
.input {
  margin: 0 auto;
  width: 70%;
  max-width: 100%;
  position: relative;
  input {
    width: 100%;
    padding: 13px;
    border: none;
    background-color: var(--gray-sub);
    color: var(--black);
    outline: none;
    border-bottom: 1px solid var(--gray);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    &:focus + .slideIn {
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(#e2e2e2, .4);
      mix-blend-mode: multiply;
      position: absolute;
      animation: split .3s;
    }

    @keyframes split {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }
}

@include sm-view {
  .input {
    width: 100%;
  }
}
.select {
  &_wrap {
    width: 100%;
    border-bottom: 1px solid var(--gray);
    position: relative;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -70%) rotate(45deg);
    width: 10px;
    height: 10px;
    border-bottom: 1px solid var(--gray);
    border-right: 1px solid var(--gray);
    cursor: pointer;
    pointer-events: none;
  }
  select {
    padding: 13px 40px 13px 13px;
    width: 100%;
    border: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    color: var(--black);
    background-color: white;
    &.placeholder {
      color: var(--gray);
    }
  }
}
.textarea {
  width: 100%;
  padding: 13px;
  color: var(--black);
}
.message {
  font-size: 12px;
  text-align: center;
}
.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
  &:disabled {
    background-color: #ccc;
  }
}
</style>
