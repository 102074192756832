<template>
  <ol :class="$style.container">
    <slot />
  </ol>
</template>

<script>
export default {
  name: 'ArticleList',
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--a-g-x) var(--a-g-y);
  width: 100%;
  overflow: hidden;
}
</style>
