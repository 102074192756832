<template>
  <li
    @click="pushPaperWeather"
    :class="[$style.container]"
  >
    <div :class="$style.title">
      <span>予報気温</span><span>(6時間後)</span>
    </div>
    <div :class="$style.data">{{ temperature || '--' }}&#8451;</div>
  </li>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TemperatureInfo',
  props: ['temperature'],
  computed: {
    ...mapState(['helper']),
  },
  methods: {
    pushPaperWeather() {
      this.$router.push('/paper/latest/?page=8');
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  width: calc((100% - var(--s)) / 2);
  aspect-ratio: 1/1;
  border: 1px solid var(--border-gray);
  cursor: pointer;
}

.title {
  font-size: 12px;
  line-height: 1.4;

  span {
    display: inline-block;
  }
}

.data {
  text-align: right;
  font-weight: 900;
  font-size: 22px;
  line-height: 100%;
}

@include sm-view {
  .container {
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    aspect-ratio: auto;
    border-radius: 4px;
    width: auto;
    min-width: 100px;
    flex-shrink: 0;
  }

  .title {
    width: 5em;
    font-size: 11px;
    text-align: center;
  }

  .data {
    font-size: 16px;
  }
}
</style>
