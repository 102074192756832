<template>
  <div class="registUsername">
    <p :class="$style.modal_title">氏名の登録が必須となりました。<br>引き続きログインしてご覧いただくには、氏名をご登録ください。</p>
    <div :class="$style.wrap">
      <div :class="$style.form">
        <div :class="$style.input">
          <div>
            <p :class="$style.label">姓</p>
            <input
              id="lastName"
              type="text"
              name="lastName"
              v-model.trim="$v.lastName.$model"
              v-on:input="invalidsCheck('lastName', $v.lastName.$invalid)">
            <span class="slide-in"></span>
            <p
              :class="$style.comment"
              v-if="$v.lastName.$dirty
                && $v.lastName.required.$invalid">姓を入力してください</p>
          </div>
          <spacer :x="2"/>
          <div>
            <p :class="$style.label">名</p>
            <input
              id="firstName"
              type="text"
              name="firstName"
              v-model.trim="$v.firstName.$model"
              v-on:input="invalidsCheck('firstName', $v.firstName.$invalid)">
            <span class="slide-in"></span>
            <p
              :class="$style.comment"
              v-if="$v.firstName.$dirty
                && $v.firstName.required.$invalid">名を入力してください</p>
          </div>
        </div>
      </div>
    </div>
    <spacer :y="3"/>
    <div :class="$style.wrap">
      <button
        type="submit"
        @click="submit"
        :class="[$style.btn, !submitFlag ? $style.disabled : '']"
        :disabled="!submitFlag">登録</button>
      <spacer :x="2"/>
      <button
        type="button"
        :class="[$style.btn, $style.bdr]"
        @click="logout">ログアウト</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Spacer from '@/components/Spacer';

export default {
  name: 'regist-username',
  components: {
    Spacer,
  },
  data() {
    return {
      invalids: {
        firstName: true,
        lastName: true,
      },
    };
  },
  setup() {
    const firstName = ref('');
    const lastName = ref('');

    const rules = {
      firstName: { required },
      lastName: { required },
    };

    const $v = useVuelidate(rules, { firstName, lastName });

    return { firstName, lastName, $v };
  },
  computed: {
    ...mapState(['user']),
    submitFlag() {
      return !this.invalids.lastName && !this.invalids.firstName;
    },
  },
  methods: {
    hideModal() {
      this.$store.dispatch('modal/contents/hideModal', null, { root: true });
    },
    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },
    submit() {
      if (!this.firstName || !this.lastName) alert('姓または名を入力してください');

      const data = {
        id: this.user.id,
        first_name: this.firstName,
        last_name: this.lastName,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          alert('氏名を登録しました。');
          this.$store.dispatch('user/update');
          this.hideModal();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
        });
    },
    logout() {
      this.$store.dispatch('modal/contents/hideModal', null, { root: true });
      this.$store.dispatch('user/login/logout', null, { root: true });
    },
  },
};
</script>

<style lang="scss" module>
.modal_title {
  text-align: center;
  margin-bottom: 32px;
  font-size: 16px;
  @include sm-view {
    margin-bottom: 20px;
    font-size: 12px;
  }
}
.wrap {
  display: flex;
  justify-content: center;
  @include sm-view {
    flex-flow: column;
  }
}

.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
  @include sm-view {
    text-align: center;
  }
  &.bdr {
    background-color: #fff;
    border: 1px solid #666;
    color: #666;
  }
}
.disabled {
  background-color: var(--lightgray);
}

.form { // いる
  text-align: center;
}
.label {
  font-size: 12px;
  padding-left: 4px;
  text-align: left;
  line-height: 1;
  font-weight: bold;
}
.input {
  margin: 0 auto;
  width: 70%;
  max-width: 100%;
  position: relative;
  display: flex;
  input {
    width: 100%;
    padding: 13px;
    border: none;
    background-color: var(--gray-sub);
    color: var(--black);
    outline: none;
    border-bottom: 1px solid var(--gray);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    &:focus + .slideIn {
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(#e2e2e2, .4);
      mix-blend-mode: multiply;
      position: absolute;
      animation: split .3s;
    }

    @keyframes split {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }
}
.comment {
  font-size: 12px;
  color: var(--pink);
}
</style>
