<template>
  <div :class="$style.wrap">
    <ul
      v-if="planLoaded"
      :class="[$style.plan_list]">
      <li :class="$style.plan"
        v-for="(plan, planName) in plans"
        :key="planName">
        <div
          :for="planName"
          :class="[$style.label]">
          <div>
            <div
              v-if="helper.master?.products?.plans"
              :class="$style.label_title">{{ helper.master?.products?.plans[planName]?.label }}</div>
            <Spacer :y="4"/>
            <div :class="$style.assistant">
              <p
                v-for="des in helper.master?.products?.plans[planName]?.description"
                :key="des"><i class="fa-regular fa-badge-check"/>{{ des }}</p>
            </div>
            <Spacer :y="4"/>
          </div>
          <div>
            <div
              :class="$style.price">
              <div
                v-if="helper.master?.products?.plans"
                :class="$style.default">&yen;{{ plan.unit_amount }}/{{ helper.master?.products?.plans[planName]?.term }}</div>
              <p v-if="showTrial"
                :class="[$style.arrow, $style.down]"><i class="fa-regular fa-arrow-down"/></p>
              <div
                v-if="showTrial"
                :class="$style.trial">
                <p :class="$style.trial_assistant">今だけ!!なんと</p>
                <p :class="$style.trial_price">&yen;<span :class="$style.zero">０</span>/月</p>
                <p :class="$style.trial_des">会員登録から２週間無料</p>
              </div>
            </div>
            <Spacer :y="4"/>
            <div>
              <div :class="$style.btns">
                <div
                  @click="linkEditSubscription(planName)"
                  :class="[$style.btn, $style.primary]">このプランで申し込む</div>
              </div>
            </div>
            <Spacer :y="4"/>
          </div>
        </div>
      </li>
    </ul>
    <Loading v-else />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Spacer from '@/components/Spacer';
import Loading from '@/components/Loading';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'subscriptions',
  mixins: [cf],
  components: {
    Spacer,
    Loading,
  },
  data() {
    return {
      coursename: 'web',
      product: null,
      planLoaded: false,
      plans: {
        web: {},
        webPaper: {},
      },
      tax: null,
    };
  },
  computed: {
    ...mapState(['helper', 'user']),
    showTrial() {
      if (!this.user.email) return true;

      const limitTrial = this.formatTimestamp(this.user.limit_trial, 'YYYY-MM-DD');
      const today = this.formatTimestamp(new Date(), 'YYYY-MM-DD');
      return today <= limitTrial;
    },
  },
  mounted() {
    // mountedだと正常に動作
    if (Object.keys(this.helper.master).length) {
      this.getSubscriptionData();
    } else {
      // helper.master中身がundefinedの時に反映を監視
      this.$store.watch(
        (state) => state.helper.master,
        () => {
          this.getSubscriptionData();
        },
      );
    }
  },
  methods: {
    getSubscriptionData() {
      const env = this.helper.env.name;
      const params = {
        subscription_id: this.helper.master?.products?.product_id[env],
      };

      this.axios({
        method: 'GET',
        url: 'v1/stripe/subscription/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.product = res.product;
          this.tax = res.tax;
          this.planLoaded = true;
          res.prices.data.forEach((price) => {
            this.plans[price.nickname] = price;
          });
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
        });
    },
    linkEditSubscription(planName) {
      if (this.user.login.isLogin) {
        this.$router.push(`/edit/subscription/?plan=${planName}`);
      } else {
        const args = {
          modalName: 'UserLimited',
          data: {
            message: '有料プランの申込を行うには新規会員登録またはログインが必要です。',
            path: '/edit/subscription/',
            query: {
              plan: planName,
            },
          },
        };
        this.$store.dispatch('modal/contents/showModal', args, { root: true });
      }
    },
  },
};
</script>
<style lang="scss" module>
.wrap {
  max-width: 1220px;
  margin: 0 auto;
}

.btns {
  display: flex;
  justify-content: center;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 128px;
  height: 40px;
  padding: 13px 26px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  border-radius: 20px;
  cursor: pointer;
  appearance: none;
  box-shadow: none;
  transition: all .3s;

  &.primary {
    background-color: rgba(26, 34, 61, 1);
    border: none;
    color: #fff;
    a {
      color: #fff;
    }
    &:hover, &.disabled {
      opacity: .6;
    }
  }
}

.plan_list {
  display: flex;
  align-items: stretch;
  > li {
    width: calc(100% / 3);
    padding: 12px;
    > * {
      height: 100%;
    }
  }

  @include sm-view {
    display: block;
    > li {
      width: 100%;
    }
  }
}

.label {
  border: 1px solid #858585;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  border-color: var(--black);
  border-width: 1.5px;
  color: var(--black);

  &_title {
    $pdgY: 15px;
    $fs: 20px;
    padding: $pdgY 32px;
    background-color: var(--black);
    color: #fff;
    text-align: center;
    font-size: $fs;
    height: calc(($fs * 1.8) * 2 + $pdgY * 2);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .assistant {
    padding: 0 32px;
    font-size: 12px;
    display: flex;
    flex-flow: column;
    align-items: center;

    i {
      font-size: 16px;
      color: green;
      margin-right: 4px;
    }
  }

  &_current {
    font-size: 12px;
    color: var(--gray);
  }
  
  .price {
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
  }

  .default {
    font-size: 24px;
    &.notDefault {
      position: relative;
      &::after {
        content: '';
        width: 100%;
        height: 2px;
        background-color: var(--font-black);
        position: absolute;
        top: 50%;
        left: 0;
      }
    }
  }

  .arrow {
    font-size: 24px;
  }

  .trial {
    margin-left: 16px;
    display: flex;
    flex-flow: column;
    align-items: center;
    &_assistant {
      font-size: 14px;
    }
    &_price {
      font-size: 26px;
      .zero {
        font-size: 37px;
        line-height: 1;
      }
    }
    &_des {
      font-size: 12px;
    }
    @include xs-view {
      margin-left: 0;
      margin-top: 12px;
    }
  }
}

</style>
