<template>
  <div
    :class="$style.container"
    :style="{
      height: innerHeight,
      transition: transition,
    }"
  >
    <div :class="$style.inner" ref='inner'>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentHeight',
  props: ['transitionStyle'],
  data() {
    return {
      innerHeight: '100%',
      transition: this.transitionStyle || 'height 300ms ease-in-out',
    };
  },
  methods: {
    updateHeight() {
      const { inner } = this.$refs;
      this.innerHeight = `${inner.offsetHeight}px`;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.inner {
  border: transparent 1px solid;
  overflow: hidden;
}
</style>
