<template>
  <div>
    <Loading v-if="flag.isLoading" />
    <div>
      <div :class="$style.selectDate_wrap"
        v-if="!flag.isLoading">
        <ul :class="$style.selectDate">
          <li
            v-for="item in selectItems"
            :key="item">
            <select
              :name="item.name"
              :id="`selectItem-${item.name}`"
              v-model="item.value">
              <option
                v-for="option in options(item.name)"
                :key="option"
                :value="option">{{ option }} {{ item.label }}</option>
            </select>
          </li>
        </ul>
        <router-link
          :class="$style.search"
          :to="link">検索</router-link>
      </div>
      <div v-if="!flag.isLoading">
        <div v-if="papers.length">
          <ul :class="$style.paper_list">
            <li
              v-for="(paperItem, i) in papers"
              :key="paperItem"
              :class="[!allowReadPaidContent ? $style.blur : '']"
              @click="changePage(i)">
              <div :class="$style.imgBlocker">
                <img
                  :src="paperItem.url ? paperItem.url : '/img/logos/no-image.png'"
                  alt=""
                  oncontextmenu="return false;">
              </div>
            </li>
          </ul>
          <viewer
            v-if="paperIndex !== null"
            :images="papers"
            :showIdx="paperIndex"
            :isPaper="true"
            :sitePageType="'page'"
            v-on:changePage="changePage" />
        </div>
        <div :class="$style.wrap" v-if="!papers.length">紙面の登録がありません。</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import cf from '@/mixins/commonFunctions.js';
import Loading from '@/components/Loading.vue';
import Viewer from '@/components/Viewer/Viewer.vue';

export default {
  name: 'paper',
  mixins: [cf],
  components: {
    Loading,
    Viewer,
  },
  data() {
    return {
      date: null,
      flag: {
        isLoading: true,
      },
      papers: [],
      selectItems: [
        {
          label: '年',
          name: 'year',
          value: 1945,
        },
        {
          label: '月',
          name: 'month',
          value: 1,
        },
        {
          label: '日',
          name: 'day',
          value: 1,
        },
      ],
      paperIndex: null,
      webPlanPriceIds: [],
    };
  },
  created() {
    this.date = this.$route.params.slug;
    if (this.date === 'latest') {
      this.getPapers(this.date);
    } else if (this.date.length === 8 && Number(this.date)) {
      const split = this.date.match(/.{2}/g);
      this.selectItems[0].value = Number(`${split[0]}${split[1]}`);
      this.selectItems[1].value = Number(split[2]);
      this.selectItems[2].value = Number(split[3]);
      this.getPapers();
    } else {
      this.flag.invalidDate = true;
    }

    if (this.helper?.prices?.web) {
      this.getWebPlanPriceIds();
    } else {
      this.$store.subscribe((mutations) => {
        if (mutations.type === 'helper/putPrices') {
          this.getWebPlanPriceIds();
        }
      });
    }

    const query = this.$route.query;
    if (query.page) {
      // this.paperIndex = Number(query.page);
      if (this.user.login.isChecked) {
        this.checkUserState(Number(query.page - 1));
      } else {
        this.$store.subscribe((mutation) => {
          if (mutation.type === 'user/login/changeCheckedStatus') {
            this.checkUserState(Number(query.page - 1));
          }
        });
      }
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
    isPaid() {
      return Boolean(this.user.email
        && this.user.customer
        && this.user.customer.subscriptions.length
        && this.user.customer.subscriptions[0].expire
        && moment(new Date()).isBefore(moment(this.user.customer.subscriptions[0].expire)));
    },
    oldPriceIds() {
      if (this.helper && this.helper.master) return this.helper.master.products.old_price_type.map((row) => row.id);
      return [];
    },
    isWebPlan() {
      return Boolean(this.user.email
        && this.user.customer
        && this.user.customer.subscriptions.length
        && this.user.customer.subscriptions[0].expire
        && this.webPlanPriceIds.length
        && (this.webPlanPriceIds.includes(this.user.customer.subscriptions[0].price_id)
          || this.oldPriceIds.includes(this.user.customer.subscriptions[0].price_id)
        ));
    },
    isTrial() {
      return Boolean(this.user.email
        && this.user.limit_trial
        && moment(new Date()).isBefore(moment(this.user.limit_trial), 'day'));
    },
    isValidDevice() {
      return Boolean(this.user && this.user.login && this.user.login.isValidDevice);
    },
    allowReadPaidContent() {
      // // 特例対応(会員登録だけで有料コンテンツを確認できるかどうか)
      const specialFlag = 0;
      if (!specialFlag) { // デフォルトの判定
        if (this.user.role && [3, 4, 5, 6, 7, 8].includes(this.user.role.role)) return true; // v2・開発・ゲスト のいずれかの権限の場合には閲覧可能
        return ((this.isPaid && this.isWebPlan) || this.isTrial) && this.isValidDevice;
      }
      return Boolean(this.user.email);
    },
    link() {
      let path = '/paper/';
      this.selectItems.forEach((item) => {
        const value = item.value < 10 ? `0${item.value}` : item.value;
        path += value;
      });
      path += '/';
      return path;
    },
  },
  watch: {
    $route(to) {
      this.date = to.params.slug;
      if (this.$route.path.split('/')[1] === 'paper') {
        if (this.date === 'latest') {
          this.getPapers(this.date);
        } else {
          const split = this.date.match(/.{2}/g);
          this.selectItems[0].value = Number(`${split[0]}${split[1]}`);
          this.selectItems[1].value = Number(split[2]);
          this.selectItems[2].value = Number(split[3]);
          this.getPapers();
        }
        const query = this.$route.query;
        if (query.page) {
          if (this.user.login.isChecked) {
            this.checkUserState(Number(query.page - 1));
          } else {
            this.$store.subscribe((mutation) => {
              if (mutation.type === 'user/login/changeCheckedStatus') {
                this.checkUserState(Number(query.page - 1));
              }
            });
          }
        }
      }
    },
  },
  methods: {
    getWebPlanPriceIds() {
      this.flag.isLoading = true;
      if (!this.helper?.prices?.web) {
        this.webPlanPriceIds = [];
        return;
      }
      const webPlanId = this.helper.prices.web.id;
      const webPaperPlanId = this.helper.prices.webPaper.id;
      this.webPlanPriceIds = [webPlanId, webPaperPlanId];
      this.flag.isLoading = false;
    },
    changePage(index) {
      if (this.allowReadPaidContent) {
        this.paperIndex = index;
      } else {
        this.openSignupModal();
      }
      this.setMetas();
    },
    options(name) {
      const array = [];
      const start = name === 'year' ? 1945 : 1;
      let end = start;
      if (name === 'year') end = new Date().getFullYear();
      else if (name === 'month') end = 12;
      else {
        const month31 = [1, 3, 5, 7, 8, 10, 12];
        if (month31.includes(this.selectItems[1].value)) end = 31; // 31日まである月
        else if (this.selectItems[1].value !== 2) end = 30; // 30日までの月
        else if (this.selectItems[0].value % 4 === 0 // 閏年
          && (this.selectItems[0].value % 100 !== 0 || this.selectItems[0].value % 400 === 0)) end = 29;
        else end = 28; // 閏年以外の2月
      }
      for (let i = 0; i < end - start + 1; i += 1) {
        array.push(start + i);
      }
      return array;
    },
    getPapers(type) {
      const params = {};
      if (type === 'latest') {
        params.latest = true;
      } else {
        this.selectItems.forEach((item) => {
          params[item.name] = item.value;
        });
      }

      this.axios({
        method: 'GET',
        url: '/v1/paper/get/list',
        params,
      })
        .then((response) => {
          this.papers = response.data.list.data;
          // 最新日取得ならそのdateもselectItemsに反映する
          if (type === 'latest' && this.papers.length) {
            const targetDate = moment(this.papers[0].date);
            this.selectItems[0].value = targetDate.year();
            this.selectItems[1].value = targetDate.month() + 1;
            this.selectItems[2].value = targetDate.date();
          }
          this.setMetas();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.isLoading = false;
        });
    },
    checkUserState(pageIndex) {
      if (!this.allowReadPaidContent && !this.user.login.isLogin) this.openSignupModal();
      else this.paperIndex = Number(pageIndex);
    },
    openSignupModal() {
      const query = this.$route.query;
      const args = {
        modalName: 'UserLimited',
        data: {
          message: '紙面を見るにはログインまたは新規会員登録が必要です。',
          query: null,
        },
      };
      if (query.page) {
        args.data.query = {
          page: query.page,
        };
      }
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
    setMetas() {
      const defaults = {
        title: '桐生タイムス',
        description: '群馬県東部の桐生・みどり両市を拠点とする新聞社。日刊の夕刊紙「桐生タイムス」のほか週刊のフリーペーパー「タウンわたらせ」「週刊渡良瀬通信」を発行しています。',
        og_image: 'https://kiryutimes.s3.ap-northeast-1.amazonaws.com/production/OGP/ogp.png',
      };

      if (!this.papers) return;
      const meta = {};

      const y = this.selectItems[0].value;
      const m = this.selectItems[1].value < 10 ? `0${this.selectItems[1].value}` : this.selectItems[1].value;
      const d = this.selectItems[2].value < 10 ? `0${this.selectItems[2].value}` : this.selectItems[2].value;
      const date = `${y}${m}${d}`;
      const baseURL = `${location.protocol}//${location.host}/paper/${date}/`;

      meta.title = `${this.selectItems[0].value}年${this.selectItems[1].value}月${this.selectItems[2].value}日の紙面 | ${defaults.title}`;
      meta.description = `桐生タイムス ${this.selectItems[0].value}年${this.selectItems[1].value}月${this.selectItems[2].value}日の紙面です。`;
      meta.og_image = defaults.og_image;
      meta.og_url = `${baseURL}`;
      meta.type = 'article';

      if (this.paperIndex) {
        meta.title = `${this.selectItems[0].value}年${this.selectItems[1].value}月${this.selectItems[2].value}日 ${this.paperIndex + 1}面の紙面 | ${defaults.title}`;
        meta.description = `桐生タイムス ${this.selectItems[0].value}年${this.selectItems[1].value}月${this.selectItems[2].value}日 ${this.paperIndex + 1}面の紙面です。`;
        meta.og_url = `${baseURL}?page=${this.paperIndex + 1}`;
      }

      // meta sets
      // console.log(meta);
      document.title = meta.title;
      document.querySelector("meta[name='description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:title']").setAttribute('content', meta.title);
      document.querySelector("meta[property='og:description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:type']").setAttribute('content', meta.type);
      document.querySelector("meta[property='og:image']").setAttribute('content', meta.og_image);
      document.querySelector("meta[property='og:url']").setAttribute('content', meta.og_url);

      // canonicalタグの動的生成
      const canonical = document.querySelector("link[rel='canonical']");
      if (canonical) {
        // 存在する場合は書き換え
        canonical.href = meta.og_url;
      } else {
        // 存在しない場合は生成
        const linkTag = document.createElement('link');
        linkTag.setAttribute('rel', 'canonical');
        linkTag.href = meta.og_url;
        document.head.appendChild(linkTag);
      }
    },
  },
};
</script>

<style lang="scss" module>
.selectDate {
  display: flex;
  align-items: center;
  position: relative;
  &_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }

  li {
    margin-right: 12px;
    select {
      font-size: 14px;
      padding: 4px 4px 4px 12px;
      border-radius: 0;
      border-color: transparent transparent var(--gray) transparent;
      outline: none;
      color: black;
      background-color: #fff;
      border-width: 1px;
      //safariに対応
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
}
.search {
  padding: 4px 12px;
  border: 1px solid var(--gray);
  color: var(--gray);
  line-height: normal;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
}
.wrap {
  display: flex;
  justify-content: center;
  position: relative;
  background-color: var(--lightgray);
  padding: calc(var(--s) * 8) 0;
  overflow: hidden;
}
.paper {
  width: 1220px;
  max-width: 100%;
  overflow: hidden;
  cursor: grab;
  &.active {
    cursor: grabbing;
  }
  img {
    width: 100%;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
  }
}
.paidBtn_wrap {
  display: flex;
  justify-content: center;
}
.paidBtn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
}
.paper_list {
  position: relative;
  z-index: 100;
  margin: 20px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  margin: 0 -0.2em;
    // 紙面の4列リスト
    li {
      width: calc(25%);
      padding: .2em;
      margin-left: var(--margin-left);
      cursor: pointer;
      img {
        border: .5px solid var(--lightgray);
      }
      @include xs-view {
        width: calc(50%);
      }
    }
}
.blur {
  -ms-filter: blur(1px);
  filter: blur(1px);
}
.imgBlocker {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
}
</style>
