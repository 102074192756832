<template>
  <div class="deliveryAreaInfo">
    <div :class="$style.title">
      <p>配達エリア情報</p>
      <i
        class="fa-regular fa-circle-xmark"
        :class="$style.close"
        @click="hideModal"
      />
    </div>
    <Spacer :y="4"/>
    <ul>
      <li
        v-for="a in area"
        :key="a"
        :class="$style.area">
        <p :class="$style.type">【{{ a.label }}】</p>
        <Spacer :y="2"/>
        <ul :class="$style.district_wrap">
          <li
            v-for="d in a.district"
            :key="d"
            :class="$style.district">
            {{ d }}
          </li>
        </ul>
      </li>
    </ul>
    <Spacer :y="6"/>
    <div
      :class="$style.closeBtn"
      @click="hideModal">閉じる</div>
  </div>
</template>

<script>
import Spacer from '@/components/Spacer';

export default {
  name: 'delivery-area-info',
  props: ['data'],
  components: {
    Spacer,
  },
  data() {
    return {
      area: [
        {
          type: 'evening',
          label: '夕刊配達エリア',
          district: [
            '旧桐生市',
            'みどり市大間々町大間々',
            'みどり市大間々町高津戸',
            'みどり市大間々町桐原（一部。瀬戸が原は朝刊配達）',
            'みどり市笠懸町阿佐美',
            'みどり市笠懸町鹿（県道69号から桐生市側）',
            'みどり市笠懸町久宮（県道69号から桐生市側）',
          ],
        },
        {
          type: 'morning',
          label: '翌日朝刊配達エリア',
          district: [
            '桐生市新里町',
            'みどり市東町',
            'みどり市大間々町浅原・小平・上神梅・下神梅・塩沢・塩原',
            'みどり市笠懸町西鹿田',
            'みどり市笠懸町鹿（県道69号から伊勢崎市側）',
            'みどり市笠懸町久宮（県道69号から伊勢崎市側）',
          ],
        },
        {
          type: 'mail',
          label: '郵送配達',
          district: [
            '桐生市梅田町桐生川ダムから上',
          ],
        },
      ],
    };
  },
  computed: {},
  methods: {
    hideModal() {
      this.$store.dispatch('modal/contents/hideModal', null, { root: true });
    },
  },
};
</script>

<style lang="scss" module>
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  .close {
    cursor: pointer;
  &:hover {
    color: var(--font-gray);
  }
  }
}
.type {
  font-weight: bold;
  font-size: 16px;
}
.area {
  &:not(:first-child) {
    margin-top: 32px;
  }
}
.district {
  &_wrap {
    margin-left: 24px;
  }
  &:not(:first-child) {
    margin-top: 12px;
  }
}
.closeBtn {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  padding: 13px 26px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  border-radius: 20px;
  cursor: pointer;
  appearance: none;
  box-shadow: none;
  transition: all .3s;
  background-color: rgba(26, 34, 61, 1);
  border: none;
  color: #fff;
  &:hover {
    opacity: .6;
  }
}
</style>
