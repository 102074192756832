<template>
  <div :class="isOpenClass">
    <button :class="$style.close" @click="closeMenu" />
    <div :class="$style.scrollWrap">
      <Spacer :y='12'/>
      <nav>
        <ul :class="$style.list">
          <li
            v-for="category in categories"
            :key="category.slug"
            :class="$style.category"
          >
            <template v-if="!category.children?.length">
              <router-link
                :to="`/category/${category.name}/`"
                :class="$style.parent"
              >
                {{ category.label }}
              </router-link>
            </template>
            <template v-if="category.children?.length">
              <button
                :class="$style.parent"
                @click="updateActiveAccodions(category.name)"
              >
                {{ category.label }}
                <i
                  class="fa-solid fa-chevron-down"
                  :class="[$style.arrow, isActiveClass(category.name)]"
                />
              </button>
              <ul :class="[$style.children, isActiveClass(category.name)]">
                <li :class="$style.category">
                  <router-link
                    :class="$style.child"
                    :to="`/category/${category.name}/`"
                  >
                  すべての記事
                  </router-link>
                </li>
                <li
                  v-for="child in category.children"
                  :key="child.id"
                  :class="$style.category"
                >
                  <router-link
                    :class="$style.child"
                    :to="`/category/${child.name}/`"
                  >
                    {{ child.label }}
                  </router-link>
                </li>
              </ul>
            </template>
          </li>
          <li :class="[$style.parent, $style.category]">
            <router-link
              :to="'/paper/latest/'"
              :class="$style.paperlink"
            >紙面版で見る</router-link>
          </li>
        </ul>
      </nav>
      <Spacer :y='6'/>
      <div :class="$style.account">
        <AccountLinks />
      </div>
      <Spacer :y='12'/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import Spacer from '@/components/Spacer';
import cf from '@/mixins/commonFunctions.js';
import AccountLinks from './AccountLinks';

export default {
  name: 'DrawerMenu',
  mixins: [cf],
  components: {
    AccountLinks,
    Spacer,
  },
  computed: {
    ...mapState(['view']),
    ...mapState('category', ['categories']),
    isOpenClass() {
      return `${this.$style.container} ${this.view.show_menu ? this.$style.isOpen : ''}`;
    },
    yesterday() {
      return this.formatTimestamp(moment(new Date()).add(-1, 'd'), 'YYYYMMDD');
    },
  },
  data() {
    return {
      isActiveAccordions: [],
    };
  },
  methods: {
    ...mapActions('view', ['closeMenu']),
    updateActiveAccodions(name) {
      this.isActiveAccordions = this.isActiveAccordions.includes(name)
        ? this.isActiveAccordions.filter((accordion) => accordion !== name)
        : [name, ...this.isActiveAccordions];
    },
    isActiveClass(name) {
      return this.isActiveAccordions.includes(name) ? this.$style.isActive : '';
    },
  },
  mounted() {
    this.$store.watch(
      (state) => state.view.show_menu,
      () => {
        if (this.view.show_menu) return;
        this.isActiveAccordions = [];
      },
    );
  },
};
</script>

<style lang="scss" module>
.container {
  position: fixed;
  top: 0;
  left: 100%;
  height: 100vh;
  width: min(100%, 500px);
  background-color: #fff;
  transform: translate3d(0, 0, 0);
  transition: transform 300ms ease-in-out;
  z-index: 100;

  &.isOpen {
    transform: translate3d(-100%, 0, 0);
  }
}

.close {
  position: absolute;
  display: block;
  height: 36px;
  width: 36px;
  top: calc(var(--s) * 4);
  right: var(--g);
  background-size: 24px;
  appearance: none;
  border: none;
  box-shadow: none;
  background: transparent url("~@/assets/images/icon/close.svg") center no-repeat;

  @include sm-view {
    top: calc(var(--s) * 2);
  }
}

.scrollWrap {
  height: 100vh;
  overflow: auto;
}

.list {
  width: 280px;
  margin: auto;
}

.item {
  width: 100%;

  & + & {
    border-top: 1px solid var(--border-gray);
  }
}

.parent {
  display: flex;
  align-items: center;
  width: 100%;
  padding: calc(var(--s) * 2) 0;
  font-size: 16px;
  font-weight: bold;
  color: var(--font-black);
  text-align: left;
  background-color: transparent;
  border: none;
}

.children {
  height: 0;
  opacity: 0;
  padding-left: 20px;
  overflow: hidden;
  transition: opacity 200ms ease-in-out;

  &.isActive {
    border-top: 1px solid var(--border-gray);
    height: auto;
    opacity: 1;
  }
}

.child {
  display: block;
  padding: calc(var(--s) * 2) 0;
  color: var(--font-black);
  font-weight: normal;
  font-size: 14px;
}

.arrow {
  font-size: 12px;
  margin-left: 6px;
  transform: rotate(0deg);
  transition: transform 300ms ease-in-out;

  &.isActive {
    transform: rotate(-180deg);
  }
}

.account {
  display: flex;
  justify-content: center;
}

.paperlink {
  font-size: 14px;
  border-radius: 4px;
  font-weight: bold;
  padding: 6px 16px;
  color: var(--black);
  border: 1px solid var(--black);
  transition: all .3s;
  &:hover {
    color: var(--black);
    opacity: .5;
  }
}
</style>
