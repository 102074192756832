<template>
  <div :class="$style.container" v-if="taxonomy">
    <h1 :class="$style.title">{{ taxonomy.label }}</h1>
    <Spacer :y="4"/>
    <p :class="$style.description">{{ taxonomy.og_description}}</p>
    <Spacer :y="4"/>
    <!-- <TagList v-if="tags.length">
      <TagListItem
        v-for="item in tags"
        :key="item.id"
        :tag="item"
      />
    </TagList> -->
  </div>
</template>

<script>
import Spacer from '@/components/Spacer';
// import {
//   TagList,
//   TagListItem,
// } from '@/components/TagList';

export default {
  name: 'InfoArea',
  components: {
    Spacer,
    // TagListItem,
    // TagList,
  },
  props: ['taxonomy'],
  data() {
    return {
      tags: [],
    };
  },
  created() {
    this.getTags();
  },
  methods: {
    getTags() {
      this.axios({
        method: 'GET',
        url: '/v1/tag/get/list',
      })
        .then((response) => {
          const res = response.data;
          this.tags = res.tags.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: block;
}

.title {
  font-weight: 700;
  font-size: 48px;
  word-break: break-all;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8;
  color: var(--font-gray);
  white-space: pre-line;
}

@include sm-view {
}
</style>
