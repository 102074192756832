<template>
  <div :class="$style.wrap">
    <ul v-if="adjustedMessages.length">
      <li
        v-for="message in adjustedMessages"
        :key="message"
        v-show="message">
        {{ message.label }}
        <router-link
          v-if="message.link"
          :to="message.link.to">{{ message.link.label }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'alert',
  computed: {
    ...mapState(['user']),
    adjustedMessages() {
      const messages = [];
      if (this.user?.login?.alertMessages) {
        Object.keys(this.user.login.alertMessages).forEach((key) => {
          if (this.user.login.alertMessages[key]) {
            messages.push({
              label: this.user.login.alertMessages[key].message,
              link: this.user.login.alertMessages[key].link,
            });
          }
        });
      }
      return messages;
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  ul {
    background-color: rgba(red, .2);
    border-bottom: 1px solid rgba(red, .5);
    padding: 4px 12px;
    font-size: 14px;
  }
  a {
    color: var(--fontblack);
    text-decoration: underline;
  }
}
</style>
