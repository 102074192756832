<template>
  <div class="policy pages">
    <div
    class="container"
    v-if="policy"
    >
      <h2 v-html="policy.title" :class="$style.title"></h2>
      <p v-html="policy.description.desTitle"></p>
      <p v-html="policy.description.desDescription" :class="$style.desDes" ></p>

      <ul :class="$style.sec">
        <li
          v-for="(sec, i) in policy.sections"
          :key="i"
          :class="$style.secClass"
        >
          <p v-html="sec.titles" :class="$style.titles"></p>
          <p v-html="sec.description" :class="$style.des"></p>
          <ul v-if="sec.onlyBralist">
            <li
              v-for="(only, i) in sec.onlyBralist"
              :key="i"
              :class="$style.onlyBra"
            >
            <p v-html="only.bratitle"></p>
            <p v-html="only.bradescription" :class="$style.des"></p>
            </li>
          </ul>
          <ul :class="$style.num">
            <li
              v-for="(num, i) in sec.numlist"
              :key="i"
              :class="$style.numClass"
            >
              <p v-html="num.title"></p>
              <p v-html="num.description" :class="$style.des"></p>
              <ul>
                <li
                  v-for="(nlist, i) in num.nlist"
                  :key="i"
                  :class="$style.nlist"
                >
                  <p v-html="nlist.title"></p>
                </li>
                <ul>
                  <li
                  v-for="(bra, i) in num.bralist"
                  :key="i"
                  >
                  <p v-html="bra.bratitle"></p>
                  <p v-html="bra.bradescription" :class="$style.des"></p>
                  </li>
                </ul>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import policy from '@/data/pages/policy.yaml';

export default {
  name: 'policy',
  data() {
    return {
      policy,
    };
  },
};
</script>

<style lang="scss" module>

.sec {
  .secClass {
    margin-top: 40px;
    counter-increment: only;
    padding-left: 40px;
    position: relative;
    &::before {
      position: absolute;
      left: 20px;
      content: counter(only) ". ";
    }
    .num {
      .numClass {
        counter-increment: num;
        padding-left: 45px;
        &::before {
          position: absolute;
          left: 55px;
          content:"(" counter(num) ")";
        }
      }
    }
  }
}
.title {
  margin-bottom: 30px;
}
.nlist {
  list-style: disc;
  margin-left: 40px;
}
.titles {
  font-weight: bold;
  margin-bottom: 12px;
}
.des {
  text-indent: 1em;
  word-break: break-all;
}
.desDes {
  padding-left: 15px;
  text-indent: 1em;
}
.onlyBra {
  padding-left: 45px;
}
</style>
