<template>
  <form
    id="payment-form"
    class="form vertical payment"
    v-on:submit.prevent="submit">
    <Spacer :y="2" />
    <CardIcons />
    <div :class="$style.form">
      <dl>
        <dt>
          <label>クレジットカード番号</label>
        </dt>
        <dd>
          <div :class="$style.input"><div id="cardNumber"></div></div>
          <div
            class="form-assistance text-danger"
            v-if="errors.cardNumber"
            v-html="errors.cardNumber"></div>
        </dd>
      </dl>
    </div>

    <div :class="$style.form">
      <dl>
        <dt>
          <label>有効期限</label>
        </dt>
        <dd>
          <div :class="$style.input"><div id="cardExpiry"></div></div>
          <div
            class="form-assistance text-danger"
            v-if="errors.cardExpiry"
            v-html="errors.cardExpiry"></div>
        </dd>
      </dl>
    </div>

    <div :class="$style.form">
      <dl>
        <dt>
          <label>セキュリティコード</label>
        </dt>
        <dd>
          <div :class="$style.input"><div id="cardCvc"></div></div>
          <div
            class="form-assistance text-danger"
            v-if="errors.cardCvc"
            v-html="errors.cardCvc"></div>
        </dd>
      </dl>
    </div>
    <ul class="btn-list horizontal" v-if="!hideBtn">
      <li>
        <button
          :class="[
            $style.btn, $style.primary,
            !flag.submit ? $style.disabled : ''
          ]"
          :disabled="!flag.submit"
          v-on:click="registCard">カード登録</button>
      </li>
    </ul>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import cf from '@/mixins/commonFunctions';
import CardIcons from '@/components/parts/CardIcons.vue';
import Spacer from '@/components/Spacer';

// stripe
const STRIPE_PUB_KEY = process.env.NODE_ENV === 'production'
  ? process.env.VUE_APP_STRIPE_PUBLIC_KEY_PROD
  : process.env.VUE_APP_STRIPE_PUBLIC_KEY_DEV;
const stripePromise = loadStripe(STRIPE_PUB_KEY);

export default {
  name: 'CardRegist',
  mixins: [cf],
  components: {
    CardIcons,
    Spacer,
  },
  props: {
    hideBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      state: {
        cardNumber: false,
        cardExpiry: false,
        cardCv: false,
      },
      errors: {
        cardNumber: null,
        cardExpiry: null,
        cardCv: null,
      },
      flag: {
        submit: false,
        cardError: false,
      },
      message: {
        cardError: '',
      },
    };
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    this.generateCardElement();
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** カード登録の要素を作成 */
    async generateCardElement() {
      const stripe = await stripePromise;
      const elements = stripe.elements({
        fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Source+Code+Pro' }],
      });
      const style = {
        base: {
          color: '#303238',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
          lineHeight: 1.6,
          textDecoration: 'underline #D3D3D3',
          '::placeholder': {
            color: '#A9A9A9',
          },
        },
        empty: {
          color: '#A9A9A9',
          textDecoration: 'underline',
        },
        invalid: {
          color: '#e5424d',
          ':focus': {
            color: '#303238',
          },
        },
      };

      // カード番号
      this.cardNumber = elements.create('cardNumber', { style });
      this.cardNumber.mount('#cardNumber');
      this.cardNumber.addEventListener('change', (event) => {
        this.state.cardNumber = event.complete;
        if (event.complete) this.cardExpiry.focus();
        this.errors.cardNumber = event.error ? event.error.message : null;
        this.completed();
      });

      // 有効期限
      this.cardExpiry = elements.create('cardExpiry', { style });
      this.cardExpiry.mount('#cardExpiry');
      this.cardExpiry.addEventListener('change', (event) => {
        this.state.cardExpiry = event.complete;
        if (event.complete) this.cardCvc.focus();
        this.errors.cardExpiry = event.error ? event.error.message : null;
        this.completed();
      });

      // セキュリティコード
      this.cardCvc = elements.create('cardCvc', { style });
      this.cardCvc.mount('#cardCvc');
      this.cardCvc.addEventListener('change', (event) => {
        this.state.cardCvc = event.complete;
        this.errors.cardCvc = event.error ? event.error.message : null;
        this.completed();
      });
    },

    /** カード登録トークン取得 */
    async getToken() {
      const stripe = await stripePromise;
      const token = await stripe.createToken(this.cardNumber);
      return token;
    },

    completed() {
      const completed = this.state.cardNumber && this.state.cardExpiry && this.state.cardCvc;
      this.flag.submit = completed;
      this.$emit('sendFlag', this.flag.submit);
    },

    /** 登録フラグの受け取り */
    receiveRegistFlag(data) {
      this.flag.completed.card = data.completed;
      this.flag.submit = this.flag.completed.card;
    },

    registed() {
      this.$emit('registed');
    },

    async registCard() {
      this.showLoading();
      // クレジットカードのトークン取得
      const card = await this.getToken();
      // エラー文言の初期化
      this.flag.cardError = false;
      this.message.cardError = '';
      if (card.error) {
        // エラー表示
        this.flag.cardError = true;
        this.message.cardError = card.error.message;
        this.hideLoading();
        return;
      }
      /**
       * 顧客およびカード情報の登録・更新
       * customer未登録の場合は登録・存在する場合は更新
       */
      const isRegist = !this.user.customer;
      const endpoint = isRegist ? 'register' : 'addCard';
      try {
        const customerId = this.user.customer
          ? this.user.customer.customer_id
          : null;
        if (isRegist) {
          const data = {
            customer_id: customerId,
            // 更新・登録ともにカードデータは必須
            customerData: { source: card.token.id },
          };
          // 登録時には追加の情報を登録
          data.customerData.email = this.user.email;
          data.customerData.name = !this.user.last_name && !this.user.first_name ? '名前未設定' : `${this.user.last_name} ${this.user.first_name}`;
          data.customerData.metadata = { user_id: this.user.id };
          data.customerData.preferred_locales = ['ja'];

          this.axios({
            method: 'POST',
            url: `/v1/stripe/customer/set/${endpoint}`,
            data,
          })
            .then((response) => {
              const res = response.data;
              const customerData = { customer: { customer_id: res.customer.id } };
              this.$store.dispatch('user/subscribe/getCustomer', customerData, { root: true });
              this.$store.dispatch('user/update', null, { root: true });
            })
            .catch((error) => {
              if (error.response) {
                console.log(error.response.data);
                alert(`お支払いカードの登録に失敗しました。お手数ですが別のカードをご利用いただくか、ご利用のカード会社へお問い合わせください。\n失敗の理由については以下サイトをご参照くださいませ。\nhttps://stripe.com/docs/declines/codes\nエラー内容: ${error.response.data.detail}`);
              } else {
                console.log(error);
              }
              this.hideLoading();
            })
            .finally(() => {
              this.registed();
              this.hideLoading();
            });

        } else {
          // customer登録済みの場合はカード情報のみ
          this.axios({
            method: 'POST',
            url: '/v1/stripe/set/addCreditcard',
            data: {
              token: card.token.id,
              customer: customerId,
            },
          })
            .then(() => {
              alert('クレジットカード情報を追加登録しました。');
              this.$store.dispatch('user/update', null, { root: true });
            })
            .catch((error) => {
              alert(`お支払いカードの登録に失敗しました。お手数ですが別のカードをご利用いただくか、ご利用のカード会社へお問い合わせください。\n失敗の理由については以下サイトをご参照くださいませ。\nhttps://stripe.com/docs/declines/codes\nエラー内容: ${error.response.data.detail}`);
              if (error.response) console.log(error.response.data);
              else console.log(error);
            })
            .finally(() => {
              this.hideLoading();
              const customer = { customer: { customer_id: customerId } };
              this.$store.dispatch('user/subscribe/getCustomer', customer, { root: true });
              this.registed();
            });
        }
      } catch (error) {
        if (error.response) console.log(error.response.data);
        else console.log(error);
        alert('お支払い情報の登録に失敗しました。\nお手数ですが管理者までお問い合わせください');
        this.hideLoading();
      }
    },

  },
};
</script>

<style lang="scss" module>
.form {
  margin-top: 24px;
  dl {
    margin-bottom: 24px;
    margin-top: 0;
  }
  label {
    font-size: 12px;
  }
  .input {
    border: 1px solid var(--lightgray);
    border-radius: 4px;
    padding: 4px 8px;
  }
  dd {
    margin-left: 0;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 128px;
  height: 40px;
  padding: 13px 26px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  border-radius: 20px;
  cursor: pointer;
  appearance: none;
  box-shadow: none;
  transition: all .3s;

  &.primary {
    background-color: rgba(26, 34, 61, 1);
    border: none;
    color: #fff;
    &:hover, &.disabled {
      opacity: .6;
      cursor: default;
    }
  }

  &.secondary {
    border: 1px solid rgba(26, 34, 61, 1);
    color: var(--font-black);
    background-color: #fff;
    &:hover {
      opacity: .4;
    }
  }
}
</style>
