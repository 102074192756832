<template>
  <li :class="$style.item">
    <router-link :to="`/article/${item.slug}/`" :class="$style.link">
      <template v-if="rank || item.rank">
        <span :class="$style.rank">{{ rank ? zeroPadding(rank, 2) : zeroPadding(item.rank, 2) }}</span>
        <Spacer :x="2" />
      </template>
      <div :class="$style.container">
        <div :class="$style.inner">
          <h3 :class="$style.title">{{ item.title }}</h3>
          <Spacer :y="1.5" />
          <div :class="$style.date">{{ formatTimestamp(item.releaseDate, 'YYYY.MM.DD') }}</div>
        </div>
        <template v-if="item.urls.thumbnail?.[0]?.image?.url">
          <Spacer :y="1.5" />
          <img :class="$style.thumb" :src="item.urls.thumbnail[0].image.url" :alt="item.title" />
        </template>
      </div>
    </router-link>
  </li>
</template>

<script>
import Spacer from '@/components/Spacer';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'ListItem',
  mixins: [cf],
  components: { Spacer },
  props: ['item', 'rank'],
  // props: {
  //   item: {
  //     rank: {
  //       type: Number,
  //       require: false,
  //     },
  //     title: {
  //       type: String,
  //       require: true,
  //     },
  //     date: {
  //       type: String,
  //       require: false,
  //     },
  //     thumb: {
  //       type: String,
  //       require: false,
  //     },
  //     url: {
  //       type: String,
  //       require: false,
  //     },
  //   },
  // },
  data() {
    return {};
  },
  methods: {
    zeroPadding(num, len) {
      return (Array(len).join('0') + num).slice(-len);
    },
  },
};
</script>

<style lang="scss" module>
.item {
  display: block;

  & + & {
    .container {
      border-top: 1px solid var(--border-gray);
    }
  }

  .container {
    width: 100%;
    padding: calc(var(--s) * 2.5) 0;
  }
}

.link {
  display: flex;
  align-items: center;
}

.rank {
  font-size: 23px;
  font-weight: bold;
  color: var(--font-gray);
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.6;
  text-align: left;
  color: var(--font-black);
}

.date {
  font-size: 12px;
  line-height: 1;
  color: var(--font-gray);
}

.thumb {
  width: 70px;
  height: 70px;
  object-fit: cover;
  pointer-events: none;
}
</style>
