/**
 * category
*/

const actions = {
  setCategories({ commit }, categories) {
    commit('putCategories', categories);
  },
};

const mutations = {
  putCategories(state, categories) {
    state.categories = [...state.categories, ...categories];
  },
};

const state = {
  categories: [
    {
      label: '人気',
      name: 'popular',
      og_description: 'よく見られている記事です。',
    },
    {
      label: 'おすすめ',
      name: 'recommend',
      og_description: 'おすすめの記事です。',
    },
  ],
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
