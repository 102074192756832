<template>
  <div>
    <div :class="$style.container">
      <div :class="$style.title">アカウント情報</div>
      <Spacer :y="4"/>
      <div class="loader-wrapper">
        <div
          class="loaded-contents"
          v-show="!flag.loader">
          <!-- メンバーシップ -->
          <ul :class="$style.category_wrap">
            <li>
              <div :class="$style.category">
                <div :class="$style.label">メンバーシップ</div>
                <ul :class="$style.item">
                  <li
                    v-for="item in membershipItems"
                    :key="item"
                    :class="$style.value">
                    <p v-if="user[item.name] && item.name !== 'password'">{{ user[item.name] }}</p>
                    <p v-if="item.name === 'password'">********</p>
                    <!-- 編集画面へのリンク -->
                    <div
                      :class="$style.onlyPC"
                      v-if="item.link">
                      <router-link :to="item.link.link">{{ item.link.label }}</router-link>
                    </div>
                  </li>
                </ul>
                <!-- 編集画面へのリンク（sp） -->
                <ul :class="[$style.onlySP, $style.links]">
                  <li
                    v-show="item.link"
                    v-for="(item, j) in membershipItems"
                    :key="j">
                    <router-link
                      :to="item.link.link"
                      v-if="item.link"
                      :class="$style.link">{{ item.link.label }}</router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

          <!-- プロフィール -->
          <ul :class="$style.category_wrap">
            <li>
              <div :class="$style.category">
                <div :class="$style.label">プロフィール</div>
                <ul :class="$style.item">
                  <li
                    v-for="item in profileItems"
                    :key="item"
                    :class="$style.value">
                      <p>
                        <span v-if="editing !== item.name">{{ user[item.name] ? user[item.name] : '未登録' }}<span v-if="item.label">（{{ item.label }}）</span></span>
                        <input
                          type="text"
                          v-if="editing === item.name"
                          v-on:blur="updateProfile"
                          v-model="editValue">
                        <i
                          v-if="editing === null"
                          class="fa-solid fa-pen-to-square"
                          v-on:click="editStart(item.name)"/>
                      </p>
                  </li>
                  <li :class="$style.icon_wrap">
                    <component
                      :is="!editing ? 'label' : 'div'"
                      :for="!editing ? 'icon' : ''"
                      :class="$style.icon"
                      :style="user.urls?.icon?.[0]?.url ? `background-image: url(${user.urls.icon[0].url})` : ''">
                      <i :class="$style.camera" class="fa-regular fa-camera"></i>
                    </component>
                    <input
                      style="display: none;"
                      type="file"
                      v-on:input="updateFile"
                      id="icon">
                    <p
                      :class="$style.btn"
                      v-if="user?.urls?.icon?.[0]?.url"
                      v-on:click="deleteUrl">画像を削除する</p>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

          <!-- 住所 -->
          <ul :class="$style.category_wrap" v-if="user.customer && user.customer.subscriptions.length && user.customer.subscriptions[0].price_id === webPaperPriceId">
            <li>
              <div :class="$style.category">
                <div :class="$style.label">住所</div>
                <ul :class="$style.item">
                  <li
                    v-for="item in addressItems"
                    :key="item">
                    <div :class="$style.value">
                      <span v-if="item.label" :class="$style.address_label">{{ item.label }}</span>
                      <p v-if="user && user.address && user.address.length" :class="$style.address">{{ adjustAddress(item.type) && adjustAddress(item.type).flag === 1 ? adjustAddress(item.type).label : '未設定' }}</p>
                      <i
                        v-if="editing === null"
                        class="fa-solid fa-pen-to-square"
                        v-on:click="updateEditFlag('address', item.name)"/>
                    </div>
                    <Spacer v-if="flag.editAddress[item.name]" :y="1"/>
                    <div
                      :class="$style.address_wrap"
                      v-if="flag.editAddress[item.name]">
                      <Address
                        :type="item.type"
                        :showRegistBtn="true"
                        v-on:updated="updatedAddress(item.name)"
                        v-on:isCancel="updateEditFlag('address', item.name)" />
                    </div>
                  </li>

                </ul>
              </div>
            </li>
          </ul>

          <!-- プラン -->
          <ul :class="$style.category_wrap">
            <li>
              <div :class="$style.category">
                <div :class="$style.label">プラン</div>
                <ul :class="$style.item">

                  <!-- ステータス -->
                  <li :class="[$style.value, $style.status]" v-if="!flag.loader">
                    <p>
                      <span v-if="isPaid && user.role && user.role.role === 7">有料会員(口座引落)</span>
                      <span v-else-if="isPaid || (isPaid && isTrial)">{{ helper.master.products.plans[planName].label }}</span>
                      <span v-else-if="isTrial">有料会員お試し期間（{{ formatTimestamp(user.limit_trial, 'YYYY年MM月DD日') }}まで）</span>
                      <span v-else>無料会員</span></p>
                    <div
                      :class="$style.onlyPC"
                      v-if="!isPaid">
                      <router-link
                        to="/edit/subscription/"
                        :class="$style.sm_link">有料会員登録する</router-link>
                    </div>
                    <div
                      :class="$style.onlyPC"
                      v-if="isPaid && !isCancelLatest">
                      <router-link to="/edit/subscription/">プラン変更・解約する</router-link>
                    </div>
                    <div
                      v-if="isPaid && isCancelLatest"
                      :class="$style.canceled">
                      <span :class="$style.onlySP">解約済  {{ formatTimestamp(user.customer.subscriptions[0].expire) }}まで有効</span>
                      <router-link
                        :class="$style.onlyPC"
                        to="/edit/subscription/">解約済  {{ formatTimestamp(user.customer.subscriptions[0].expire) }}まで有効</router-link>
                    </div>
                  </li>

                  <!-- 履歴 -->
                  <li :class="$style.value">
                    <div>
                      <p :class="$style.value_label">お支払い方法</p>
                      <div>
                        <div
                          :class="$style.card"
                          v-if="user.customer && user.subscribe.cards && user.subscribe.cards.cards.length">
                          <div
                            :class="$style.card_img"
                            v-bind:style="{ 'background-image':`url('/img/cards/${getCardIconName(user.subscribe.cards.cards[0].brand)}.png')`}"></div>
                            <p>**** **** **** {{ user.subscribe.cards.cards[0].last4 }}</p>
                        </div>
                        <div v-else>お支払い方法が登録されていません。登録してください。</div>
                      </div>
                    </div>
                    <div
                      v-if="!flag.editCards"
                      :class="$style.onlyPC">
                      <router-link to="" @click="editCard">カードを変更</router-link>
                    </div>
                  </li>

                  <li v-if="flag.editCards" :class="$style.onlyPC">
                    <div :class="$style.cardSelectWrap">
                      <p :class="$style.bold">カード情報変更</p>
                      <Spacer :y="2"/>
                      <CardSelect
                        v-if="user && user.customer && flag.editCards"
                        v-on:updateEditFlag="editCard" />
                    </div>
                  </li>

                  <li
                    v-if="user.customer && user.customer.subscriptions.length && nextPayment && isPaid && !isCancelLatest">
                    <p :class="$style.value_label">次回の請求内容</p>
                    <p>{{ formatTimestamp(nextPayment.next_payment_attempt, 'YYYY/MM/DD') }}　&yen;{{ addComma(nextPayment.total) }}（税込）</p>
                  </li>

                  <!-- 履歴 -->
                  <li v-if="user.customer && paymentsHistory.length">
                    <p :class="$style.value_label">支払い履歴</p>
                    <table :class="$style.historyTable">
                      <tbody>
                        <tr>
                          <td
                            v-for="item in historyItems"
                            :key="item">{{ item.label }}</td>
                        </tr>
                        <tr
                          v-for="(payment, i) of paymentsHistory"
                          :key="i">
                          <td>{{ payment.payment_date ? formatTimestamp(payment.payment_date, 'YYYY/MM/DD') : formatTimestamp(payment.created_at, 'YYYY/MM/DD') }}</td>
                          <td>&yen;{{ addComma(payment.total) }}（税込）</td>
                          <td>{{ payment.succeeded ? '成功' : '失敗' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>

                  <li v-if="flag.editCards" :class="$style.onlySP">
                    <div :class="$style.cardSelectWrap">
                      <p :class="$style.bold">カード情報変更</p>
                      <Spacer :y="2"/>
                      <CardSelect
                        v-if="user && user.customer && flag.editCards"
                        v-on:updateEditFlag="editCard" />
                    </div>
                  </li>

                </ul>

                <ul :class="[$style.onlySP, $style.links]">
                  <li>
                    <router-link
                      to="/edit/subscription/"
                      :class="$style.link">
                      <span v-if="isPaid && !isCancelLatest">プラン変更・解約する</span>
                      <span v-else-if="isPaid && isCancelLatest">再登録する</span>
                      <span v-else>有料会員登録する</span>
                    </router-link>
                  </li>
                  <li v-if="!flag.editCards">
                    <div
                      @click="editCard"
                      :class="$style.link">カードを変更</div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

          <!-- メール受信設定 -->
          <ul :class="$style.category_wrap">
            <li>
              <div :class="$style.category">
                <div :class="$style.label">設定</div>
                <ul :class="$style.item">
                  <li :class="$style.value">
                    <p :class="$style.sm_space">記事ダイジェストメール</p>
                    <div>
                      <div>
                        <input
                          type="checkbox"
                          id="emailSetting"
                          :true-value="1"
                          :false-value="0"
                          v-model="receiveEmail"
                          @change="updateUserSetting">
                        <label
                          class="ms-2"
                          for="emailSetting">受信する</label>
                      </div>
                    </div>
                  </li>
                  <li :class="$style.value">
                    <p :class="[$style.sm_space, $style.trash_days_title_space]">ゴミの日区域</p>
                    <div v-if="helper && helper.master && helper.master.labels">
                      <div>
                        <select
                          name="district"
                          class="form-select form-select-sm"
                          v-model="district"
                          @change="updateUserSetting">
                          <option :value="0">選択してください</option>
                          <option
                            v-for="(label, i) of helper.master.labels.district"
                            :key="label"
                            :value="i">{{ label }}</option>
                        </select>
                      </div>
                    </div>
                  </li>
                  <li :class="$style.value">
                    <p>閲覧デバイス</p>
                    <ul
                      :class="$style.taglist"
                      v-if="user?.devices?.length">
                      <li
                        v-for="device in user.devices"
                        :key="device.id">
                        {{ device.deviceName }}
                        <i
                          :class="$style.xmark"
                          class="fa-solid fa-circle-xmark"
                          v-on:click="deleteDevice(device.id)"
                          v-if="device.id !== deviceId"/>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <spacer :y="6"/>
          <div :class="$style.withdrawal">
            <p 
              :class="$style.assistant"
              class="form-assistance"
              v-if="!(isPaid && !isCancelLatest)">※退会完了後はログインできません</p>
            <p
              :class="$style.assistant"
              class="form-assistance"
              v-else>※退会したい場合には、先に定期購読の解約をしてください</p>
            <spacer :y="2"/>
            <div
              :class="[$style.btn, isPaid && !isCancelLatest ? $style.disabled : '']"
              @click="userWithdrawal"
              v-bind:disabled="isPaid && !isCancelLatest">退会する</div>
          </div>
        </div>
        <Loading
          class="loader-simple"
          v-if="flag.loader"></Loading>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Spacer from '@/components/Spacer.vue';
import Loading from '@/components/Loading.vue';
import CardSelect from '@/components/parts/CardSelect.vue';
import Address from '@/components/parts/Address.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'account',
  mixins: [cf],
  components: {
    Spacer,
    CardSelect,
    Loading,
    Address,
  },
  data() {
    return {
      editing: null,
      editValue: null,
      file: null,
      membershipItems: [
        {
          name: 'email',
          link: {
            label: 'メールアドレスの変更',
            link: '/edit/email/',
          },
        },
        {
          name: 'password',
          link: {
            label: 'パスワードの変更',
            link: '/edit/password/',
          },
        },
      ],
      profileItems: [
        {
          name: 'last_name',
          label: '姓',
        },
        {
          name: 'first_name',
          label: '名',
        },
      ],
      addressItems: [
        {
          name: 'personal',
          type: 1,
          label: '登録住所',
        },
        {
          name: 'delivery',
          type: 2,
          label: '配送先住所',
        },
      ],
      historyItems: [
        {
          label: 'お支払い日',
          name: 'payment_date',
        },
        {
          label: '金額',
          name: 'price',
        },
        {
          label: '決済状況',
          name: 'isSucceeded',
        },
      ],
      paymentsHistory: [],
      nextPayment: null,
      receiveEmail: 0,
      district: 0,
      product: {},
      price: {},
      flag: {
        loader: true,
        editCards: false,
        editAddress: {
          personal: false,
          delivery: false,
        },
      },
    };
  },
  created() {
    this.$store.dispatch('user/update');
    if (this.user.email) {
      this.checkPayment();
      this.initUserSetting();
    } else {
      this.$store.subscribe((mutation) => {
        // ユーザ情報を取得してvaluesを定義
        if (mutation.type === 'user/setUserData') {
          this.checkPayment();
          this.initUserSetting();
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
    isPaid() {
      // 有料会員(資格)を有するかどうか
      // ここのテストはStripeのWebhookをAPIで受け取る必要有り
      return Boolean((this.user.role && this.user.role.role === 7)
        || (this.user.email
        && this.user.customer
        && this.user.customer.subscriptions.length
        && this.user.customer.subscriptions[0].expire
        && moment(new Date()).isBefore(moment(this.user.customer.subscriptions[0].expire))));
    },
    isCancelLatest() {
      // 最新の購読がキャンセルされているかどうか
      return Boolean(this.user.email
        && this.user.customer
        && this.user.customer.subscriptions.length
        && this.user.customer.subscriptions[0].isCancel);
    },
    isTrial() {
      return Boolean(this.user.email
        && this.user.limit_trial
        && moment(new Date()).isBefore(moment(this.user.limit_trial), 'day'));
    },
    webPaperPriceId() {
      if (this.price.length) {
        const webPaper = this.price.find((row) => row.nickname === 'webPaper');
        if (webPaper) return webPaper.id;
        return null;
      }
      return null;
    },
    planName() {
      let plan = null;
      if (this.price.length) plan = this.price.find((row) => row.id === this.user.customer.subscriptions[0].price_id);
      return plan ? plan.nickname : '';
    },
    deviceId() {
      const ua = navigator.userAgent;
      let target;

      // デバイス判定
      let deviceName = 'others';
      if (ua.includes('iPhone')) deviceName = 'iPhone';
      else if (ua.includes('iPad')) deviceName = 'iPad';
      else if (ua.includes('Macintosh')) deviceName = 'Mac';
      else if (ua.includes('Android')) deviceName = 'Android';

      if (this.user?.devices?.length) target = this.user.devices.find((d) => d.deviceName === deviceName);

      return target && target.id ? target.id : null;
    },
  },
  watch: {
    isPaid() {
      /** 覚書: userのstateを監視する
       * - userのstateをcomputedする
       * - computedした値をwatchする
       * - user/updateする
       * - 該当ビュー・DOMをv-ifの値を書き換えることで再生成する
      */
      this.checkPayment();
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    checkPayment() {
      if (this.user && this.user.customer) {
        this.getPayments();
        if (this.isPaid && !this.isCancelLatest) {
          this.getNextPayment();
        }
        this.getSubscriptionData();
      } else {
        this.flag.loader = false;
      }
    },

    initUserSetting() {
      if (this.user.email) {
        this.receiveEmail = Number(this.user.receiveEmail);
        this.district = this.user.district ? Number(this.user.district) : 0;
      }
    },

    getPayments() {
      const params = {
        customer_id: this.user.customer.customer_id,
      };

      this.axios({
        method: 'GET',
        url: '/v1/stripe/payment/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.paymentsHistory = res.list;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
        });
    },

    getNextPayment() {
      const params = {
        customerId: this.user.customer.customer_id,
      };

      this.axios({
        method: 'GET',
        url: '/v1/stripe/get/upcoming_invoice',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.nextPayment = res.data[0] || null;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
        });
    },

    getSubscriptionData() {
      if (!this.user.customer || !this.user.customer.subscriptions.length) {
        this.flag.loader = false;
        return;
      }

      const params = {
        subscription_id: this.user.customer.subscriptions[0].product_id,
      };
      this.axios({
        method: 'GET',
        url: 'v1/stripe/subscription/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.product = res.product;
          this.price = res.prices.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.loader = false;
        });

    },

    adjustAddress(type) {
      if (this.user && this.user.address) {
        const addressArray = this.user.address.filter((addr) => addr.type === type);
        if (!addressArray.length) return;
        const address = addressArray[0];
        const label = type === 2
          ? `${address.city}${address.address} ${address.building || ''}`
          : `〒${address.zip} ${address.pref}${address.city}${address.address} ${address.building || ''}`;
        return {
          label,
          flag: address.flag,
        };
      }
    },

    editStart(name) {
      this.editing = name;
      this.editValue = this.user[name];
    },

    editAddressStart(name) {
      this.editing = name;
      this.editValue = this.user[name];
    },

    editCancel() {
      this.editing = null;
      this.editValue = null;
    },

    updateEditFlag(type, target) {
      if (type === 'address') {
        this.flag.editAddress[target] = !this.flag.editAddress[target];
      }
    },

    updatedAddress(target) {
      this.$store.dispatch('user/update');
      this.flag.editAddress[target] = false;
    },

    editCard() {
      this.flag.editCards = !this.flag.editCards;
    },

    updateProfile() {
      this.showLoading();
      const data = {
        id: this.user.id,
      };
      data[`${this.editing}`] = this.editValue;

      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          this.$store.dispatch('user/update');
          this.editing = null;
          this.editValue = null;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    updateUserSetting() {
      this.showLoading();
      const data = {
        id: this.user.id,
        receiveEmail: this.receiveEmail,
        district: this.district,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          this.$store.dispatch('user/update');
          alert('ユーザー設定を更新しました');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    /** 画像の更新 */
    updateFile(e) {
      this.showLoading();
      const files = e.target.files || e.dataTransfer.files;

      // files.lengthが0の場合はキャンセル時
      if (!files.length) {
        this.hideLoading();
        return;
      }

      // ファイルのサイズチェック（上限2mb）
      const limit = 1024 * 1024 * 2;
      if (files[0].size > limit) {
        alert('ファイルサイズが上限(2mb)を超えています。');
        this.hideLoading();
        return;
      }

      // ファイルを格納
      this.file = files[0];
      this.uploadFile();
    },

    /** ファイルのアップロード */
    uploadFile() {
      // 保存時formDataに落とし込む
      const form = new FormData();
      form.append('file', this.file);

      this.axios({
        method: 'POST',
        url: '/v1/user/set/uploadfile',
        data: form,
        params: {
          id: this.user.id,
          environment: this.helper.env.name,
        },
      })
        .then((response) => {
          // URLの紐付けを保存
          this.updateUrl(response.data.data);
        })
        .catch((error) => {
          alert('プロフィール画像のアップロードに失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.hideLoading();
        });
    },

    deleteUrl() {
      const data = { uploaded: { s3Path: null } };
      this.updateUrl(data);
    },

    /** URLの紐付け更新 */
    updateUrl(uploadData) {
      // 既存の紐付けがあるか新規登録か
      const id = this.user.urls.icon.length
        ? this.user.urls.icon[0].id
        : null;

      const data = {
        id,
        user_id: this.user.id,
        url: uploadData.uploaded.s3Path,
        type: 2, // アイコンは2
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/updateUrl',
        data,
      })
        .then(() => {
          // 初期化
          this.file = null;
          this.$store.dispatch('user/update');
        })
        .catch((error) => {
          alert('プロフィール画像の更新に失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    deleteDevice(deviceId) {
      const data = {
        id: deviceId,
      };
      this.axios({
        method: 'POST',
        url: '/v1/user/delete/device',
        data,
      })
        .then(() => {
          const args = {
            id: this.user.id,
          };
          this.$store.dispatch('user/update');
          this.$store.dispatch('user/login/checkDevice', args);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          console.log(error);
        });
    },

    userWithdrawal() {
      if (this.isPaid && !this.isCancelLatest) return;
      if (!confirm('この操作は取り消せません。よろしいですか？')) return;

      this.showLoading();
      const data = {
        id: this.user.id,
        flag: 999,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          this.$store.dispatch('user/update');
          alert('退会が完了しました。\nご利用いただきありがとうございました。');
          this.logout();
        })
        .catch((error) => {
          alert('退会中にエラーが発生しました。\n再度お試しください。');
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    logout() {
      this.$store.dispatch('user/login/logout', null, { root: true });
      this.$router.push({ path: '/' });
    },
  },
};
</script>

<style lang="scss" module>
.container {
  max-width: 860px;
  margin: 0 auto;
}

.title {
  font-size: 20px;
  font-weight: bold;
}
.category {
  &_wrap {
    >li {
      border-top: 1px solid var(--lightgray);
    }
  }
  display: flex;
  padding: 12px 0;

  @include sm-view {
    display: block;
  }
}
.label {
  width: 120px;
  @include sm-view {
    font-weight: bold;
    margin-bottom: 12px;
  }
}
.item {
  flex: 1;
  text-align: left;
  >li {
    border-top: none;
    &:not(:first-child) {
      margin-top: 12px;
      &.icon_wrap {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }
  }
}
.value {
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    padding: 8px;
    border-radius: 0;
    outline: none;
    border: 1px solid var(--gray);
  }
  .address {
    margin-right: auto;
    margin-left: 0;
  }
  .address_label {
    width: 80px;
  }
  >i {
    cursor: pointer;
  }
  .card {
    display: flex;
    align-items: center;
  }
  &_label {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
  }
}
.links {
  margin-top: 12px;
  li {
    border-top: none;
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
}
.cardSelectWrap {
  border: 1px solid var(--lightgray);
  border-radius: 8px;
  padding: 18px 18px 8px 18px;
}
.link {
  display: inline-block;
  padding: 8px;
  width: 100%;
  border: 1px solid var(--font-black);
  color: var(--font-black);
  position: relative;
  transition: all .3s;
  &::after {
    content: '>';
    position: absolute;
    right: 12px;
  }
  &:hover {
    opacity: .4;
  }
}

.icon {
  width: 100px;
  padding-top: 100px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  display: inline-block;
  position: relative;
  background-color: var(--lightgray);

  &_wrap {
    display: flex;
    align-items: center;
  }
}

.camera {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 40px;
  transform: translate(-50%, -50%);
  mix-blend-mode: multiply;
  opacity: .3;
}

.onlyPC {
  @include sm-view {
    display: none;
  }
}
.onlySP {
  display: none;
  @include sm-view {
    display: block;
  }
}

.btn {
  padding: 4px 12px;
  border-radius: 4px;
  margin-left: 16px;
  font-size: 12px;
  border: 1px solid var(--gray);
  cursor: pointer;
}

.historyTable {
  border-spacing: 0;
  border-bottom: 1px solid var(--lightgray);
  border-right: 1px solid var(--lightgray);
  tbody {
    tr {
      &:first-child {
        background-color: var(--lightgray);
      }
    }
  }
  td {
    border-top: 1px solid var(--lightgray);
    border-left: 1px solid var(--lightgray);
    margin: 0;
    padding: 4px 12px;
  }
}

.card_img {
  display: inline-block;
  height: 20px;
  width: calc(20px * 220 / 142);
  background-size: cover;
  border-radius: 2px;
  margin: auto 5px auto 0;
}

.bold {
  font-weight: bold;
}
@include sm-view {
  .value {
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }

  .sm_space {
    margin-top: 12px;
    margin-bottom: 8px;
  }
  .trash_days_title_space {
    margin-top: 24px;
  }
  .icon_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      margin: 24px 0;
    }
  }
  .status {
    display: inline-block;
    text-align: left;
    width: 100%;
    p:first-child {
      margin-bottom: 12px;
    }
  }
  .sm_link {
    display: inline-block;
    padding: 8px;
    width: 100%;
    border: 1px solid var(--font-black);
    color: var(--font-black);
    position: relative;
    transition: all .3s;
    &::after {
      content: '>';
      position: absolute;
      right: 12px;
    }
    &:hover {
      opacity: .4;
    }
  }
}

.address_wrap {
  padding: 18px;
  border: 1px solid var(--lightgray);
  border-radius: 8px;
}

.canceled {
  color: var(--red);
  a {
    color: var(--red);
  }
}

.taglist {
  display: flex;
  flex-wrap: wrap;

  li {
    padding: 4px 12px;
    border: 1px solid var(--gray);
    margin-top: 8px;
    border-radius: 4px;

    .xmark {
      margin-left: 4px;
      color: var(--gray);
      cursor: pointer;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.withdrawal {
  text-align: center;
  .btn {
    display: inline-block;
    &.disabled {
      border: 1px solid rgba(#000000, 0.2);
      color: rgba(#000000, 0.2);
      cursor: default;
    }
  }
}

.assistant {
  font-size: 12px;
  color: var(--red);
}
</style>
