/**
* ユーザー管理関連
*/

import moment from 'moment';
import axios from '@/plugins/axios';
import cf from '@/mixins/commonFunctions';
import login from './login';
import subscribe from './subscribe';

const actions = {
  setUserData({ commit, dispatch }, data) {
    commit('setUserData', data);
    dispatch('setStatusFlags', data);
  },


  /** DBからユーザー情報を取得しstate更新 */
  update({ commit, dispatch, state }) {
    const ls = cf.getLocalStorage('times');
    // localStorageが存在しない場合はスルー
    if (!ls) return;

    // TODO: user情報をDBから取得してアップデート

    if (state.login.isLogin && ls.jwt) {
      axios({
        method: 'GET',
        url: '/v1/user/get/detail',
        params: {
          id: state.id,
          withDevice: 1,
        },
      })
        .then((response) => {
          const user = response.data.data;
          commit('update', user);
          dispatch('setStatusFlags', user);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    }
  },

  setStatusFlags({ commit }, user) {
    const flags = {
      isPaid: false, // 支払いが有効な期間中
      isTrial: moment(user.limit_trial).isAfter(moment(new Date())), // トライアル中
      isBank: Boolean(user.role && user.role.role === 7), // 口座振替
      isUnderContract: false, // サブスクリプション契約中
      canViewContent: false, // コンテンツの閲覧可否
    };

    const adminRoles = [3, 4, 5, 6, 8, 10];
    const webPlans = ['web', 'webPaper'];

    const customer = user.customer || null;
    const subscription = customer ? customer.subscriptions[0] : null;

    // isPaid
    //  キャンセル済であってもexpireが有効であればtrue
    if (subscription) flags.isPaid = moment(subscription.expire).isAfter(moment(new Date()));

    // isUnderContract
    //  isPaidでキャンセルしていない状態
    if (subscription) flags.isUnderContract = flags.isPaid && !subscription.isCancel;

    // canViewContent
    if (user.role) flags.canViewContent = (flags.isPaid && webPlans.includes(subscription.planName)) || flags.isTrial || flags.isBank || adminRoles.includes(user.role.role);

    commit('setStatusFlags', flags);
  },
};

const mutations = {
  setUserData(state, data) {
    const keys = Object.keys(data);
    keys.some((key) => {
      // passwordはstoreで保持しない
      if (key !== 'password') {
        state[key] = data[key];
      }
      return false;
    });
  },

  update(state, user) {
    const userKeys = Object.keys(user);
    const stateKeys = Object.keys(state);
    // 取得したuserの値をまわして
    userKeys.some((key) => {
      // キーが存在するstateを更新
      if (stateKeys.includes(key)) {
        // console.log(key);
        state[key] = user[key];
      }
      return false;
    });
  },

  setStatusFlags(state, flags) {
    const stateKeys = Object.keys(state.statusFlags);
    stateKeys.some((key) => {
      state.statusFlags[key] = Boolean(flags[key]);
      return false;
    });
  },
};

const state = {
  id: '',
  statusFlags: {
    isPaid: false, // 支払いが有効な期間中
    isTrial: false, // トライアル中
    isBank: false, // 口座振替
    isUnderContract: false, // サブスクリプション契約中
    canViewContent: false, // コンテンツの閲覧可否
  },
};


export default {
  namespaced: true,
  actions,
  mutations,
  state,
  modules: {
    login,
    subscribe,
  },
};
