<template>
  <div class="transactionLaw pages">
    <div
      class="container"
      v-if="transaction"
      >
      <h2 v-html="transaction.title"></h2>
      <ul :class="description">
        <li
        v-for="(sec, i) in transaction.sections"
        v-bind:key="i"
        >
        <p v-html="sec.maintitles" :class="$style.main" v-if="sec.maintitles"></p>
        <div :class="$style.list">
          <div :class="$style.sub">
            <p v-html="sec.subtitles" ></p>
          </div>
            <p v-html="sec.description"
              :class="$style.des"
              v-if="sec.description"></p>
            <ul :class="$style.numlist">
              <li
                v-for="(num, i) in sec.numlist"
                v-bind:key="i"
              >
                <p v-html="num.title"></p>
                <p v-html="num.description"></p>
              </li>
            </ul>
        </div>

        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import transaction from '@/data/pages/transaction.yaml';

export default {
  name: 'transaction',
  data() {
    return {
      transaction,
    };
  },
};

</script>

<style lang="scss" module>
@media print, screen and (min-width: 850px) {
  .sub {
    color: black;
    width: 25%;
    background-color: #f4f4f4;
    padding: 12px 20px;
    display: table-cell;
  }
  .des {
    width: 75%;
    padding: 12px 20px;
  }

  .numlist {
    li {
      counter-increment: numcnt;
      position: relative;
      padding: 5px 30px;
      &::before {
        content: counter(numcnt) ".";
        position: absolute;
        left: 15px;
      }
    }
  }
  .main {
    font-size: 18px;
    font-weight: 1000;
    margin-top: 30px;
    border-bottom: 1px solid gray;
  }
  .list {
    display: flex;
    border-bottom: 1px solid gray;
  }
}

@media print, screen and (max-width: 850px) {
  .main {
    font-size: 18px;
    font-weight: 1000;
    margin-top: 30px;
    border-bottom:1px solid gray;
  }
  .sub {
    color: black;
    background-color: #f4f4f4;
    padding: 12px 20px;
    display: table-cell;
    width: 100vw;
    border-bottom: 1px solid gray;
  }
  .des {
    padding: 12px 20px;
  }
  .numlist {
    li {
      counter-increment: numcnt;
      position: relative;
      padding: 5px 30px;
      &::before {
        content: counter(numcnt) ".";
        position: absolute;
        left: 15px;
      }
    }
  }
  .list {
    border-bottom: 1px solid gray;

  }
}

</style>
