<template>
  <ColumnContainer>
    <MainColumn>
      <LatestBlock
        v-if="latest.high"
        :latest="latest"
        :notExist="$route.query.date && !latest.high.length && !flag.isLoadingArticle"
      />
      <Spacer :y="1"/>
      <PastBlock v-if="pastDataList.length" :past-data-list="pastDataList" />
      <button
        :class="$style.more"
        v-if="!flag.isLoadingArticle && flag.hasMoreArticles"
        @click="getMoreArticles"
      >
        もっと見る
      </button>
      <Loading v-if="flag.isLoadingArticle" />
    </MainColumn>
    <SideColumn />
  </ColumnContainer>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import Spacer from '@/components/Spacer';
import Loading from '@/components/Loading.vue';
import {
  ColumnContainer,
  MainColumn,
  SideColumn,
} from '@/components/layouts';
import LatestBlock from './LatestBlock';
import PastBlock from './PastBlock';

export default {
  name: 'Home',
  components: {
    ColumnContainer,
    MainColumn,
    SideColumn,
    Spacer,
    Loading,
    LatestBlock,
    PastBlock,
  },
  data() {
    return {
      flag: {
        hasMoreArticles: true,
        isLoadingArticle: false,
      },
      latest: {
        high: [],
        lists: [],
      },
      pastDataList: [],
      releaseDate: null,
    };
  },
  created() {
    const query = this.$route.query;
    if (query.date) this.getArticles();
    else this.getTops();
  },
  computed: {
    ...mapState(['article']),
  },
  watch: {
    $route() {
      this.getArticles();
    },
    'article.topGetFlag': {
      handler(newFlag) {
        if (!newFlag) return;
        this.getArticles();
      },
    },
  },
  methods: {
    initArticles() {
      this.latest.high = [];
      this.latest.lists = [];
      this.pastDataList = [];
      this.releaseDate = null;
      this.flag.hasMoreArticles = true;
    },
    getArticles() {
      // 既にメソッドが進行中の場合は取得しない
      if (this.flag.isLoadingArticle) return;

      this.flag.isLoadingArticle = true;
      this.initArticles();

      let targetDate;
      const query = this.$route.query;
      if (query && query.date) {
        targetDate = moment(this.$route.query.date).format('YYYY-MM-DD');
      } else {
        // dateクエリーが無ければ通常表示を行う
        this.flag.isLoadingArticle = false;
        return this.getTops();
      }

      const params = {
        flag: [1],
        limit: this.limit,
        releaseDate: targetDate,
      };
      this.axios({
        method: 'GET',
        url: '/v1/article/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          if (res && res.articles && res.articles.data.length) {
            this.setLatestArticles({
              date: targetDate,
              articles: res.articles.data,
            });
          }
          this.flag.hasMoreArticles = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        }).finally(() => {
          this.flag.isLoadingArticle = false;
          this.$store.dispatch('article/setTopGetFlag', false);
        });
    },
    setLatestArticles({ articles }) {
      this.latest.high = [articles.slice(0, 1), articles.slice(1, 9)];
      // 10以上のとき
      if (articles.length > 9) {
        const cloneArticle = cloneDeep(articles);
        const adjustArticle = cloneArticle.splice(9, cloneArticle.length);
        for (let j = 0; j < (Math.floor(adjustArticle.length / 13) + 1); j += 1) {
          const targetArray = adjustArticle.slice(0 + 13 * j, 13 + 13 * j);
          this.latest.lists.push([
            targetArray.slice(0, 2),
            targetArray.slice(2, 6),
            targetArray.slice(6, 7),
            targetArray.slice(7, 11),
            targetArray.slice(11, 13),
          ]);
        }
      }
    },
    setPastArticles({ date, articles }) {
      this.pastDataList.push({
        date: moment(date).format('YYYY年MM月DD日'),
        articles,
      });
    },

    getTops() {
      // 既にメソッドが進行中の場合は取得しない
      if (this.flag.isLoadingArticle) return;

      this.flag.isLoadingArticle = true;
      const params = {
        releaseDate: this.releaseDate,
      };
      this.axios({
        method: 'GET',
        url: '/v1/article/get/tops',
        params,
      })
        .then((response) => {
          const { result, oldest } = response.data;
          if (result) {
            if (this.releaseDate) {
              this.setPastArticles(...result);
            } else {
              this.setLatestArticles(...result);
            }
            this.releaseDate = result[0].date || null;
            this.flag.hasMoreArticles = true;
          } else if (oldest) {
            this.flag.hasMoreArticles = false;
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.isLoadingArticle = false;
          this.$store.dispatch('article/setTopGetFlag', false);
        });
    },

    getMoreArticles() {
      if (!this.flag.hasMoreArticles || this.flag.isLoadingArticle) return;
      this.flag.hasMoreArticles = false;
      this.flag.isLoadingArticle = false;
      this.releaseDate = moment(this.releaseDate).subtract(1, 'd').format('YYYY-MM-DD');
      this.getTops();
    },
  },
};
</script>

<style lang="scss" module>
.topContainer {
  display: flex;
}

.topInfo {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  width: 26.6%;
}

@include sm-view {
  .topContainer {
    display: block;
  }

  .topInfo {
    width: 100%;
  }
}

.date {
  text-align: left;
  font-weight: 900;
  font-size: 22px;
  line-height: 100%;
}

.more {
  display: block;
  margin: auto;
  width: fit-content;
  padding: 12px 38px;
  font-size: 12px;
  line-height: 1;
  color: rgba(26, 34, 61, 0.72);
  text-align: center;
  border: 1px solid var(--border-gray);
  border-radius: 18px;
  cursor: pointer;
}
</style>
