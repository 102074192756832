<template>
  <li
    @click="pushCityPage"
    :class="[$style.container]"
  >
    <div :class="[$style.title]">明日のゴミ</div>
    <div :class="$style.data" v-if="helper.master?.trash">
      <div v-for="trash in trashImage" :key="trash">
        <img
          :src="getTrashImage(trash)"
          :alt="getTrashLabel(trash)"
          :class="$style.image"
        >
        <p :class="$style.label">{{getTrashLabel(trash)}}</p>
      </div>
    </div>
    <div :class="$style.link" @click="pushAccountPage">地域の変更</div>
  </li>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'TrashInfo',
  mixins: [cf],
  data() {
    return {
      districtGroup: null,
      trashImage: [''],
    };
  },
  created() {
    if (this.helper.master.trash) {
      this.checkUserState();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putMaster') {
          this.checkUserState();
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  methods: {
    checkUserState() {
      // 非ログイン時は即読み込み
      if (!this.user.login.isLogin) this.getTrashType();

      // ログイン時は読み込み後実行
      if (this.user.email) {
        this.getTrashType();
      } else {
        this.$store.subscribe((mutation) => {
          if (mutation.type === 'user/setUserData') {
            this.getTrashType();
          }
        });
      }
    },
    pushCityPage() {
      window.open(this.helper.master.trash[this.districtGroup].link, '_blank');
    },
    pushAccountPage(e) {
      e.stopPropagation();
      if (this.user.email) this.$router.push('/account/');
      else {
        // ログインモーダルを表示
        this.openSignupModal();
      }
    },
    openSignupModal() {
      const args = {
        modalName: 'UserLimited',
        data: {
          message: '地域の設定を行うには新規会員登録またはログインが必要です。',
          path: '/account/',
        },
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
    getTrashLabel(trash) {
      return trash === '' ? '---' : this.helper.master.trash.labels[trash];
    },
    getTrashImage(trash) {
      return trash === '' ? 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' : `img/trash/${trash}.png`;
    },
    getTrashType() {
      this.trashImage = []; // init
      if (!this.helper.master.trash) return; // masterは必須

      const todayObject = new Date();
      const today = moment(todayObject).add(1, 'd').format('YYYY-MM-DD');
      const todayDayCount = {
        day: moment(today).day(), // 曜日: 0=>日曜, 6=>土曜
        count: Math.floor((moment(today).date() - 1) / 7) + 1, // 何周目か
      };
      const weekDayLabel = `${todayDayCount.count}-${todayDayCount.day}`;

      const userDistrictGroup = this.user.district ? this.helper.master.trash.mapping[this.user.district] : 'g1';
      this.districtGroup = userDistrictGroup;

      const trashMaster = this.helper.master && this.helper.master.trash ? this.helper.master.trash[userDistrictGroup] : null;
      if (!trashMaster) {
        console.log('trash type error!');
        return this.trashImage.push('error');
      }
      if (userDistrictGroup !== 'g6') {
        // g1~g5の一般地区
        let imageName = trashMaster[weekDayLabel] || 'none';
        // ペットボトル追加回収日の反映
        if (trashMaster.petAdded.includes(today)) {
          imageName = 'pet';
        }
        // 回収除外日の反映
        if (trashMaster.exclusion.includes(today)) {
          imageName = 'none';
        }
        this.trashImage.push(imageName);
      } else {
        // g6(黒保根地区)はゴミ出しが他地域より特殊
        let imageName;
        if (weekDayLabel === '1-3') {
          const exceLabelArray = trashMaster[weekDayLabel];
          Object.keys(trashMaster.specialExclusion).forEach((label) => {
            if (trashMaster.specialExclusion[label].includes(today)) {
              const index = exceLabelArray.indexOf(label);
              if (index !== -1) exceLabelArray.splice(index, 1);
            }
          });
          this.trashImage.push(...exceLabelArray);
        } else {
          imageName = trashMaster[weekDayLabel] || 'none';
          // ペットボトル追加回収日の反映(翌年度以降の改定があるかもなので念の為)
          if (trashMaster.petAdded.includes(today)) {
            imageName = 'pet';
          }
          // 回収除外日の反映
          if (trashMaster.exclusion.includes(today)) {
            imageName = 'none';
          }
          this.trashImage.push(imageName);
        }
      }
      if (!this.trashImage.length) this.trashImage.push('none');
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  width: calc((100% - var(--s)) / 2);
  aspect-ratio: 1/1;
  border: 1px solid var(--border-gray);
  cursor: pointer;
}

.title {
  font-size: 12px;
  line-height: 1.4;
  white-space: nowrap;
}

.data {
  display: flex;
  justify-content: center;
  text-align: right;
  font-weight: 900;
  font-size: 22px;
  line-height: 100%;
}

.link {
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  line-height: 1.4;
  color: #1A223D;
  text-decoration: lightgray underline;
  cursor: pointer;

  &:hover {
    color: #1A223D;
  }
}

.image {
  margin-top: 4px;
  margin-left: auto;
  margin-right: auto;
  width: 60px;
  aspect-ratio: 1/1;
}

.label {
  text-align: center;
  margin-bottom: 4px;
  font-size: 12px;
  min-width: 6.5em;
}

@include sm-view {
  .container {
    justify-content: space-between;
    align-items: center;
    padding: var(--s);
    aspect-ratio: auto;
    border-radius: 4px;
    width: auto;
    min-width: 100px;
    flex-shrink: 0;
  }

  .title {
    font-size: 11px;
  }

  .data {
    font-size: 16px;
  }

  .image {
    width: 45px;
    height: auto;
    aspect-ratio: 1/1;
  }

  .link {
    font-size: 10px;
  }
}
</style>
