<template>
  <div>
    <ArticleList>
      <ArticleListItem
        v-if="list[1].length"
        v-for="(article, index) in list[1]"
        :article="article"
        :key="article"
        :showImg="true"
        :isNarrow="list[1].length === 4"
        :index="index"
      />
      <ArticleListItem
        v-if="list[0].length"
        v-for="(article, index) in list[0]"
        :article="article"
        :key="article"
        :index="index"
      />
      <ArticleListItem
        v-if="list[3].length"
        v-for="(article, index) in list[3]"
        :article="article"
        :key="article"
        :showImg="true"
        :isNarrow="true"
        :index="index"
      />
      <ArticleListItem
        v-if="list[4].length"
        v-for="(article, index) in list[4]"
        :article="article"
        :key="article"
        :showImg="true"
        :isNarrow="true"
        :index="index"
      />
      <ArticleListItem
        v-if="list[2].length"
        v-for="(article, index) in list[2]"
        :article="article"
        :key="article"
        :index="index"
      />
    </ArticleList>
    <Spacer :y="4"/>
    <hr>
    <Spacer :y="4"/>
  </div>
</template>

<script>
import Spacer from '@/components/Spacer';
import {
  ArticleList,
  ArticleListItem,
} from '@/components/ArticleList';

export default {
  name: 'LatestArticleList',
  components: {
    Spacer,
    ArticleList,
    ArticleListItem,
  },
  props: {
    list: Array,
  },
};
</script>
