<template>
  <div :class="$style.topContainer">
    <div :class="$style.topInfo">
      <InfoArea />
    </div>
    <Spacer :x="5"/>
    <p v-if="notExist" :class="$style.notExist">
      {{ queryDate.year }}年{{ queryDate.month }}月{{ queryDate.day }}日の記事が見つかりませんでした。
    </p>
    <LatestArticleHighList
      v-if="latest.high.length"
      :articles="latest.high"
    />
  </div>
  <Spacer :y="4"/>
  <hr>
  <Spacer :y="4"/>
  <template
    v-if="latest.lists.length">
    <LatestArticleList
      v-for="(list, i) in latest.lists"
      :key="i"
      :list="list"
      :index="i"
    />
  </template>
</template>

<script>
import cf from '@/mixins/commonFunctions.js';
import Spacer from '@/components/Spacer';
import {
  LatestArticleHighList,
  LatestArticleList,
} from '@/components/ArticleList';
import InfoArea from './InfoArea';

export default {
  name: 'LatestBlock',
  components: {
    Spacer,
    InfoArea,
    LatestArticleHighList,
    LatestArticleList,
  },
  mixins: [cf],
  props: ['latest', 'notExist'],
  computed: {
    today() {
      const today = new Date();
      const object = {
        year: this.formatTimestamp(today, 'YYYY'),
        month: this.formatTimestamp(today, 'MM'),
        day: this.formatTimestamp(today, 'DD'),
      };
      return object;
    },
    queryDate() {
      const query = this.$route.query;
      if (!query.date) {
        return this.today;
      }
      const object = {
        year: this.formatTimestamp(query.date, 'YYYY'),
        month: this.formatTimestamp(query.date, 'MM'),
        day: this.formatTimestamp(query.date, 'DD'),
      };
      return object;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  width: 100%;
}

.topContainer {
  display: flex;
}

.topInfo {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  width: 26.6%;
}

@include sm-view {
  .topContainer {
    display: block;
  }

  .topInfo {
    width: 100%;
  }
}

.notExist {
  font-size: 16px;
  font-weight: bold;
}
</style>
