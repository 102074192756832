<template>
  <div>
    <spacer :y="4"/>
    <h2 :class="$style.title">パスワード変更</h2>
    <spacer :y="5"/>

    <form autocomplete="off">
      <div :class="$style.form"
        v-if="!flag.mismatch && !flag.updated"
        >
        <div
          :class="$style.input">
          <input
            id="oldPassword"
            type="password"
            name="oldPassword"
            placeholder="現在のパスワード"
            v-model.trim="$v.oldPassword.$model"
            v-bind:class="{ input_error: validationFlags.oldPassword }"
            v-on:input="invalidsCheck('oldPassword', $v.oldPassword.$invalid)">
          <span :class="$style.slideIn"/>
        </div>
        <p
          :class="$style.comment"
          v-if="$v.oldPassword.$dirty
            && $v.oldPassword.required.$invalid">パスワードを入力してください</p>

        <Spacer :y="2"/>

        <div
          :class="$style.input"
          v-if="!flag.mismatch && !flag.updated">
          <input
            id="newPassword"
            type="password"
            name="newPassword"
            placeholder="新しいパスワード"
            v-model.trim="$v.newPassword.$model"
            v-bind:class="{ input_error: validationFlags.newPassword }"
            v-on:input="invalidsCheck('newPassword', $v.newPassword.$invalid)">
          <span :class="$style.slideIn"/>
        </div>
        <p
          :class="$style.comment"
          v-if="$v.newPassword.$dirty
            && $v.newPassword.required.$invalid">パスワードを入力してください</p>
        <p
          :class="$style.comment"
          v-if="$v.newPassword.$dirty
            && $v.newPassword.minLength.$invalid">パスワードは8文字以上で設定してください</p>

        <Spacer :y="2"/>

        <div
          :class="$style.input"
          v-if="!flag.mismatch && !flag.updated">
          <input
            id="repeatPassword"
            type="password"
            name="repeatPassword"
            placeholder="新しいパスワードの確認"
            v-model.trim="$v.repeatPassword.$model"
            v-bind:class="{ input_error: validationFlags.repeatPassword }"
            v-on:input="invalidsCheck('repeatPassword', $v.repeatPassword.$invalid)">
          <span :class="$style.slideIn"/>
        </div>
        <p
          :class="$style.comment"
          v-if="$v.repeatPassword.$dirty
            && $v.repeatPassword.sameAsPassword.$invalid">新しいパスワードと同一のパスワードを入力してください。</p>
          

        <Spacer :y="6"/> 

        <div
          :class="$style.wrap"
          v-if="!flag.mismatch && !flag.updated">
          <button
            :class="$style.btn"
            @click="submit"
            v-bind:disabled="!submitFlag">変更</button>
        </div>    
      </div>

      <div
        :class="$style.wrap"
        v-if="flag.updated">
        <div :class="$style.center">
          <p
            :class="$style.comment">パスワードを更新しました。<br>次回から新しいログインパスワードをご使用ください。</p>
          <Spacer :y="4"/>
          <div
            v-if="user.login.isLogin">
            <router-link
              :class="$style.btn"
              to="/account/">マイページ</router-link>
          </div>
        </div>
      </div>

      <div
        :class="$style.wrap"
        v-if="flag.mismatch">
        <p
          :class="$style.comment">現在のパスワードが一致しないため更新できません。<br>パスワードをお忘れに場合は
            <router-link
              class="link"
              to="/forgot-password/">こちら</router-link>
            からパスワードリセットを行ってください。
        </p>
      </div>

    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, sameAs } from '@vuelidate/validators';
import Spacer from '@/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'edit-password',
  mixins: [cf],
  components: {
    Spacer,
  },
  data() {
    return {
      validationFlags: {
        oldPassword: false,
        newPassword: false,
        repeatPassword: false,
      },
      // 無効フラグ
      invalids: {
        oldPassword: true,
        newPassword: true,
        repeatPassword: true,
      },
      flag: {
        updated: false,
        mismatch: false,
      },
    };
  },
  setup() {
    const oldPassword = ref('');
    const newPassword = ref('');
    const repeatPassword = ref('');

    const rules = {
      // パスワードは必須で8文字以上
      oldPassword: {
        required,
      },
      newPassword: {
        required,
        minLength: minLength(8),
      },
      repeatPassword: {
        sameAsPassword: sameAs(newPassword),
      },
    };

    const $v = useVuelidate(rules, {
      oldPassword,
      newPassword,
      repeatPassword,
    });

    return {
      oldPassword,
      newPassword,
      repeatPassword,
      $v,
    };
  },

  created() {
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.oldPassword
        && !this.invalids.newPassword
        && !this.invalids.repeatPassword
        && !this.flag.updated;
    },
  },
  methods: {
    ...mapActions('user/login', [
      'logout',
    ]),

    invalidsCheck(name, bool) {
      this.flag.mismatch = false;
      this.invalids[name] = bool;
    },

    submit(e) {
      e.preventDefault();
      // validateチェックは入力時に行われてる
      const data = {
        id: this.user.id,
        email: this.user.email,
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/updatePassword',
        data,
      })
        .then((response) => {
          if (response.data.token) {
            // tokenをlocalStorageに保存
            cf.saveLocalStorage({
              jwt: response.data.token,
            }, 'rt');
            this.$store.dispatch('user/login/authCheck', {
              type: 'check',
              jwt: response.data.token,
            });
          }
          if (response.data.updated) this.flag.updated = true;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.status === 401
              && error.response.data.detail.includes('MISMATCH')) {
              this.flag.mismatch = true;
            }
          } else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  display: flex;
  justify-content: center;
}
.logo {
  margin: 0 auto;
  width: 50%;
  max-width: 300px;
}
.title {
  text-align: center;
  color: var(--orange-main);
}
.center {
  text-align: center;
}
.form {
  text-align: center;
}
.input {
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
  position: relative;
  input {
    width: 100%;
    padding: 13px;
    border: none;
    background-color: var(--gray-sub);
    outline: none;
    border-bottom: 1px solid var(--gray);

    &:focus + .slideIn {
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(#e2e2e2, .4);
      mix-blend-mode: multiply;
      position: absolute;
      animation: split .3s;
    }

    @keyframes split {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }
}
.comment {
  font-size: 12px;
  color: var(--pink);
}
.message {
  font-size: 12px;
  text-align: center;
}
.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
}
.forgot {
  font-size: 14px;
  text-decoration: underline;
  a {
    color: var(--orange-main);
  }
}
// ログイン失敗時
.vibration {
  animation: vibration .1s  infinite;
}
@keyframes vibration {
  0% {transform: translate(0px, 0px) rotateZ(0deg)}
  25% {transform: translate(2px, 2px) rotateZ(1deg)}
  50% {transform: translate(0px, 2px) rotateZ(0deg)}
  75% {transform: translate(2px, 0px) rotateZ(-1deg)}
  100% {transform: translate(0px, 0px) rotateZ(0deg)}
}
</style>
