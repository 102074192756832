<template>
  <div :class="$style.container">
    <SearchParts />
    <Spacer :y="9"/>
    <div v-if="!existsKeyword" :class="$style.noResult">
      <p>キーワードを入力して検索してください。</p>
    </div>
    <p
      v-if="!isLoading && existsKeyword"
      :class="$style.total"
    >{{ paper.total }}紙面</p>
    <Spacer :y="2.5"/>
    <div v-if="!isLoading && existsKeyword && paper.papers.length">
      <ImageScroller
        :images="paper.papers"
        :isBlur="!allowReadPaidContent"
        v-on:selectIndex="changePage" />
      <viewer
        v-if="paperIndex !== null"
        :images="paper.papers"
        :showIdx="paperIndex"
        :showPage="false"
        :isPaper="true"
        v-on:changePage="changePage" />
    </div>
    <div v-if="!isLoading && existsKeyword && !paper.total" :class="$style.noResult">
      <p>「{{keyword}}」に該当する紙面はありませんでした</p>
    </div>

    <Spacer :y="4"/>

    <p
      v-if="!isLoading && existsKeyword"
      :class="$style.total"
    >{{ total }}記事</p>
    <Spacer :y="2.5"/>
    <ContentHeight
      ref='ContentHeight'
    >
      <ArticleSimpleList v-if="total">
        <ArticleSimpleListItem
          v-for="article in articles"
          :key="article.id"
          :article="article"/>
      </ArticleSimpleList>
      <div v-if="!isLoading && existsKeyword && !total" :class="$style.noResult">
        <p>「{{keyword}}」の検索結果はありませんでした</p>
      </div>
      <Spacer :y="2.5"/>
      <Loading v-if="isLoading && existsKeyword" />
    </ContentHeight>
    <button
      :class="$style.more"
      v-if="!isLoading && hasMoreArticles"
      @click="getMoreArticles"
    >
      もっと見る
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import SearchParts from '@/components/layouts/GlobalHeader/SearchParts.vue';
import ArticleSimpleList from '@/components/ArticleList/ArticleSimpleList.vue';
import ArticleSimpleListItem from '@/components/ArticleList/ArticleSimpleListItem.vue';
import Spacer from '@/components/Spacer.vue';
import Loading from '@/components/Loading.vue';
import ContentHeight from '@/components/ContentHeight';
import ImageScroller from '@/components/ImageScroller';
import Viewer from '@/components/Viewer/Viewer.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'search',
  mixins: [cf],
  components: {
    SearchParts,
    ArticleSimpleList,
    ArticleSimpleListItem,
    Spacer,
    Loading,
    ContentHeight,
    ImageScroller,
    Viewer,
  },
  data() {
    return {
      articles: [],
      total: 0,
      currentPage: 1,
      limit: 10,
      paper: {
        papers: [],
        total: 0,
      },
      keyword: null,
      isLoading: true,
      existsKeyword: true,
      hasMoreArticles: false,
      paperIndex: null,
      webPlanPriceIds: [],
    };
  },
  created() {
    this.init();
    const keyword = this.$route.query.keyword;
    if (keyword) {
      this.existsKeyword = true;
      this.getArticles();
      this.getPapers();
    } else {
      this.existsKeyword = false;
    }

    if (this.helper?.prices?.web) {
      this.getWebPlanPriceIds();
    } else {
      this.$store.subscribe((mutations) => {
        if (mutations.type === 'helper/putPrices') {
          this.getWebPlanPriceIds();
        }
      });
    }
  },
  computed: {
    ...mapState(['user']),
    isPaid() {
      return Boolean((this.user.role && this.user.role.role === 7)
        || (this.user.email
        && this.user.customer
        && this.user.customer.subscriptions.length
        && this.user.customer.subscriptions[0].expire
        && moment(new Date()).isBefore(moment(this.user.customer.subscriptions[0].expire))));
    },
    oldPriceIds() {
      if (this.helper && this.helper.master) return this.helper.master.products.old_price_type.map((row) => row.id);
      return [];
    },
    isWebPlan() {
      return Boolean(this.user.email
        && this.user.customer
        && this.user.customer.subscriptions.length
        && this.user.customer.subscriptions[0].expire
        && this.webPlanPriceIds.length
        && (this.webPlanPriceIds.includes(this.user.customer.subscriptions[0].price_id)
          || this.oldPriceIds.includes(this.user.customer.subscriptions[0].price_id)
        ));
    },
    isTrial() {
      return Boolean(this.user.email
        && this.user.limit_trial
        && moment(new Date()).isBefore(moment(this.user.limit_trial), 'day'));
    },
    allowReadPaidContent() {
      // 特例対応(会員登録だけで有料コンテンツを確認できるかどうか)
      const specialFlag = 1;
      if (!specialFlag) { // デフォルトの判定
        return (this.isPaid && this.isWebPlan) || this.isTrial;
      }
      return Boolean(this.user.email);
    },
  },
  watch: {
    $route() {
      this.init();
      const keyword = this.$route.query.keyword;
      if (keyword) {
        this.existsKeyword = true;
        this.getArticles();
        this.getPapers();
      } else {
        this.existsKeyword = false;
      }
    },
  },
  methods: {
    getWebPlanPriceIds() {
      this.isLoading = true;
      if (!this.helper?.prices?.web) {
        this.webPlanPriceIds = [];
        return;
      }
      const webPlanId = this.helper.prices.web.id;
      const webPaperPlanId = this.helper.prices.webPaper.id;
      this.webPlanPriceIds = [webPlanId, webPaperPlanId];
      this.isLoading = false;
    },
    changePage(index) {
      if (this.allowReadPaidContent) {
        this.paperIndex = index;
      } else {
        this.openSignupModal();
      }
    },

    getPapers() {
      this.isLoading = true;
      this.keyword = this.$route.query.keyword;
      let keyword = this.keyword;
      keyword = keyword.split(/(?:\s|\u3000)+/);
      const newKeywords = {};
      keyword.forEach((words, i) => {
        const extractedKanji = cf.extractKanji([words], 1);
        newKeywords[`keyword_${i + 1}`] = extractedKanji;
      });
      const params = {
        keywords: newKeywords,
        acceptRatio: 0.7,
      };
      this.axios({
        method: 'GET',
        url: '/v1/paper/get/search',
        params,
      })
        .then((response) => {
          const res = response.data.list;
          const paperData = this.paper;
          paperData.total = res.papers.length;
          paperData.papers.push(...res.papers);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        }).finally(() => {
          this.isLoading = false;
        });
    },

    getArticles() {
      this.isLoading = true;
      this.keyword = this.$route.query.keyword;
      const params = {
        flag: [1],
        limit: this.limit,
        page: this.currentPage,
        keyword: this.keyword ? this.keyword.split(/(?:\s|\u3000)+/) : [],
        targetColumn: ['title', 'description', 'content', 'info'],
      };
      this.axios({
        method: 'GET',
        url: '/v1/article/get/list',
        params,
      })
        .then((response) => {
          this.total = response.data.articles.total;
          this.hasMoreArticles = !!response.data.articles.lastPage && !(response.data.articles.page === response.data.articles.lastPage);
          this.articles.push(...response.data.articles.data);
          if (this.$route.path.includes('search')) this.setMetas();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        }).finally(() => {
          this.isLoading = false;
        });
    },
    getMoreArticles() {
      this.currentPage += 1;
      this.hasMoreArticles = false;
      this.getArticles();
    },
    init() {
      this.total = 0;
      this.hasMoreArticles = false;
      this.currentPage = 1;
      this.articles = [];
      this.paper.total = 0;
      this.paper.papers = [];
    },
    setMetas() {
      const defaults = {
        title: '桐生タイムス',
      };

      const meta = {};

      meta.title = `"${this.keyword}"の検索結果 | ${defaults.title}`;
      meta.og_url = `${location.protocol}//${location.host}${location.pathname}`;
      meta.type = 'article';

      // meta sets
      document.title = meta.title;
      document.querySelector("meta[property='og:title']").setAttribute('content', meta.title);
      document.querySelector("meta[property='og:type']").setAttribute('content', meta.type);
      document.querySelector("meta[property='og:url']").setAttribute('content', meta.og_url);
    },
    openSignupModal() {
      const args = {
        modalName: 'UserLimited',
        data: {
          query: {
            keyword: this.$route.query.keyword,
          },
        },
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.ContentHeight.updateHeight();
    });
  },
};
</script>

<style lang="scss" module>
.container {
  max-width: 720px;
  width: 100%;
  margin: auto;
}

.total {
  font-size: 16px;
}

.noResult {
  display: flex;
  align-items: center;
  justify-content: center;
}

.more {
  display: block;
  margin: auto;
  width: fit-content;
  padding: 12px 38px;
  font-size: 12px;
  line-height: 1;
  color: rgba(26, 34, 61, 0.72);
  text-align: center;
  border: 1px solid var(--border-gray);
  border-radius: 18px;
  cursor: pointer;
}
</style>
