<template>
  <div :class="$style.wrapper">
    <AlertMessage />
    <GlobalHeader />
    <Spacer :y="7" :sm-y="4"/>
    <div :class="$style.contents">
      <router-view />
    </div>
    <Spacer :y="12"/>
    <GlobalFooter />
    <LoadingsModal />
    <ContentsModal />
    <MessagesModal />
    <template v-if="!isLgView">
      <DrawerMenu />
      <div :class="overlayClass" @click="closeMenu" />
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import { throttle } from 'lodash';
import {
  GlobalHeader,
  GlobalFooter,
  DrawerMenu,
  AlertMessage,
} from '@/components/layouts';
import Spacer from '@/components/Spacer';
import { ContentsModal, MessagesModal, LoadingsModal } from '@/components/modal';

export default {
  name: 'app',
  components: {
    GlobalHeader,
    GlobalFooter,
    Spacer,
    DrawerMenu,
    AlertMessage,
    ContentsModal,
    MessagesModal,
    LoadingsModal,
  },
  computed: {
    ...mapState(['page', 'view', 'user', 'helper']),
    isModal() {
      const contents = this.$store.state.modal.contents.modalName !== '';
      const loadings = this.$store.state.modal.loadings.modalName !== '';
      const messages = this.$store.state.modal.messages.modalName !== '';
      return contents || loadings || messages;
    },
    isLgView() {
      return ['lg'].includes(this.view.display_size);
    },
    overlayClass() {
      return `${this.$style.drawerMenuOverlay} ${this.view.show_menu ? this.$style.isOpen : ''}`;
    },
    isPaid() {
      return Boolean(this.user.email
        && this.user.customer
        && this.user.customer.subscriptions.length
        && this.user.customer.subscriptions[0].expire
        && moment(new Date()).isBefore(moment(this.user.customer.subscriptions[0].expire)));
    },
  },
  created() {
    // ログインチェック
    this.$store.dispatch('user/login/check');
    // backend共通マスターデータ取得
    this.$store.dispatch('helper/getMaster');
    this.setDisplaySize(window.innerWidth);
    this.innerWidth = window.innerWidth;
    window.addEventListener('resize', throttle(() => this.setDisplaySize(window.innerWidth), 100));
    this.getCategories();

    // user情報を確認してAdsenseのタグを埋め込み
    if (this.user.email) {
      this.setAdSenseScriptTag();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.setAdSenseScriptTag();
        }
      });
      // 非ログインユーザ
      if (this.user.login.isChecked && !this.user.login.isLogin) this.setAdSenseScriptTag();
    }
  },
  watch: {
    $route(to) {
      // メタ情報の仕込み
      this.setMetas(to);
      this.closeMenu();
    },
  },
  methods: {
    ...mapActions('view', ['setDisplaySize', 'closeMenu']),
    ...mapActions('category', ['setCategories']),

    /** 無料会員にadsenseのscriptを埋め込み */
    setAdSenseScriptTag() {
      const isSpecial = this.user.role && [3, 4, 5, 6, 7, 8].includes(this.user.role.role); // v2・開発・ゲスト のいずれかの権限の場合には閲覧可能
      if (!this.isPaid && !isSpecial) {
        const scriptTag = document.createElement('script');
        scriptTag.id = 'g-adsense';
        scriptTag.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4866689451938023';
        scriptTag.async = 'true';
        scriptTag.crossorigin = 'anonymous';
        document.head.appendChild(scriptTag);
      }
    },

    setMetas: (route) => {
      const meta = {};
      // 個別設定がない場合のデフォルト設定
      const defaults = {
        title: '桐生タイムス',
        description: '群馬県東部の桐生・みどり両市を拠点とする新聞社。日刊の夕刊紙「桐生タイムス」のほか週刊のフリーペーパー「タウンわたらせ」「週刊渡良瀬通信」を発行しています。',
        og_image: 'https://kiryutimes.s3.ap-northeast-1.amazonaws.com/production/OGP/ogp.png',
      };

      meta.name = route.name;
      meta.title = route.meta.title ? `${route.meta.title} | ${defaults.title}` : defaults.title;
      meta.description = route.meta.description || defaults.description;

      // meta sets
      document.title = meta.title;
      document.querySelector("meta[name='description']").setAttribute('content', meta.description);

      // canonicalタグの動的削除
      const canonical = document.querySelector("link[rel='canonical']");
      // 存在する場合はリセットのため削除
      if (canonical) canonical.remove();
    },
    getCategories() {
      const params = {
        sortParents: 1,
      };
      this.axios({
        method: 'GET',
        url: '/v1/category/get/list',
        params,
      })
        .then((response) => {
          this.setCategories(response.data.categories.data);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss">
/**
 * ここでimportしているscssは
 * 全てのページに適用されます
 */
@import './assets/scss/_variables.scss';
@import './assets/scss/_keyframes.scss';
@import './assets/scss/fonts.scss';
@import './assets/scss/modal.scss';
@import './assets/scss/style.scss';
</style>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  max-width: 100%;
  overflow: hidden;
}

.contents {
  flex-shrink: 1;
  flex-grow: 10;
  padding: 0 var(--g);
}

.drawerMenuOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: 99;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.1);
  transition: opacity 500ms ease-in-out;

  &.isOpen {
    opacity: 1;
    pointer-events: auto;
  }
}
</style>
