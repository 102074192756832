<template>
  <ColumnContainer>
    <MainColumn>
      <template v-if="existTaxonomy">
        <div :class="$style.topContainer">
          <div :class="$style.topInfo">
            <InfoArea :taxonomy="taxonomy"/>
          </div>
          <Spacer :x="5"/>
          <div :class="$style.topArticleList">
            <ArticleList v-if="articles.length">
              <ArticleListItem
                v-for="(article, i) in articles"
                :article="article"
                :key="article.id"
                :isWide="i === 0"
                :index="i"
              />
            </ArticleList>
            <Loading v-if="flag.isArticleLoading" />
            <ErrorMessage
              v-if="!articles.length && !flag.isArticleLoading"
              :class="$style.notFoundMessege"
            >
              {{notFoundMessegeForAricle}}
            </ErrorMessage>
            <Spacer :y="8"/>
            <InviewTarget v-if="useAutoLoad" :once="page > 2" @inview="getMoreArticles"/>
            <button
              :class="$style.more"
              v-if="!useAutoLoad && !flag.isLoadingArticle && flag.hasMoreArticles"
              @click="getMoreArticles"
            >
              もっと見る
            </button>
          </div>
        </div>
      </template>
      <template v-if="!existTaxonomy && !flag.isTaxonomyLoading">
        <ErrorMessage
          v-if="!articles.length"
          :class="$style.notFoundMessege"
        >
          {{notFoundMessegeForTaxonomy}}
        </ErrorMessage>
      </template>
      <Loading v-if="flag.isTaxonomyLoading" />
      <Spacer :y="4"/>
      <hr>
    </MainColumn>
    <SideColumn />
  </ColumnContainer>
</template>

<script>
import { mapState } from 'vuex';
import Spacer from '@/components/Spacer';
import ErrorMessage from '@/components/ErrorMessage';
import Loading from '@/components/Loading';
import InviewTarget from '@/components/InviewTarget';
import {
  ColumnContainer,
  MainColumn,
  SideColumn,
} from '@/components/layouts';
import {
  ArticleList,
  ArticleListItem,
} from '@/components/ArticleList';
import cf from '@/mixins/commonFunctions.js';
import InfoArea from './InfoArea';

export default {
  name: 'Home',
  components: {
    ColumnContainer,
    MainColumn,
    SideColumn,
    Spacer,
    InfoArea,
    ArticleList,
    ArticleListItem,
    ErrorMessage,
    Loading,
    InviewTarget,
  },
  mixins: [cf],
  data() {
    return {
      flag: {
        isTaxonomyLoading: true,
        isArticleLoading: true,
        hasMoreArticles: false,
      },
      taxonomy: {},
      currentPage: 1,
      ArticlePerPage: 10,
      articles: [],
      sortBy: '',
      slug: '',
    };
  },
  mounted() {
    // category/:slugにアクセス && Storeにcategoriesが入ってない場合はwatch
    if (this.$route.path.includes('category') && !(this.categories.length > 1)) {
      this.$store.watch(
        (state) => state.category.categories,
        () => {
          this.startLoad(this.$route);
        },
      );
      return;
    }
    this.startLoad(this.$route);
  },
  computed: {
    ...mapState('category', ['categories']),
    existTaxonomy() {
      return this.taxonomy ? Object.keys(this.taxonomy).length > 0 : false;
    },
    notFoundMessegeForTaxonomy() {
      return `${this.sortBy === 'tag' ? 'タグ' : 'カテゴリー'}「${decodeURIComponent(this.slug)}」は見つかりませんでした`;
    },
    notFoundMessegeForAricle() {
      return `${this.sortBy === 'tag' ? 'タグ' : 'カテゴリー'}「${decodeURIComponent(this.slug)}」に該当する記事は見つかりませんでした`;
    },
    useAutoLoad() {
      return this.currentPage < 3;
    },
    offset() {
      return this.currentPage > 1 ? 1 + (this.currentPage - 1) * this.ArticlePerPage : 0;
    },
    limit() {
      return this.currentPage > 1 ? this.ArticlePerPage : this.ArticlePerPage + 1;
    },
  },
  watch: {
    $route(to) {
      this.startLoad(to);
    },
  },
  methods: {
    getMoreArticles() {
      if (this.flag.isArticleLoading || !this.flag.hasMoreArticles) return;
      this.currentPage += 1;
      this.getArticles();
      this.flag.isArticleLoading = true;
    },
    checkMoreArticles(articles) {
      this.flag.hasMoreArticles = articles?.length > this.limit;
    },
    setArticles(articles) {
      // 更に記事があるかチェックするために表示する件数(limit)より1件多く記事を取得しています
      // そのため表示件数より多く取得できた場合は1件減らす必要があるためここで処理しています
      if (articles?.length > this.limit) articles.splice(-1, 1);
      if (this.currentPage === 1) this.articles = articles;
      else this.articles.push(...articles);
      this.flag.isArticleLoading = false;
    },
    startLoad(routing) {
      this.initData();
      if (!routing.params.slug) {
        this.$router.push('/');
        return;
      }

      this.sortBy = routing.path.includes('category') ? 'category' : 'tag';
      try {
        this.slug = encodeURIComponent(routing.params.slug);
      } catch (err) {
        this.slug = routing.params.slug;
      }

      if (this.categories.length > 2) {
        this.getTaxonomy();
      } else {
        this.$store.subscribe((mutations) => {
          if (mutations.type === 'category/putCategories') {
            this.getTaxonomy();
          }
        });
      }
    },
    initData() {
      this.flag = {
        isTaxonomyLoading: true,
        isArticleLoading: true,
        hasMoreArticles: false,
      };
      this.currentPage = 1;
      this.taxonomy = {};
      this.articles = [];
    },
    getTaxonomy() {
      // categoryの場合はStoreのデータ参照
      if (this.sortBy === 'category') {
        this.taxonomy = this.categories.filter((category) => (
          // 親カテゴリに該当するものがないかチェック
          category.name === this.slug || this.slug === 'popular'))?.[0]
          || this.categories.filter((category) => (
            category.name === this.slug || this.slug === 'recommends'))?.[1]
          // 子カテゴリーにslugに該当するものがあるかチェック
          || this.categories.flatMap((category) => category?.children).filter((child) => child?.name === this.slug)?.[0]
          // カテゴリーにslugに該当するものがなければ空Objectを返す
          || {};
        this.flag.isTaxonomyLoading = false;
        this.setMetas();
        this.getArticles();
        return;
      }
      this.axios({
        method: 'GET',
        url: '/v1/tag/get/detail',
        params: {
          slug: this.slug,
        },
      })
        .then((response) => {
          this.taxonomy = response.data.detail;
          this.flag.isTaxonomyLoading = false;
          this.setMetas();
          this.getArticles();
        })
        .catch((error) => {
          this.taxonomy = {};
          this.flag.isTaxonomyLoading = false;
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    getArticles() {
      // recommendの場合は別API叩く
      if (this.slug === 'recommend') {
        this.getRecommends();
        return;
      }
      if (this.slug === 'popular') {
        this.getPopular();
        return;
      }

      const sortTarget = this.sortBy === 'category' ? [] : this.slug;
      if (this.sortBy === 'category' && this.taxonomy) {
        sortTarget.push(this.taxonomy.id);
        if (this.taxonomy.children?.length) {
          sortTarget.push(...this.taxonomy.children.map((category) => category.id));
        }
      }

      const params = {
        flag: [1],
        sort: {
          [this.sortBy === 'category' ? 'categories' : 'tags']: sortTarget,
        },
        offset: this.offset,
        // 更に記事があるかチェックするために表示する件数(limit)より1件多く記事を取得しています
        limit: this.limit + 1,
      };
      this.axios({
        method: 'GET',
        url: '/v1/article/get/list',
        params,
      })
        .then((response) => {
          this.completeLoadArticles(response.data.articles);
        })
        .catch((error) => {
          this.flag.isArticleLoading = false;
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    getPopular() {
      const params = {
        flag: [1],
        offset: this.offset,
        limit: this.limit + 1,
        releaseDatePeriod: [1, 'w'],
      };
      this.axios({
        method: 'GET',
        url: '/v1/article/get/popular',
        params,
      })
        .then((response) => {
          this.completeLoadArticles(response.data.popular);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    getRecommends() {
      const params = {
        flag: [1],
        offset: this.offset,
        // 更に記事があるかチェックするために表示する件数(limit)より1件多く記事を取得しています
        limit: this.limit + 1,
      };
      this.axios({
        method: 'GET',
        url: '/v1/article/get/recommends',
        params,
      })
        .then((response) => {
          this.completeLoadArticles(response.data.recommends);
        })
        .catch((error) => {
          this.flag.isArticleLoading = false;
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    completeLoadArticles(articles) {
      this.checkMoreArticles(articles);
      this.setArticles(articles);
    },

    setMetas() {
      const defaults = {
        title: '桐生タイムス',
        description: '群馬県東部の桐生・みどり両市を拠点とする新聞社。日刊の夕刊紙「桐生タイムス」のほか週刊のフリーペーパー「タウンわたらせ」「週刊渡良瀬通信」を発行しています。',
        og_image: 'https://kiryutimes.s3.ap-northeast-1.amazonaws.com/production/OGP/ogp.png',
      };

      if (!this.taxonomy) return;
      const taxonomy = cf.methods.getClone(this.taxonomy);
      const meta = {};

      meta.title = `${taxonomy.label} | ${defaults.title}`;
      meta.description = taxonomy.og_description || defaults.description;
      meta.og_image = taxonomy.og_img || defaults.og_image;
      const split = location.pathname.split('/');
      const trailingSlash = split[split.length - 1] !== '' ? '/' : '';
      meta.og_url = `${location.protocol}//${location.host}${location.pathname}${trailingSlash}`;
      meta.type = 'article';

      // meta sets
      document.title = meta.title;
      document.querySelector("meta[name='description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:title']").setAttribute('content', meta.title);
      document.querySelector("meta[property='og:description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:type']").setAttribute('content', meta.type);
      document.querySelector("meta[property='og:image']").setAttribute('content', meta.og_image);
      document.querySelector("meta[property='og:url']").setAttribute('content', meta.og_url);

      // canonicalタグの動的生成
      const canonical = document.querySelector("link[rel='canonical']");
      if (canonical) {
        // 存在する場合は書き換え
        canonical.href = meta.og_url;
      } else {
        // 存在しない場合は生成
        const linkTag = document.createElement('link');
        linkTag.setAttribute('rel', 'canonical');
        linkTag.href = meta.og_url;
        document.head.appendChild(linkTag);
      }
    },
  },
};
</script>

<style lang="scss" module>
.container {
  width: 100%;
}

.topContainer {
  display: flex;
}

.topInfo {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  width: 26.6%;
}

.topArticleList {
  display: block;
  width: 100%;
}

.more {
  display: block;
  margin: auto;
  width: fit-content;
  padding: 12px 38px;
  font-size: 12px;
  line-height: 1;
  color: rgba(26, 34, 61, 0.72);
  text-align: center;
  border: 1px solid var(--border-gray);
  border-radius: 18px;
  cursor: pointer;
}

@include sm-view {
  .topContainer {
    display: block;
  }

  .topInfo {
    width: 100%;
  }
}
</style>
