<template>
  <div :class="$style.topArticleList">
    <template v-if="hasThumbFirstArticle || ['lg', 'md'].includes(view.display_size)">
      <TopArticleItem
        :article="articles?.[0]?.[0]"
      />
      <Spacer :y="3"/>
      <hr>
      <Spacer :y="3"/>
      <ArticleList v-if="articles?.[1]?.length">
        <ArticleListItem
          v-for="(article, index) in articles[1]"
          :article="article"
          :key="article.id"
          :index="index"
        />
      </ArticleList>
    </template>
    <template v-else>
      <ArticleList v-if="articleList?.length">
        <ArticleListItem
          v-for="article in articleList"
          :article="article"
          :key="article.id"
        />
      </ArticleList>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { throttle } from 'lodash';
import Spacer from '@/components/Spacer';
import {
  ArticleList,
  ArticleListItem,
  TopArticleItem,
} from '@/components/ArticleList';

export default {
  name: 'LatestArticleHighList',
  components: {
    Spacer,
    ArticleList,
    ArticleListItem,
    TopArticleItem,
  },
  props: {
    articles: Array,
  },
  computed: {
    ...mapState(['view']),
    articleList() {
      return this.articles?.flatMap((articles) => articles);
    },
    hasThumbFirstArticle() {
      return !!this.articles?.[0]?.[0]?.urls?.thumbnail?.[0]?.image?.url;
    },
  },
  created() {
    this.setDisplaySize(window.innerWidth);
    window.addEventListener('resize', throttle(() => this.setDisplaySize(window.innerWidth), 100));
  },
  methods: {
    ...mapActions('view', ['setDisplaySize']),
  },
};
</script>

<style lang="scss" module>
.topArticleList {
  display: block;
  width: 100%;
}

.col {
  position: relative;
  display: block;
  width: 100%;
}

.link {
  position: relative;
  display: flex;
  gap: calc(var(--s) * 2.5);

  @include sm-view {
    padding-bottom: 95px;
  }
}

.inner {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: calc(var(--s) * 1.5);

  @include sm-view {
    position: absolute;
    bottom: 0;
    left: calc(var(--g) * -1);
    padding: calc(var(--s) * 3) calc(var(--g)) 0;
    z-index: 2;
    width: 94.855305%;
    max-width: 500px;
    background-color: #fff;
  }
}

.title {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 700;
  color: var(--font-black);

  @include sm-view {
    font-size: 20px;
  }
}

.description {
  font-size: 14px;
  font-weight: 400;
  color: var(--font-gray);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;

  @include sm-view {
    -webkit-line-clamp: 2;
    height: 51px;
  }
}

.meta {
  display: flex;
  align-items: center;
  gap: calc(var(--s) * 1.5);
}


.isNew {
  display: block;
  width: fit-content;
  padding: 4px 11px;
  border-radius: 2px;
  background-color: var(--accent);
  color: #fff;
  font-weight: 900;
  font-size: 10px;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 0.04em;
}

.createdAt {
  font-size: 12px;
  line-height: 1;
  color: var(--font-gray);
}

.thumbnail {
  @include sm-view {
    position: relative;
    z-index: 1;
    margin: 0 calc(50% - 50vw);
    max-width: 100vw;
    width: 100vw;
  }
}
</style>
