<template>
  <div :class="$style.images_wrap" id="wrap">
    <ul :class="imagesClass()" id="images">
      <li
        v-for="(image, i) in images"
        :key="image"
        :class="[$style.imageItem, isBlur ? $style.blur : '']"
        @click="handleClick(i)">
        <div :class="$style.imgBlocker">
          <img
            :src="image.url ? image.url : '/img/logos/no-image.png'"
            alt=""
            :class="$style.rowImage"
            oncontextmenu="return false;">
        </div>
      </li>
    </ul>
    <div
      :class="$style.left"
      v-on:click="scroll('left')"
      v-if="flag.showArrow"><i class="fa-solid fa-angle-left"/></div>
    <div
      :class="$style.right"
      v-on:click="scroll('right')"
      v-if="flag.showArrow"><i class="fa-solid fa-angle-right"/></div>
  </div>
</template>

<script>

export default {
  name: 'ImageScroller',
  props: {
    images: {
      type: Object,
      required: true,
    },
    isBlur: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      flag: {
        showArrow: false,
      },
    };
  },
  created() {
  },
  mounted() {
    this.checkTabWidth();
    addEventListener('resize', this.checkTabWidth);
  },
  unmounted() {
    removeEventListener('resize', this.checkTabWidth);
  },
  methods: {
    handleClick(index) {
      this.$emit('selectIndex', index);
    },
    imagesClass() {
      let className = this.$style.images;
      if (this.flag.showArrow) className += ` ${this.$style.arrow}`;
      return className;
    },
    checkTabWidth() {
      // タブが全て表示される場合には矢印非表示
      this.flag.showArrow = false;
      const wrap = document.getElementById('wrap');
      const images = document.getElementById('images');
      if (wrap && images) {
        const wrapW = wrap.clientWidth;
        const imagesW = images.scrollWidth;
        if (wrapW < imagesW) this.flag.showArrow = true;
      } else {
        this.$router.go({ path: this.$router.currentRoute.path, force: true });
      }
    },
    scroll(direction) {
      const images = document.getElementById('images');
      const imagesW = images.scrollWidth;
      const viewW = images.clientWidth;
      const positionX = images.scrollLeft;
      const maxX = imagesW - viewW;
      const remain = positionX % (viewW / 4);
      let X = direction === 'right' ? viewW : viewW * -1;
      if (positionX + X > maxX && direction === 'right') X = maxX - positionX;
      if (positionX < maxX && direction === 'left') X = positionX * -1;
      if (remain && direction === 'right') X -= remain;

      images.scrollLeft += X;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: block;
  width: 100%;
  padding: 0 16px;

  @include sm-view {
    display: none;
  }
}

.images {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 4px;
  padding-top: 4px;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  &_wrap {
    position: relative;
    z-index: 100;
  }
  &.arrow {
    margin: 0 30px;
  }
    li {
      width: calc(25%);
      padding: .5em;
      margin-left: var(--margin-left);
      cursor: pointer;
    }
}

.imageItem {
  position: relative;
  white-space: nowrap;
  flex-shrink: 0;
  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 7px;
    border-radius: 4px;
    background-color: transparent;
    transform: translate3d(0, 3px, 0);
    transition: all 250ms ease-in-out;
  }
  &.isActive::after {
    background-color: var(--secondary-color);
    transform: translate3d(0, 0, 0);
  }
}

.image {
  padding: 10px 1px;
  font-size: 20px;
  color: #696969;
  appearance: none;
  border: none;
  background-color: transparent;
  box-shadow: none;
  transition: all 250ms ease-in-out;
  .tabItem:not(.isActive) &:hover {
    cursor: pointer;
    background-color: #e9e9e9;
  }
}

.right, .left {
  position: absolute;
  top: 50%;
  transform: translate(0, calc(-50% + 4px));
  font-size: 28px;
  color: var(--gray-side);
  cursor: pointer;
  &:hover {
    opacity: .4;
  }
  transition: all .3s;
}
.right {
  right: 0;
}
.left {
  left: 0;
}
.blur {
  -ms-filter: blur(1px);
  filter: blur(1px);
}
.imgBlocker {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 800;
  }
}
</style>
