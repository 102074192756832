<template>
  <div>
    <spacer :y="4"/>
    <h2 :class="$style.title">メールアドレス変更</h2>
    <spacer :y="5"/>


    <form autocomplete="off">
      <div
        :class="$style.form">
        <p
          :class="$style.comment">新しいメールアドレスを入力してください。</p>
        <Spacer :y="5"/>
        <div :class="$style.input">
          <input
            id="email"
            type="email"
            name="email"
            placeholder="メールアドレス"
            v-model.trim="$v.mail.$model"
            v-bind:disabled="flg.success"
            v-bind:class="{ input_error: validationFlags.mail }"
            v-on:input="invalidsCheck('mail', $v.mail.$invalid)">
          <span class="slide-in"></span>
        </div>
        <p
          :class="$style.comment"
          v-if="$v.mail.$dirty
            && $v.mail.required.$invalid">メールアドレスを入力してください</p>
        <p
          :class="$style.comment"
          v-if="$v.mail.$dirty
            && $v.mail.email.$invalid">正しいメールアドレスの形式で入力してください</p>
        <div>
          <Spacer
            v-if="flg.success || flg.userNotFound || flg.duplicate || flg.error"
            :y="6"/>
          <div>
            <p
              :class="$style.comment"
              v-if="flg.success">更新するメールアドレス宛に確認メールを送信しました。<br>現在は更新完了していません。<br>メールに記載されたURLからアクセスし、メールアドレスの更新を完了してください。</p>
            <p
              :class="$style.comment"
              v-if="flg.userNotFound">ユーザが見つかりませんでした。</p>
            <p
              :class="$style.comment"
              v-if="flg.duplicate">送信いただいた更新メールアドレスはすでに別アカウントで登録済みです。<br>メールアドレスの重複登録はできません。<br>別のメールアドレスでお試しください。</p>
            <p
              :class="$style.comment"
              v-if="flg.error">メールアドレスの更新に失敗しました。お手数ですがリロードして再度お試しください。</p>
          </div>
          <Spacer
            v-if="!flg.success"
            :y="6"/>
          <button
            @click="submit"
            :class="$style.btn"
            v-if="!flg.success"
            v-bind:disabled="!submitFlag">
            送信</button>
        </div>
      </div>


    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import Spacer from '@/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'edit-email',
  mixins: [cf],
  components: {
    Spacer,
  },
  data() {
    return {
      flg: {
        success: false,
        userNotFound: false,
        duplicate: false,
        error: false,
      },
      validationFlags: {
        mail: false,
      },
      invalids: {
        mail: true,
      },
    };
  },
  created() {
  },
  setup() {
    const mail = ref('');

    const rules = {
      // 必須・email形式
      mail: { required, email },
    };

    const $v = useVuelidate(rules, { mail });
    return { mail, $v };
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      return !this.invalids.mail && !this.flg.success;
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      this.showLoading();
      // 初期化
      this.flg.success = false;
      this.flg.userNotFound = false;
      this.flg.duplicate = false;
      this.flg.error = false;

      const data = {
        id: this.user.id,
        email: this.mail,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/updateEmail',
        data,
      })
        .then((response) => {
          const res = response.data;
          if (res.status === 200) this.flg.success = true;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          const res = error.response.data;
          if (res.duplicate) this.flg.duplicate = true; // 重複エラー時
          if (res.notFound) this.flg.notFound = true; // User Not Found
          if (!res.duplicate && !res.notFound) this.flg.error = true; // それ以外のエラー
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  display: flex;
  justify-content: center;
}
.logo {
  margin: 0 auto;
  width: 50%;
  max-width: 300px;
}
.title {
  text-align: center;
  color: var(--orange-main);
}
.form {
  text-align: center;
}
.input {
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
  position: relative;
  input {
    width: 100%;
    padding: 13px;
    border: none;
    background-color: var(--gray-sub);
    outline: none;
    border-bottom: 1px solid var(--gray);

    &:focus + .slideIn {
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(#e2e2e2, .4);
      mix-blend-mode: multiply;
      position: absolute;
      animation: split .3s;
    }

    @keyframes split {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }
}
.comment {
  font-size: 12px;
  color: var(--pink);
}
.message {
  font-size: 12px;
  text-align: center;
}
.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
}
</style>
