<template>
  <li
    @click="pushPaperWeather"
    :class="$style.container"
  >
    <div>
      <div :class="$style.title">これからの天気</div>
      <p :class="$style.label">{{ label }}</p>
    </div>
    <img
      :src="src"
      :alt="label"
      :class="$style.image"
    >
  </li>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'WeatherInfo',
  mixins: [cf],
  props: ['weather'],
  computed: {
    ...mapState(['helper']),
    label() {
      return this.helper?.master?.weathers && this.weather !== null ? this.helper.master.weathers.label[this.weather] : '---';
    },
    src() {
      return this.weather !== null ? `img/weathers/${this.helper.master.weathers.image[this.weather]}.png` : 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    },
  },
  methods: {
    pushPaperWeather() {
      this.$router.push('/paper/latest/?page=8');
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  border: 1px solid var(--border-gray);
  align-items: center;
  aspect-ratio: 3/1;
  cursor: pointer;
}

.title {
  font-size: 12px;
  line-height: 1.4;
  white-space: nowrap;
}

.data {
  text-align: right;
  font-weight: 900;
  font-size: 22px;
  line-height: 100%;
}

.label {
  font-weight: bold;
  font-size: 20px;
  max-width: 6.5em;
  margin-top: 4px;
}

.image {
  flex-shrink: 0;
  flex-grow: 0;
  aspect-ratio: 1/1;
  width: 60px;
  height: 60px;
}

@include sm-view {
  .container {
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    aspect-ratio: auto;
    border-radius: 4px;
    flex-direction: column;
    width: auto;
    min-width: 100px;
    flex-shrink: 0;
  }

  .title {
    min-width: 5em;
    font-size: 11px;
  }

  .data {
    font-size: 16px;
  }

  .label {
    font-size: 12px;
    text-align: center;
  }

  .image {
    margin-top: 0;
    width: 55px;
    height: 55px;
  }
}
</style>
