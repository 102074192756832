<template>
  <template
    v-for="pastData in pastDataList"
    :key="pastData.date"
  >
    <h3 :class="$style.date">{{ pastData.date }}</h3>
    <Spacer :y="3"/>
    <ArticleList>
      <ArticleListItem
        v-for="(article, index) in paddedArticleList(pastData.articles)"
        :key="index"
        :article="article"
        :isNarrow="isNarrow(index)"
      />
    </ArticleList>
    <Spacer :y="4"/>
    <hr />
    <Spacer :y="10"/>
  </template>
</template>

<script>
import Spacer from '@/components/Spacer';
import {
  ArticleList,
  ArticleListItem,
} from '@/components/ArticleList';

export default {
  name: 'PastBlock',
  components: {
    Spacer,
    ArticleList,
    ArticleListItem,
  },
  props: ['past-data-list'],
  methods: {
    isNarrow(index) {
      return index % 6 > 1;
    },
    paddedArticleList(articles) {
      const length = articles.length;
      const mod6 = length % 6;
      if (!mod6 || mod6 === 2) { return articles; }
      if (mod6 === 5) { return [...articles, ...Array(1)]; }
      if (mod6 === 4) { return [...articles, ...Array(2)]; }
      return [...articles, ...Array(mod6)];
    },
  },
};
</script>

<style lang="scss" module>
.date {
  text-align: left;
  font-weight: 900;
  font-size: 22px;
  line-height: 100%;
}
</style>
