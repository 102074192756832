<template>
  <div class="login container-xl">
    <div class="login_content">
      <Spacer :y="4"/>
      <h2 :class="$style.title">パスワード設定</h2>
      <Spacer :y="5"/>
      <div class="wrap-error" v-if="invalid" :class="$style.form">
        <p>
          アクティベートキーが無効です。<br />
          メールアドレス登録時のメールに記載されたURLから再度アクセスするか、ログインをお試しください。
        </p>
      </div>

      <form
        class="form_login form"
        autocomplete="off"
        v-if="!invalid">
        <p
          :class="$style.message"
          v-if="!flag.sended || !flag.registed">パスワードを設定してください。</p>
        <div :class="$style.form"
          v-if="!flag.sended">
          <div :class="$style.input">
            <input
              id="password"
              type="password"
              name="password"
              placeholder="パスワード"
              v-model.trim="$v.password.$model"
              v-bind:class="{ input_error: validationFlags.password }"
              v-on:input="invalidsCheck('password', $v.password.$invalid)">
            <span class="slide-in"></span>
          </div>
          <p
            :class="$style.message"
            v-if="$v.password.$dirty && $v.password.required.$invalid">
            パスワードを入力してください</p>
          <p
            :class="$style.message"
            v-if="$v.password.$dirty && $v.password.minLength.$invalid">
            パスワードは8文字以上で設定してください</p>
          <Spacer :y="4"/>
          <div
            :class="$style.wrap"
            v-if="!flag.sended">
            <button
              :class="$style.btn"
              @click="submit"
              v-bind:disabled="!submitFlag">
            決定</button>
          </div>
        </div>
        <div
          :class="$style.message"
          v-if="flag.sended && flag.registed">
          <p>
            パスワードを登録しました。自動でリダイレクトします。<br>
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import Spacer from '@/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'signupPassword',
  mixins: [cf],
  components: {
    Spacer,
  },
  data() {
    return {
      validationFlags: {
        password: false,
      },
      // 無効フラグ
      invalids: {
        password: true,
        minLength: true,
      },
      // パラメータなし等で無効
      invalid: true,
      activatekey: null,
      email: null,
      flag: {
        sended: false,
        registed: false,
      },
    };
  },
  created() {
    // パラメータが含まれているか確認
    const query = this.$route.query;
    if (query.activate) {
      this.invalid = false;
      this.activatekey = query.activate;
    }
  },
  // https://qiita.com/tsuji_masayuki/items/2250f87b391a0bd374cc
  setup() {
    const password = ref('');

    const rules = {
      // パスワードは必須で8文字以上
      password: {
        required,
        minLength: minLength(8),
      },
    };

    const $v = useVuelidate(rules, { password });
    return { password, $v };
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.password;
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      this.showLoading();

      // validateチェックは入力時に行われてる
      const data = {
        flag: 1,
        password: this.password,
        activatekey: this.activatekey,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/registPassword',
        data,
      })
        .then((response) => {
          const resData = response.data;
          if (resData.registed) {
            alert('パスワードの設定が完了しました。');
            this.email = resData.user.email;
            this.flag.registed = true;
            this.login();
          } else {
            this.flag.sended = true;
            this.hideLoading();
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.invalid = true;
          this.flag.sended = true;
          this.hideLoading();
        });
    },

    login() {
      // ログイン後、有料会員登録ページに飛ばしたいので ls.loginRef を設定
      const loginRef = { path: '/edit/subscription/' };
      cf.saveLocalStorage({ loginRef: JSON.stringify(loginRef) }, 'times');

      // ログイン処理
      const data = {
        email: this.email,
        password: this.password,
      };
      this.$store.dispatch('user/login/submit', data);
      this.$store.dispatch('user/update');
      this.flag.sended = true;
      this.hideLoading();
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  display: flex;
  justify-content: center;
}

.title {
  text-align: center;
  color: var(--orange-main);
}

.form {
  text-align: center;
}

.btn {
  display: inline-block;
  border-radius: 22px;
  border: none;
  background-color: #666;
  font-weight: bold;
  color: #fff;
  appearance: none;
  padding: 8px 25px;
  font-size: 16px;
}

.message {
  font-size: 12px;
  text-align: center;
}

.input {
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
  input {
    width: 100%;
    padding: 13px;
    border: none;
    background-color: var(--gray-sub);
    outline: none;
    border-bottom: 1px solid var(--gray);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
</style>
