/**
* 記事管理
*/

const actions = {
  setTopGetFlag({ commit }, bool) {
    commit('setTopGetFlag', bool);
  },
};


const mutations = {
  setTopGetFlag(state, bool) {
    state.topGetFlag = bool;
  },
};

const state = {
  topGetFlag: false,
};


export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
